import React from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import AuthComponentWrapper from '../AuthComponentWrapper';
import WrappedLink from '../WrappedLink';

import { getHeaderLinks } from '../../helper/functions';

import './MobileHeaderLinks.scss';

function MobileHeaderLinks() {
  const userData = useSelector((state) => state.auth.userData);

  if (userData.role === 'user') {
    return null;
  } else {
    return (
      <div bp="flex full-width" className="phone-header">
        {getHeaderLinks()
          .filter((link) => link.mobileSubMenu)
          .map((link, index) => (
            <div key={index} className="phone-header-button" bp="fill text-center">
              <AuthComponentWrapper allowedRules={link.allowedRules || []}>
                <WrappedLink navLink={true} to={link.to}>
                  <Translate value={link.translateKey} />
                </WrappedLink>
              </AuthComponentWrapper>
            </div>
          ))}
      </div>
    );
  }
}

export default MobileHeaderLinks;

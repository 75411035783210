import { createSlice } from '@reduxjs/toolkit';

const submissionsSlice = createSlice({
  name: 'submissionsPlus',
  initialState: {},
  reducers: {
    setSubmission: (state, { payload }) => {
      Object.values(payload).forEach((s) => (state[s._id] = s));
    },
    submissionDataFetching: (state, { payload: { submissionId } }) => {
      state[submissionId] = { loading: true };
    },
    submissionsDataFetched: (state, { payload: { data } }) => {
      state[data._id] = { ...data };
      state[data._id].data = data.data.map((group) => group.groupId);
      state[data._id].loading = false;
    },
  },
  extraReducers: {
    LOGOUT: () => {
      return {};
    },
    'submissions/addNewGroupResult': (state, action) => {
      if (
        !state[action.payload.submissionId].loading &&
        !state[action.payload.submissionId].data.some((s) => s === action.payload.groupId)
      ) {
        state[action.payload.submissionId].data.push(action.payload.groupId);
      }
    },
  },
});

export const { setSubmission, submissionDataFetching, submissionsDataFetched } = submissionsSlice.actions;

export default submissionsSlice.reducer;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AdminsTable from '../AdminsTable';
import Popup from '../Popup';
import Authorization from '../Authorization';
import Svg from '../Svg';
import TextWithTooltip from '../TextWithTooltip';
import SubHeader from '../SubHeader';
import ViewOptions from '../ViewOptions';
import Filterbar from '../Filterbar';
import MobileFilter from '../Filterbar/MobileFilter';
import SideBarNavigationMobile from '../SideBarNavigationMobile';
import MobileHeaderLinks from '../MobileHeaderLinks';

import * as entityActions from '../../actions/entity';
import * as errorActions from '../../actions/error';
import { translate, filterBy, useIsBigScreen } from '../../helper/functions';

import './Delegates.scss';

import saveIcon from '../../assets/save.svg';
import resetIcon from '../../assets/cancel.svg';

const Delegates = (props) => {
  const { token, entity, history, subPageTitle, setEntity, throwError, fields, navigation, backButton } = props;
  const [admins, setAdmins] = useState({ data: [], fetched: false });
  const defaultSearchAndFilter = {
    search: '',
    role: '',
  };
  const [searchAndFilter, setSearchAndFilter] = useState(defaultSearchAndFilter);
  const initialPopupState = {
    shown: false,
  };
  const [popup, setPopup] = useState(initialPopupState);
  const [selectedAdmins, setSelectedAdmins] = useState(entity.adminUsers.map((au) => au._id));
  const [adminsSelectedOnLastSave, setAdminsSelectedOnLastSave] = useState(entity.adminUsers.map((au) => au._id));
  const [filteredAdmins, setFilteredAdmins] = useState([]);
  const [roles, setRoles] = useState({ data: [], fetched: false });
  const isBigScreen = useIsBigScreen();

  useEffect(() => {
    if (!admins.fetched) {
      fetchData();
    }
    setFilteredAdmins(
      admins.data
        .map((a) => ({
          ...a,
          delegate: adminsSelectedOnLastSave.includes(a._id),
        }))
        .filter((a) =>
          filterBy(
            a,
            [
              { type: 'text', itemProperties: ['profile.name', 'email'], searchProperty: 'search' },
              { type: 'selector', itemProperties: ['profile.role'], searchProperty: 'role' },
            ],
            searchAndFilter
          )
        )
    );
  }, [admins, searchAndFilter, adminsSelectedOnLastSave]);

  const closePopup = () => {
    setPopup({ shown: false });
  };

  const showPopup = () => {
    setPopup({
      shown: true,
    });
  };

  const selectAdmins = (id) => {
    let updatedAdminList = [...selectedAdmins];
    if (selectedAdmins.includes(id)) {
      updatedAdminList.splice(updatedAdminList.indexOf(id), 1);
    } else {
      updatedAdminList.push(id);
    }
    setSelectedAdmins(updatedAdminList);
  };
  const resetAdmins = () => {
    setSelectedAdmins(adminsSelectedOnLastSave);
  };

  const headers = {
    Authorization: 'Bearer ' + token,
  };
  const delegateAdmins = async () => {
    try {
      const response = await axios.put(
        `/admin/entities/${entity._id}/allocate`,
        { adminUserIds: selectedAdmins },
        { headers }
      );
      if (response) {
        showPopup();
        setAdminsSelectedOnLastSave(selectedAdmins);
      }
    } catch (e) {
      throwError(e);
    }
  };
  const fetchData = async () => {
    try {
      const response = await Promise.all([
        axios.get('/admin/users?loginEnabled=true&roleType=client-admin', { headers }),
        axios.get(`/entities/${entity._id}`, { headers }),
        axios.get('/auth/acl-data', { headers }),
      ]);
      setAdmins({ data: response[0].data, fetched: true });
      setSelectedAdmins(response[1].data.adminUsers.map((au) => au._id));
      setAdminsSelectedOnLastSave(response[1].data.adminUsers.map((au) => au._id));
      setEntity(response[1].data, fields);
      setRoles({
        data: Object.values(response[2].data.roles)
          .filter((r) => r.roleType === 'client-admin')
          .map((r) => ({ value: r._id, label: translate(r.name) })),
        fetched: true,
      });
    } catch (e) {
      throwError(e);
    }
  };

  const buttonsInactive =
    adminsSelectedOnLastSave.filter((admin) => !selectedAdmins.includes(admin)).length +
      selectedAdmins.filter((admin) => !adminsSelectedOnLastSave.includes(admin)).length ===
    0;

  const filterbarProps = {
    searchbar: {
      shown: true,
      resultsLength: filteredAdmins.length,
      type: 'text',
      placeholder: 'entity-details/search-for',
      onSearchChange: () => {
        setSearchAndFilter({
          ...searchAndFilter,
          search: event.target.value,
        });
      },
    },
    selects: [
      {
        name: 'role',
        className: 'role-selector',
        selected: searchAndFilter.role,
        onSelectChange: (selectValue) =>
          setSearchAndFilter({
            ...searchAndFilter,
            role: selectValue,
          }),
        options: roles.data,
      },
    ],
    clearSearchAndFilter: () => setSearchAndFilter(defaultSearchAndFilter),
  };

  const extraContent = (user) => {
    const isNotSaved =
      (selectedAdmins.includes(user._id) && !adminsSelectedOnLastSave.includes(user._id)) ||
      (adminsSelectedOnLastSave.includes(user._id) && !selectedAdmins.includes(user._id));
    return (
      <div className="column half checkbox sticky" onClick={(e) => e.stopPropagation()}>
        <label className="checkbox-line">
          <input
            id={user._id}
            type="checkbox"
            checked={selectedAdmins.includes(user._id) ? true : false}
            onChange={(e) => {
              e.stopPropagation();
              selectAdmins(user._id);
            }}
          />
          <span className={`checkmark ${isNotSaved ? 'not-saved' : ''}`}></span>
        </label>
      </div>
    );
  };

  return (
    <div className="delegates-container container hide-scrollbar">
      <DelegatesSubHeader
        subPageTitle={subPageTitle}
        buttonsInactive={buttonsInactive}
        resetAdmins={resetAdmins}
        delegateAdmins={delegateAdmins}
      />
      {isBigScreen ? (
        <Filterbar {...filterbarProps} />
      ) : (
        <MobileFilter filterbarProps={filterbarProps} backButton={backButton} subPageTitle={subPageTitle} />
      )}
      {!isBigScreen && <SideBarNavigationMobile navigation={navigation} />}
      <Popup
        popupTitle={I18n.t('entity-details/delegates/save-popup-title')}
        popupShown={popup.shown}
        okHandler={closePopup}
      ></Popup>
      <AdminsTable
        filteredAdmins={filteredAdmins}
        roles={roles}
        setFilteredAdmins={setFilteredAdmins}
        history={history}
        extraHeaders={[{ name: I18n.t('users-table/table-header-8'), class: 'half', sortName: 'delegate' }]}
        extraContent={extraContent}
      />
      {isBigScreen ? null : <MobileHeaderLinks />}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    fields: state.modules.fields,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEntity: entityActions.setEntity,
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default Authorization(connect(mapStateToProps, mapDispatchToProps)(Delegates), ['delegate-user']);

function DelegatesSubHeader({ subPageTitle, buttonsInactive, resetAdmins, delegateAdmins }) {
  const isBigScreen = useIsBigScreen();

  if (isBigScreen) {
    return (
      <SubHeader>
        <div className="column">
          <div className="title">
            <TextWithTooltip textWithOverflow={subPageTitle} />
          </div>
        </div>
        <div className="column">
          <ViewOptions options={['toggleFilterBar']} />
          <button
            className={'subheader-button reset ' + (buttonsInactive ? 'inactive' : '')}
            onClick={() => resetAdmins()}
          >
            <Svg src={resetIcon} otherColor="#FFFFFF" />
            <Translate value="delegation/reset" />
          </button>
          <button
            className={'subheader-button save ' + (buttonsInactive ? 'inactive' : '')}
            onClick={() => delegateAdmins()}
          >
            <Svg src={saveIcon} otherColor="#FFFFFF" />
            <Translate value="form/save" />
          </button>
        </div>
      </SubHeader>
    );
  } else {
    return (
      <div className="floating-subheader-buttons">
        <button
          className={'subheader-button reset ' + (buttonsInactive ? 'inactive' : '')}
          onClick={() => resetAdmins()}
        >
          <Svg src={resetIcon} otherColor="#FFFFFF" />
          <div className="floating-text">
            <Translate value="delegation/reset" />
          </div>
        </button>
        <button
          className={'subheader-button save ' + (buttonsInactive ? 'inactive' : '')}
          onClick={() => delegateAdmins()}
        >
          <Svg src={saveIcon} otherColor="#FFFFFF" />
          <div className="floating-text">
            <Translate value="form/save" />
          </div>
        </button>
      </div>
    );
  }
}

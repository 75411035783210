import axios from 'axios';

import { setSubmissionLoading, setStageResults, validateFields } from '../reducers/submissions';
import { allConditionsMet } from '../helper/functions';

export function completeSubmission(submissionId, token, callback) {
  return () => {
    axios
      .post(`/drf/submissions/${submissionId}/complete`, null, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .finally(() => {
        if (callback) {
          callback();
        }
      });
  };
}

export function postResults(submissionId, stageId, token, stageResults = [], fields = {}, callback = null) {
  const stageResultsWithOutNullValues = stageResults
    .map((stageResult) => {
      return {
        ...stageResult,
        values: stageResult.values.filter((value) => {
          return value.value || value.value === false;
        }),
      };
    })
    .filter((stageResult) => stageResult.values.length > 0);

  return (dispatch) => {
    dispatch(setSubmissionLoading({ submissionId, loading: true }));
    const status = {};

    let resultIdsToDelete = [];
    const valuesToDelete = [];

    // if a field exists, but no condition is met for it, than it has to be deleted
    for (const sr of stageResultsWithOutNullValues) {
      for (const value of sr.values) {
        if (!allConditionsMet(fields[value.fieldId], fields, sr.groupId, sr)) {
          resultIdsToDelete.push(sr.resultId);
          valuesToDelete.push(value.fieldId);
        }
      }
    }

    resultIdsToDelete = [...new Set(resultIdsToDelete)];
    const deletePromises = [];

    resultIdsToDelete.forEach(async (resultId) => {
      deletePromises.push(
        new Promise((resolve) => {
          axios
            .delete(`/drf/submissions/${submissionId}/results/${resultId}`, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            })
            .then(() => {
              resolve();
            });
        })
      );
    });

    // update the stageResults with the updated resultFieldIds
    // if a connection is no longer active then it has to be undefined
    const stageResultToSave = stageResultsWithOutNullValues.map((sr) => {
      return {
        ...sr,
        resultId: resultIdsToDelete.length > 0 ? undefined : sr.resultId,
        values: sr.values
          .filter((value) => !valuesToDelete.includes(value.fieldId))
          .map((value) => ({
            ...value,
            resultFieldId: resultIdsToDelete.length > 0 ? undefined : value.resultFieldId,
          })),
      };
    });

    Promise.all(deletePromises).then(() => {
      axios
        .put(
          '/drf/submissions/' + submissionId,
          {
            stageId,
            results: stageResultToSave,
          },
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then((response) => {
          status.state = 'success';
          dispatch(setStageResults({ submissionId, groupResults: response.data, stageId, status }));
          dispatch(setSubmissionLoading({ submissionId, loading: false }));
        })
        .catch((e) => {
          if (e.response && e.response.status === 422) {
            status.state = 'fieldError';
            status.errors = e.response.data.error;
          } else {
            status.state = 'generalError';
          }

          dispatch(setStageResults({ submissionId, groupResults: undefined, stageId, status }));

          if (status.errors && Array.isArray(status.errors)) {
            const mappedErrors = status.errors.map((originalError) => ({
              fieldId: originalError.property,
              message: originalError.error,
            }));

            dispatch(validateFields({ submissionId, errors: mappedErrors, fields }));
          }
        })
        .finally(() => {
          if (callback) callback(status);
        });
    });
  };
}

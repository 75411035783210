import React, { useRef, useState, useLayoutEffect } from 'react';

import './TextWithTooltip.scss';

const TextWithTooltip = ({ tooltipContent, textWithOverflow, showTooltipIfTextNotOverflow = false, textClassName }) => {
  const textContainer = useRef();
  const textSpan = useRef();
  const tooltip = useRef();
  const [showTooltip, setTooltip] = useState(false);
  const minPaddingAtPageBottom = 32;

  useLayoutEffect(() => {
    setTooltip(textContainer.current.clientWidth < textSpan.current.offsetWidth);
  });

  const isElementInViewport = function (el) {
    if (!el.current) return;
    let rect = el.current.getBoundingClientRect();
    let windowHeight = window.innerHeight || document.documentElement.clientHeight;

    return !(
      Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100) < 100 ||
      Math.floor(100 - ((rect.bottom - windowHeight + minPaddingAtPageBottom) / rect.height) * 100) < 100
    );
  };

  const calculateDirection = () => {
    if (showTooltip || showTooltipIfTextNotOverflow) {
      let newClass = isElementInViewport(tooltip) ? 'down' : 'up';
      tooltip.current.classList.add(newClass);
    }
  };

  const resetDirection = () => {
    if (showTooltip || showTooltipIfTextNotOverflow) {
      tooltip.current.className = 'tooltip';
    }
  };

  return (
    <div className={'text-overflow-with-tooltip'} onMouseEnter={calculateDirection} onMouseLeave={resetDirection}>
      <div ref={textContainer} className="overflow-text">
        <span className={textClassName ? textClassName : ''} ref={textSpan}>
          {textWithOverflow}
        </span>
      </div>
      {(showTooltip || showTooltipIfTextNotOverflow) && (
        <div className="tooltip-container">
          <span ref={tooltip} className="tooltip">
            {tooltipContent ? tooltipContent : textWithOverflow}
          </span>
        </div>
      )}
    </div>
  );
};

export default TextWithTooltip;

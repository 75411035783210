import PropTypes from 'prop-types';
import React, { useState } from 'react';

import OutsideAlerter from '../SelectButton/OutsideAlerter';

import './StatusSelector.scss';

const StatusSelector = ({ state, handleSelectChange, isDisabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <OutsideAlerter callback={() => setIsOpen(false)}>
      <div
        className={`select-container status-selector-container ${state}`}
        style={{ position: 'relative', opacity: isDisabled ? 0.3 : 1 }}
        onClick={() => !isDisabled && setIsOpen(!isOpen)}
      >
        <div
          className={`subject-select__control ${isOpen && 'subject-select__control--menu-is-open bottom-menu'}`}
          style={{ display: 'flex' }}
        >
          <div className="subject-select__value-container">
            <div className="subject-select__single-value">{state}</div>
            <input readOnly={true} style={{ transform: 'scale(0)' }} />
          </div>
          <div className="subject-select__indicators css-1hb7zxy-IndicatorsContainer">
            <span className="subject-select__indicator-separator css-109onse-indicatorSeparator"></span>
            <div
              aria-hidden="true"
              className="subject-select__indicator subject-select__dropdown-indicator css-tlfecz-indicatorContainer"
            >
              <svg
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className="css-6q0nyr-Svg"
              >
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
              </svg>
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="subject-select__menu bottom-menu">
            <div className="subject-select__menu-list css-4ljt47-MenuList">
              <div
                className="subject-select__option subject-select__option--is-focused subject-select__option--is-selected css-9gakcf-option"
                data-state="pending"
                tabIndex="-1"
                onClick={handleSelectChange}
              >
                pending
              </div>
              <div
                className="subject-select__option css-yt9ioa-option"
                data-state="approved"
                tabIndex="-1"
                onClick={handleSelectChange}
              >
                approved
              </div>
              <div
                className="subject-select__option css-yt9ioa-option"
                data-state="rejected"
                tabIndex="-1"
                onClick={handleSelectChange}
              >
                rejected
              </div>
            </div>
          </div>
        )}
      </div>
    </OutsideAlerter>
  );
};

StatusSelector.propTypes = {
  state: PropTypes.string,
};

export default StatusSelector;

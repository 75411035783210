import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { arrayToObject } from '../../helper/functions';
import { mapToRawTransactions, mapToRawGrouppedTransactions } from './transaction-history';

export const fetchTransactionHistory = createAsyncThunk(
  'transactionHistory/fetchBySubmissionId',
  async ({ submissionId, token }) => {
    const headers = {
      Authorization: 'Bearer ' + token,
    };

    const response = await axios.get(`/admin/drf/submissions/${submissionId}/transaction-history`, { headers });
    return response.data;
  }
);

const transactionHistorySlice = createSlice({
  name: 'transactionHistory',
  initialState: {},
  reducers: {
    loadTransactions: (state, { payload }) => {
      const rawTransactions = arrayToObject(payload.transactions);
      state.rawTransactions = rawTransactions;
      state.rawGrouppedTransactions = mapToRawGrouppedTransactions(rawTransactions);
    },
  },
  extraReducers: {
    [fetchTransactionHistory.fulfilled]: (state, { payload }) => {
      const rawTransactions = arrayToObject(mapToRawTransactions(payload));
      state.rawTransactions = rawTransactions;
      state.rawGrouppedTransactions = mapToRawGrouppedTransactions(rawTransactions);
    },
  },
});

export const { loadTransactions } = transactionHistorySlice.actions;
export default transactionHistorySlice.reducer;

import React from 'react';
import { Translate } from 'react-redux-i18n';

import AuthComponentWrapper from '../AuthComponentWrapper';
import WrappedLink from '../WrappedLink';

import './NavigateTabs.scss';

const NavigateTabs = ({ routes }) => (
  <div className="navigate-tabs">
    {routes.map((r, index) => (
      <AuthComponentWrapper allowedRules={r.rules} key={index}>
        <WrappedLink to={"/user-management" + r.path} navLink={true}><Translate value={"navigation-tabs" + r.path} /></WrappedLink>
      </AuthComponentWrapper>
    ))}
  </div>
)

export default NavigateTabs;
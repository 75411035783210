import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import axios from 'axios';

import AllocatedEntities from '../AllocatedEntities';
import Authorization from '../Authorization';
import EditAdminDetails from '../EditAdminDetails';
import SideBarLayout from '../SideBarLayout';

import * as errorActions from '../../actions/error';

import './AdminDetails.scss';

const AdminDetails = (props) => {
  const { match, token, throwError } = props;
  const mainRoute = match.url === '/' ? '' : match.url;
  const [data, setData] = useState({
    fetched: false,
    user: null,
  });
  const fetchData = async () => {
    const headers = {
      Authorization: 'Bearer ' + token,
    };
    try {
      const response = await axios.get(`admin/users/${match.params.adminId}`, { headers });
      setData({
        fetched: true,
        user: response.data,
      });
    } catch (e) {
      throwError(e);
    }
  };

  useEffect(() => {
    if (!data.fetched) {
      fetchData();
    }
  }, [data.fetched]);

  const navigation = [
    {
      destination: `${mainRoute}/edit-rights-and-data`,
      name: I18n.t('admin-details/sidebar/edit-rights'),
      class: '',
      component: (routeProps) => (
        <EditAdminDetails {...routeProps} {...props} user={data.user} id={match.params.adminId} setData={setData} />
      ),
      rules: ['edit-admin'],
    },
    {
      destination: `${mainRoute}/allocated-entities`,
      name: I18n.t('admin-details/sidebar/allocated-entities'),
      class: '',
      component: (routeProps) => (
        <AllocatedEntities {...routeProps} {...props} user={data.user} id={match.params.adminId} />
      ),
      rules: ['list-all-entities'],
    },
  ];
  const sideBarElements = {
    redirectRoute: `${mainRoute}/edit-rights-and-data`,
    navigation,
    backButton: {
      title: I18n.t('admin-details/sidebar/back-button'),
      url: '/user-management/admins',
    },
    deleteButton: {
      text: I18n.t('admin-details/sidebar/delete-button'),
      onClick: () => {},
      rules: ['delete-admin'],
    },
  };
  return (
    <div className="admin-details-container container row-direction hide-scrollbar">
      <SideBarLayout sideBarElements={sideBarElements} dataArrived={data.fetched} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default Authorization(connect(mapStateToProps, mapDispatchToProps)(AdminDetails), [
  'edit-admin',
  'list-all-entities',
]);

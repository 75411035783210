import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { masks } from '../fields';
import { hasNonNumbers, getBankMask, getPhoneMask } from './text-field';
import { removeMask } from '../fields';
import { getPennyMask } from '../NumberField/number-field';

export default function TextField({ text, placeholder, disabled, mask, handleGlobalChange }) {
  const [value, setValue] = useState(text | '');
  const cursor = useRef(0);

  useEffect(() => {
    if (!text) {
      setValue('');
      return;
    }

    switch (mask) {
      case masks.bank:
        setValue(getBankMask(text));
        break;
      case masks.penny:
        setValue(getPennyMask(text));
        break;
      case masks.phone:
        setValue(getPhoneMask(text));
        break;
      default:
        setValue(text);
    }
  }, [text]);

  let handleChange;
  let maxLength = 100;

  switch (mask) {
    case masks.bank:
      maxLength = 26;
      handleChange = (e) => {
        const element = e.target;
        if (hasNonNumbers(removeMask(e.target.value))) {
          return;
        }

        setValue(getBankMask(removeMask(e.target.value)));
        handleGlobalChange(e, removeMask(e.target.value));

        if (getBankMask(removeMask(e.target.value))[e.target.selectionStart - 1] === '-') {
          cursor.current = e.target.selectionStart + 1;
        } else {
          cursor.current = e.target.selectionStart;
        }

        window.requestAnimationFrame(() => {
          element.setSelectionRange(cursor.current, cursor.current);
        });
      };

      return (
        <TextInput
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
          handleChange={handleChange}
        />
      );
    case masks.penny: {
      const handleChange = (e) => {
        if (hasNonNumbers(removeMask(e.target.value))) {
          return;
        }

        setValue(getPennyMask(removeMask(e.target.value)));
        handleGlobalChange(e, removeMask(e.target.value));
      };

      return (
        <TextInput
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
          handleChange={handleChange}
        />
      );
    }
    case masks.phone: {
      const handleChange = (e) => {
        if (hasNonNumbers(e.target.value)) {
          return;
        }

        setValue(e.target.value);
      };
      const handleFocus = (e) => setValue(removeMask(e.target.value));
      const handleBlur = (e) => {
        if (hasNonNumbers(removeMask(e.target.value))) {
          return;
        }

        setValue(getPhoneMask(e.target.value));
        handleGlobalChange(e, removeMask(e.target.value));
      };

      return (
        <TextInput
          value={value}
          placeholder={placeholder}
          maxLength={20}
          disabled={disabled}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleFocus={handleFocus}
        />
      );
    }
    default:
      handleChange = (e) => {
        setValue(e.target.value);
        handleGlobalChange(e, e.target.value);
      };
      return (
        <TextInput
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
          handleChange={handleChange}
        />
      );
  }
}

TextField.propTypes = {
  text: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  mask: PropTypes.string,
  handleGlobalChange: PropTypes.func.isRequired,
};

TextField.defaultProps = {
  text: '',
  placeholder: '',
  disabled: false,
  mask: null,
};

function TextInput({ type, value, placeholder, maxLength, disabled, handleChange, handleBlur, handleFocus }) {
  return (
    <label>
      <input
        className={'input ' + type + ' ' + (value ? 'has-value' : '')}
        type="text"
        disabled={disabled}
        value={value}
        maxLength={maxLength}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      ></input>
      <div className="active-placeholder">
        <span>{placeholder}</span>
      </div>
    </label>
  );
}

TextInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  maxLength: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
};

TextInput.defaultProps = {
  type: '',
  value: '',
  placeholder: '',
  disabled: false,
  handleBlur: null,
  handleFocus: null,
};

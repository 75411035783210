import { types } from '../actions/modules';

export default function(
  state = {
    modules: {},
    stages: {},
    groups: {},
    fields: {},
  },
  action
) {
  switch (action.type) {
    case types.SET_MODULES:
      const modules = {};
      action.data.forEach((m) => (modules[m._id] = { ...m }));
      return {
        ...state,
        modules,
      };
    case types.SET_MODULE_DATA: {
      const newStages = {};
      const newGroups = {};
      const newFields = {};

      // TODO: sort metas
      action.data.stages.forEach((s) => (newStages[s._id] = { ...s }));
      action.data.groups.forEach((g) => (newGroups[g._id] = { ...g }));
      action.data.fields.forEach((f) => (newFields[f._id] = { ...f }));
      return {
        ...state,
        modules: {
          ...state.modules,
          [action.data._id]: {
            ...state.modules[action.data._id],
            ...action.data,
          },
        },
        stages: {
          ...state.stages,
          ...newStages,
        },
        groups: {
          ...state.groups,
          ...newGroups,
        },
        fields: {
          ...state.fields,
          ...newFields,
        },
        referenceCollections: {
          ...state.referenceCollections,
          ...action.data.referenceCollections,
        },
      };
    }
    case types.SET_FIELDS: {
      const newFields = {};
      action.data.forEach((f) => (newFields[f._id] = { ...f }));
      return {
        ...state,
        fields: {
          ...state.fields,
          ...newFields,
        },
      };
    }
    case types.SET_REFERENCE_COLLECTION: {
      return { ...state, referenceCollections: { [action.name]: action.data.map((d) => ({ ...d, label: d.name })) } };
    }
    default:
      return state;
  }
}

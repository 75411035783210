export const types = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SET_USER_DATA: 'SET_USER_DATA'
}

export function login(data) {
  return {
    type: types.LOGIN,
    data
  }
}

export function logout() {
  return {
    type: types.LOGOUT,
  }
}

export function setUserData() {
  return {
    type: types.SET_USER_DATA,
  }
}

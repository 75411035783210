import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import Popup from '../Popup';
import { translate } from '../../helper/functions';

import lightbulb from '../../assets/lightbulb.svg';

import './ModuleSelectorPopup.scss';

const ModuleSelectorPopup = (props) => {
  const { popup, popupSetter, modules, createSubmission } = props;
  const handleCancel = () =>
    popupSetter({
      ...popup,
      isPopupOpen: false,
      moduleId: null,
      submissionName: '',
    });

  return (
    <>
      <Popup
        popupShown={popup.isPopupOpen}
        popupTitle={popup.moduleId && translate(modules[popup.moduleId].label)}
        popupSubtitle={I18n.t('module-selector/start-subtitle')}
        okHandler={createSubmission}
        cancelHandler={handleCancel}
      >
        <div className="input-container">
          <input
            value={popup.submissionName}
            onChange={() =>
              popupSetter({
                ...popup,
                submissionName: event.target.value,
              })
            }
          ></input>
          <div className="helper-container">
            <img src={lightbulb} />
            <span className="helper-text">{I18n.t('module-selector-popup/description')}</span>
          </div>
        </div>
      </Popup>
    </>
  );
};

ModuleSelectorPopup.propTypes = {
  modules: PropTypes.object,
  popup: PropTypes.object,
  popupSetter: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    modules: state.modules.modules,
    token: state.auth.token,
  };
}

export default connect(mapStateToProps, null)(ModuleSelectorPopup);

import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import * as Sentry from '@sentry/browser';

import Router from './Router'
import { API_URL, SENTRY_ENABLED, SENTRY_DSN } from './constants'

if (SENTRY_ENABLED === '1') {
  Sentry.init({
    dsn: SENTRY_DSN,
  });
}

axios.defaults.baseURL = API_URL
ReactDOM.render(Router, document.getElementById('app'))

import { getConditionsForGroup } from '../helper/functions';

/**
 * Selects the specified groups with field meta data
 *
 * @param {number[]} groupIds
 * @return {() => any[]} groups array with values and metas
 */
export function selectGroups(groupIds) {
  return (state) => {
    const result = [];

    groupIds.forEach((groupId) => {
      const foundGroup = state.modules.groups[groupId];

      if (foundGroup) {
        const newGroup = { ...foundGroup };

        newGroup.fieldMeta = foundGroup.fieldMeta.map((fm) => ({
          groupId,
          ...fm,
          ...state.modules.fields[fm.fieldId],
        }));

        result.push(newGroup);
      }
    });

    return result;
  };
}

/**
 * Selects the fields meta data for groups
 * It checks for dependent group rules
 *
 * @param {any[]} groups
 * @param {string} submissionId
 * @param {object} editedField
 * @return {any[]} fields array with metas
 */
export function selectFieldMetasForGroups(groups, submissionId, editedField) {
  return (state) => {
    if (!groups || groups.length === 0) {
      return [];
    }

    const fieldMetas = [];
    const groupResults = [];

    groups.forEach((group) => {
      if (state.groups[submissionId] && state.groups[submissionId][group._id]) {
        const g = { ...state.groups[submissionId][group._id] };
        g.values = g.values.map((v) => state.fieldValues[submissionId][v]);
        groupResults.push(g);
      }

      group.fieldMeta.forEach((fieldMeta) => {
        if (editedField && fieldMeta.fieldId === editedField.fieldId) {
          fieldMeta.edited = true;
        }
        fieldMetas.push(fieldMeta);
      });
    });

    const result = [];

    groups.forEach((group) => {
      const [andConditionsFailed, orConditionsFailed] = getConditionsForGroup(
        group,
        groupResults,
        state.modules.fields
      );

      if (!andConditionsFailed && !orConditionsFailed) {
        group.fieldMeta.forEach((fieldMeta) => result.push(fieldMeta));
      }
    });

    return result;
  };
}

/**
 * Selects the the field values for groups
 *
 * @export
 * @param {any[]} fieldMetas
 * @param {string} submissionId
 * @return {object} fields array with values
 */
export function selectFieldValuesForGroups(fieldMetas, submissionId) {
  return (state) => {
    const result = {};
    const fieldValues = state.fieldValues;

    fieldMetas.forEach((fm) => {
      if (!fieldValues[submissionId]) {
        return;
      }

      const fieldValue = fieldValues[submissionId][fm.fieldId];
      if (fieldValue) {
        result[fm.fieldId] = fieldValue;
      }
    });

    return result;
  };
}

/**
 *  Selects the edited field's meta data
 *
 * @param {any[]} groups
 * @param {string} editedFieldId
 * @return {object} the edited field's meta
 */
export function selectEditedField(groups, editedFieldId) {
  return (state) => {
    let result;

    if (editedFieldId) {
      for (const group of groups) {
        for (const fieldMeta of group.fieldMeta) {
          if (fieldMeta.fieldId === editedFieldId) {
            result = fieldMeta;
          }
        }
      }
    }

    return result;
  };
}

import React from 'react';

import './Input.scss';

export default function ReadonlyInput(props) {
  const { placeholder, ...inputProps } = props;
  return (
    <div className="input">
      <div className="input-wrapper">
        <input className=" has-value" {...inputProps} />
        <div className="active-placeholder">
          <span>{placeholder}</span>
        </div>
      </div>
      <div className="error-message"></div>
    </div>
  );
}

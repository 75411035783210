import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import SimpleBar from 'simplebar-react';

import FormGroups from '../FormGroups';
import DataCheckStage from '../DataCheckStage';
import DocumentLibrary from '../DocumentLibrary';
import StageList from '../StageList';
import NavigationButtons from '../NavigationButtons';
import StoppedStage from '../StoppedStage';
import BackButton from '../BackButton';
import Popup from '../Popup';
import InputWithError from '../InputWithError';
import DeleteButton from '../DeleteButton';
import Authorization from '../Authorization';
import Svg from '../Svg';
import { PaymentStage } from 'components/Stages';
import { PaymentStatusBar } from 'components/PaymentStatusBar';
import { MessengerDataInputContainer, MessengerDataCheck } from 'components/Upsoo';
import TextWithTooltip from '../TextWithTooltip';

import * as entityThunks from '../../thunks/entity';
import * as moduleActions from '../../actions/modules';
import * as submissionThunks from '../../thunks/submission';
import { updateCurrentStage } from '../../reducers/current-stage';

import { translate, formValidator, isProcessViewEnabled, useIsBigScreen, getStage } from '../../helper/functions';

import pwd_by_consolidity_logo from '../../assets/pwd_by_consolidity.svg';
import hamburger from '../../assets/hamburger_icon_mobile.svg';

import './Stage.scss';

class Stage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      isDirty: false,
      popup: {
        shown: false,
        password: '',
        errors: {},
      },
      originalValues: {
        isUpToDate: false,
        values: '',
      },
    };
    this.formContainerRef = React.createRef();
  }

  closePopup = () => {
    this.setState((state) => {
      return {
        ...state,
        popup: {
          ...state.popup,
          shown: false,
          password: '',
          errors: {},
        },
      };
    });
  };

  setOriginalValues = (newOriginalValues) => {
    this.setState({ isDirty: false, originalValues: { isUpToDate: true, value: newOriginalValues } });
  };

  setOriginalValuesToOutOfDate = () => {
    this.setState({ originalValues: { ...this.state.originalValues, isUpToDate: false } });
  };

  setIsDirty = (isDirty) => {
    if (isProcessViewEnabled(this.props.userData.rules)) {
      this.setState({ isDirty: isDirty });
    }
  };

  handleInputChange = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    this.setState((state) => {
      return {
        ...state,
        popup: {
          ...state.popup,
          password: value,
          errors: { ...state.popup.errors, ...formValidator(name, value) },
        },
      };
    });
  };

  deleteSubmission = () => {
    axios
      .delete('/drf/submissions/' + this.props.match.params.submissionId, {
        data: {
          currentPassword: this.state.popup.password,
        },
        headers: {
          Authorization: 'Bearer ' + this.props.token,
        },
      })
      .then((response) => {
        this.props.fetchEntities();
        this.closePopup();
        this.props.history.push('/');
      })
      .catch((e) => {
        this.setState((state) => {
          return {
            ...state,
            popup: {
              ...state.popup,
              shown: true,
              errors: { ...state.popup.errors, password: I18n.t(e.response.data.error) },
            },
          };
        });
      });
  };

  componentDidMount = () => {
    this.props.getSubmission(this.props.match.params.submissionId, this.props.token);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.submissionId !== prevProps.match.params.submissionId) {
      this.props.getSubmission(this.props.match.params.submissionId, this.props.token);
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.formContainerRef && this.formContainerRef.current && this.formContainerRef.current.getScrollElement) {
        this.formContainerRef.current.getScrollElement().scrollTop = 0;
      }
    }

    this.props.updateCurrentStage({
      submissionId: this.props.match.params.submissionId,
      stageId: this.props.match.params.stageId,
      stages: this.props.stages,
    });
  };

  render() {
    const { stage, stages, stageMeta, params, submission, submissions, modules } = this.props;

    if (submission && !submission.loading && stages && stage) {
      const module = modules.modules[submission.moduleId];
      const stages = module.stages || [];
      const stageMeta = module.stageMeta;

      const mergedStages = stages.map((stage) => {
        const foundStageMeta = stageMeta.find((sm) => sm.stageId === stage._id);
        return { ...foundStageMeta, ...stage };
      });
      const sortedStages = mergedStages.sort((a, b) => a.position - b.position);
      const currentStage = sortedStages.find((s) => s._id === stage._id);
      const nextStage = sortedStages.find((s) => s.position === currentStage.position + 1);
      const prevStage = sortedStages.find((s) => s.position === currentStage.position - 1);
      const groupIds = mergedStages
        .reduce((acc, stage) => {
          acc.push(...stage.groupMeta);
          return acc;
        }, [])
        .map((gm) => gm.groupId);

      if (stage.groupMeta.length > 0 && stage.groupMeta[0].renderType === 'messenger') {
        return (
          <MessengerDataInputContainer
            submissionId={submission._id}
            groupIds={groupIds}
            stageId={stage._id}
            nextStage={nextStage}
            history={this.props.history}
            params={this.props.match.params}
          />
        );
      }

      if (stage.type === 'messenger-summary') {
        return (
          <MessengerDataCheck
            history={this.props.history}
            submissionId={submission._id}
            stageId={stage._id}
            prevStage={prevStage}
            groupIds={groupIds}
          />
        );
      }
    }

    const { popup } = this.state;
    const popupOkDisabled = !!popup.errors.password || popup.password.length < 1;
    if (!stage || !submission) return null;
    const entityId = submission.entityId;
    const isBlocked = submission.isBlocked;
    const blockContent = isBlocked && stage.blockContent ? translate(stage.blockContent) : null;
    const blockDescription = isBlocked && stage.description ? translate(stage.description) : null;
    const isDisabledDeleteButton =
      params.submissionId &&
      submissions[params.submissionId] &&
      submissions[params.submissionId].moduleId &&
      modules.modules[submissions[params.submissionId].moduleId].autoCreateEntity;
    const stagePosition = stageMeta.length > 0 ? stageMeta.find((sm) => sm.stageId == stage._id).position : -1;

    const filteredStageMeta = stageMeta
      .map((sm) => {
        const stageInList = stages[sm.stageId];
        if (stageInList.type !== 'data-input') {
          return { ...sm, hidden: false };
        }

        const groupMetaExtendedWithHidden = stageInList.groupMeta.map((gm) => {
          let groupResult = submission.data.find((result) => result.groupId === gm.groupId);

          return {
            ...gm,
            hidden:
              groupResult && 'hidden' in groupResult && groupResult.values.length > 0
                ? groupResult.hidden
                : gm.options && gm.options.hidden,
          };
        });

        return { ...sm, hidden: !groupMetaExtendedWithHidden.some((g) => !g.hidden) };
      })
      .filter((sm) => {
        if (this.props.processViewShowHiddenFields) {
          return true;
        }

        return !sm.hidden;
      });

    const blockedPosition = filteredStageMeta.findIndex((sm) => sm.stageId === isBlocked);

    const filteredStageMetaIds = filteredStageMeta.map((sm) => sm.stageId);
    if (!filteredStageMetaIds.includes(stage._id)) {
      if (filteredStageMetaIds.includes(submission.stageId)) {
        return <Redirect to={`${submission.stageId}`} />;
      } else if (stageMeta && stageMeta.length) {
        const firstStage = [...stageMeta].sort((a, b) => a.position - b.position)[0];
        if (firstStage && firstStage.stageId) {
          return <Redirect to={`${firstStage.stageId}`} />;
        }
      }
    }

    let stageComponent = null;
    if (isBlocked && blockedPosition < 0 && blockedPosition <= stagePosition) {
      stageComponent = (
        <StoppedStage blockContent={blockContent} isBlocked={isBlocked} submissionId={params.submissionId} />
      );
    } else {
      switch (stage.type) {
        case 'data-input':
          stageComponent = (
            <FormGroups
              groupMeta={stage.groupMeta}
              params={params}
              setIsDirty={this.setIsDirty}
              originalValues={this.state.originalValues}
              setOriginalValues={this.setOriginalValues}
            />
          );
          break;
        case 'document-library':
          stageComponent = <DocumentLibrary groupMeta={stage.groupMeta} params={params} />;
          break;
        case 'summary':
          stageComponent = <DataCheckStage params={params} filteredStageMeta={filteredStageMeta} />;
          break;
        case 'manual-override':
          stageComponent = (
            <StoppedStage
              isBlocked={isBlocked}
              blockContent={blockContent}
              blockDescription={blockDescription}
              submissionId={params.submissionId}
            />
          );
          break;
        case 'payment':
          stageComponent = (
            <PaymentStage submissionId={params.submissionId} stageId={params.stageId}>
              <FormGroups
                groupMeta={stage.groupMeta}
                params={params}
                setIsDirty={this.setIsDirty}
                originalValues={this.state.originalValues}
                setOriginalValues={this.setOriginalValues}
              />
            </PaymentStage>
          );
          break;
        default:
      }
    }

    let stageIsBlocked = isBlocked || stage.type === 'manual-override';
    let stageDescriptionShown = translate(stage.description) && !stageIsBlocked;

    let deleteButton = {
      text: I18n.t('submission/delete-submission'),
      onClick: () =>
        this.setState((state) => {
          return { ...state, popup: { ...state.popup, shown: true } };
        }),
      rules: ['delete-submission'],
      disabled: isDisabledDeleteButton,
      tooltipText: I18n.t('submission/delete-not-allowed'),
    };
    return (
      <div className="stage-container">
        <Popup
          popupShown={popup.shown}
          popupTitle={I18n.t('delete-process/popup-title')}
          cancelHandler={this.closePopup}
          okHandler={this.deleteSubmission}
          okDisabled={popupOkDisabled}
        >
          <div className="form-container">
            <span>{I18n.t('delete-process/popup-text')}</span>
            <InputWithError
              placeholder={I18n.t('form/password')}
              name="password"
              type="password"
              value={popup.password}
              onChange={this.handleInputChange}
              errorMsg={popup.errors.password}
            />
          </div>
        </Popup>
        <SideBar
          entityId={entityId}
          stage={stage}
          filteredStageMeta={filteredStageMeta}
          params={params}
          history={this.props.history}
          deleteButton={deleteButton}
        />
        <div className="stage">
          <Navigation
            stage={stage}
            filteredStageMeta={filteredStageMeta}
            submission={submission}
            params={params}
            history={this.props.history}
            entityId={entityId}
            isDirty={this.state.isDirty}
            setOriginalValuesToOutOfDate={this.setOriginalValuesToOutOfDate}
          />

          {stage.type === 'payment' && <PaymentStatusBar />}

          <div className={'form-container scroll-to-top hide-scrollbar ' + (stageIsBlocked ? 'blocked-stage' : '')}>
            <SimpleBar ref={this.formContainerRef} style={{ maxHeight: '100%' }}>
              {stageDescriptionShown && (
                <div className="description-box">
                  <span dangerouslySetInnerHTML={{ __html: translate(stage.description) }}></span>
                </div>
              )}
              {stageComponent}
            </SimpleBar>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { params } = ownProps.match;
  const { history } = ownProps;
  const submission = state.submissions[params.submissionId] || null;
  const stageMeta =
    submission && state.modules.modules[submission.moduleId] && state.modules.modules[submission.moduleId].stageMeta
      ? state.modules.modules[submission.moduleId].stageMeta
      : [];
  return {
    authenticated: state.auth.authenticated,
    userData: state.auth.userData,
    token: state.auth.token,
    modules: state.modules,
    stage: getStage(state.modules.stages, stageMeta, params.stageId, state.auth.userData.isLimited),
    stages: state.modules.stages,
    submissions: state.submissions,
    submission,
    stageMeta,
    params,
    history,
    processViewShowHiddenFields: state.currentStage.processViewShowHiddenFields,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSubmission: submissionThunks.getSubmission,
      fetchEntities: entityThunks.fetchEntities,
      setModuleData: moduleActions.setModuleData,
      updateCurrentStage,
    },
    dispatch
  );
}

export default Authorization(connect(mapStateToProps, mapDispatchToProps)(Stage), ['edit-submission']);

function Navigation({
  stage,
  filteredStageMeta,
  submission,
  params,
  history,
  entityId,
  isDirty,
  setOriginalValuesToOutOfDate,
}) {
  const isBigScreen = useIsBigScreen();

  return isBigScreen ? (
    <NavigationDesktop
      stage={stage}
      filteredStageMeta={filteredStageMeta}
      submission={submission}
      params={params}
      history={history}
      isDirty={isDirty}
      setOriginalValuesToOutOfDate={setOriginalValuesToOutOfDate}
    />
  ) : (
    <NavigationMobile
      stage={stage}
      filteredStageMeta={filteredStageMeta}
      submission={submission}
      params={params}
      history={history}
      entityId={entityId}
    />
  );
}

function NavigationDesktop({
  stage,
  filteredStageMeta,
  submission,
  params,
  history,
  isDirty,
  setOriginalValuesToOutOfDate,
}) {
  const isHidden = useMemo(() => {
    const found = filteredStageMeta.find((sm) => sm.stageId === stage._id);
    if (found) {
      return found.hidden;
    }

    return false;
  }, [filteredStageMeta, stage]);

  const title = isHidden ? `[${translate(stage.label)}]` : translate(stage.label);

  return (
    <div className="navigation">
      <div className="header-title">
        <TextWithTooltip textWithOverflow={title} />
      </div>
      <NavigationButtons
        stage={stage}
        filteredStageMeta={filteredStageMeta}
        submission={submission}
        params={params}
        history={history}
        isDirty={isDirty}
        setOriginalValuesToOutOfDate={setOriginalValuesToOutOfDate}
      />
    </div>
  );
}

function NavigationMobile({ stage, filteredStageMeta, submission, params, history, entityId }) {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const handleSidebarToggleClick = useCallback(() => {
    setSideBarOpen(!sideBarOpen);
  }, [sideBarOpen, setSideBarOpen]);

  return (
    <>
      <div bp="flex vertical-center" className="header-title">
        <BackButton title={I18n.t('submission/back-button')} backUrl={`/entity/${entityId}/submissions`} />
        <h2 className="title" dangerouslySetInnerHTML={{ __html: translate(stage.label) }}></h2>
      </div>
      <div className="navigation-mobile">
        <div>
          <Svg src={hamburger} onClick={handleSidebarToggleClick} />
        </div>
        <NavigationButtons
          stage={stage}
          filteredStageMeta={filteredStageMeta}
          submission={submission}
          params={params}
          history={history}
        />
      </div>
      {sideBarOpen && (
        <div className="sidebar">
          <StageList stage={stage} filteredStageMeta={filteredStageMeta} params={params} history={history} />
        </div>
      )}
    </>
  );
}

function SideBar({ entityId, stage, filteredStageMeta, params, history, deleteButton }) {
  const isBigScreen = useIsBigScreen();

  if (isBigScreen) {
    return (
      <SideBarDesktop
        entityId={entityId}
        stage={stage}
        filteredStageMeta={filteredStageMeta}
        params={params}
        history={history}
        deleteButton={deleteButton}
      />
    );
  } else {
    return null;
  }
}

function SideBarDesktop({ entityId, stage, filteredStageMeta, params, history, deleteButton }) {
  return (
    <div className="sidebar">
      <BackButton title={I18n.t('submission/back-button')} backUrl={`/entity/${entityId}/submissions`} />
      <StageList stage={stage} filteredStageMeta={filteredStageMeta} params={params} history={history} />
      <div className="sidebar-bottom">
        <DeleteButton {...deleteButton} />
        <div className="pwd-by-consolidity">
          {clientConfig && clientConfig.poweredBy && <img src={pwd_by_consolidity_logo}></img>}
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import axios from 'axios';

import InputWithError from '../InputWithError';
import Header from '../Header';
import Popup from '../Popup';
import WrappedLink from '../WrappedLink';
import Badges from '../Badges';

import * as authActions from '../../actions/auth';
import { formValidator, afterLogin } from '../../helper/functions';
import Footer from '../Footer';

import './LoginPage.scss';

class LoginPage extends React.Component {
  constructor() {
    super();
    this.state = {
      formData: {
        email: '',
        password: '',
      },
      errors: {},
      popupShown: false,
      popupText: '',
      errorsVisible: false,
      browserHasAutofill: false,
    };
    this.headerLinks = [{ to: '/registration', translateKey: 'menu/registration' }];
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push('/');
    }

    const autofillInterval = setInterval(() => {
      const hasAutoFillOnEmail =
        document.querySelector('#email') && document.querySelector('#email').matches(':-webkit-autofill');
      const hasAutoFillOnPassword =
        document.querySelector('#password') && document.querySelector('#password').matches(':-webkit-autofill');

      if (hasAutoFillOnEmail && hasAutoFillOnPassword) {
        clearInterval(autofillInterval);
        this.setState((state) => {
          return {
            ...state,
            browserHasAutofill: hasAutoFillOnEmail && hasAutoFillOnPassword,
          };
        });
      }
    }, 100);

    setTimeout(() => {
      clearInterval(autofillInterval);
    }, 2000);
  }

  handleInputChange = (event) => {
    const { target } = event;
    this.setState((state) => {
      return {
        formData: {
          ...state.formData,
          [target.name]: target.value,
        },
        errors: { ...state.errors, ...formValidator(target.name, target.value) },
        browserHasAutofill: false,
        errorsVisible: false,
      };
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.errors.password || this.state.errors.email) {
      this.setState((state) => {
        return {
          ...state,
          errorsVisible: true,
        };
      });
    } else {
      axios
        .post('/auth/login', { ...this.state.formData, type: 'email' })
        .then((response) => {
          afterLogin(this.props.login, response, this.state.formData.email, this.props.userData, this.props.history);
        })
        .catch((e) => {
          console.log(e);
          this.setState((state) => {
            return {
              ...state,
              popupShown: true,
              popupText: e.response.data.error,
            };
          });
        });
    }
  };

  goBack() {
    this.props.history.goBack();
  }

  popupOkHandler = () => {
    this.setState((state) => {
      return {
        ...state,
        popupShown: false,
        popupText: '',
      };
    });
    this.props.history.push('/login');
  };

  render() {
    const { formData, errors, popupShown, popupText, errorsVisible, browserHasAutofill } = this.state;
    const formValid = formData.email.length > 0 && formData.password.length > 0 && !errors.email;

    return (
      <div className="authentication-page-content">
        <Popup popupShown={popupShown} popupImg="error" okHandler={this.popupOkHandler}>
          <span>{I18n.t(popupText)}</span>
        </Popup>
        <Header links={this.headerLinks} />
        <h1 className="welcome-headline" dangerouslySetInnerHTML={{ __html: I18n.t('auth/welcome-headline') }} />
        <div bp="full-width-until@sm 6--max" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <div className="form-wrapper" style={{ minHeight: 380 }}>
            <h1 className="login-headline">
              <Translate value="login/title" />
            </h1>
            <div className="form-container">
              <form onSubmit={this.handleSubmit}>
                <InputWithError
                  placeholder={I18n.t('form/email')}
                  name="email"
                  type="email"
                  id="email"
                  value={formData.email}
                  autoFilled={browserHasAutofill}
                  onChange={this.handleInputChange}
                  errorMsg={errorsVisible ? errors.email : ''}
                />
                <InputWithError
                  placeholder={I18n.t('form/password')}
                  name="password"
                  type="password"
                  id="password"
                  value={formData.password}
                  autoFilled={browserHasAutofill}
                  onChange={this.handleInputChange}
                  errorMsg={errorsVisible ? errors.password : ''}
                />
                <div className="button-container one-child">
                  <button className="secondary" type="submit" disabled={!formValid && !browserHasAutofill}>
                    <Translate value="login/title" />
                  </button>
                </div>
              </form>
            </div>
            <div className="forgotten-password-container">
              <WrappedLink to={'forgottenpassword'}>
                <Translate className="forgotten-password" value="forgotten-password/title" />
              </WrappedLink>
            </div>
            <div className="underline">
              <div className="line"></div>
            </div>
            <span style={{ height: '25px' }}></span>
            <div className="button-container">
              <WrappedLink className="button secondary" to={'/registration'}>
                <Translate value="registration/title" />
              </WrappedLink>
            </div>
          </div>
          <Badges />
        </div>
        <Footer />
      </div>
    );
  }
}

LoginPage.propTypes = {
  login: PropTypes.func,
  authenticated: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    userData: state.auth.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login: authActions.login,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

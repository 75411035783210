import React from 'react';
import { I18n } from 'react-redux-i18n';

import SubHeader from './SubHeader';
import TextWithTooltip from '../TextWithTooltip';
import ViewOptions from '../ViewOptions';
import Filterbar from '../Filterbar';
import MobileFilter from '../Filterbar/MobileFilter';
import BackButton from '../BackButton';

import { useIsBigScreen } from '../../helper/functions';

const ResponsiveSubHeader = ({ filterbarProps, backButton, backBtnTitle, pathname, subPageTitle }) => {
  const isBigScreen = useIsBigScreen();
  const isNewProcessesPage = pathname && !pathname.includes('new-processes');

  if (isBigScreen) {
    return (
      <>
        <SubHeader>
          {isNewProcessesPage && (
            <BackButton
              title={I18n.t(backBtnTitle)}
              backUrl="/client-management/entities"
              className="negative-margin"
            />
          )}
          <div className="column">
            <div className="title">
              <TextWithTooltip textWithOverflow={subPageTitle} />
            </div>
          </div>
          <div className="column">
            <ViewOptions options={['toggleFilterBar']} />
          </div>
        </SubHeader>
        <Filterbar {...filterbarProps} />
      </>
    );
  } else {
    return (
      <MobileFilter
        filterbarProps={filterbarProps}
        backButton={backButton}
        backBtnTitle={backBtnTitle}
        pathname={pathname}
        subPageTitle={subPageTitle}
      />
    );
  }
};

export default ResponsiveSubHeader;

import React from 'react';
import { I18n } from 'react-redux-i18n';

import StatusIndicator from '../StatusIndicator';
import TextWithTooltip from '../TextWithTooltip';
import ResponsiveTable from '../ResponsiveTable';

import { translate, getDateInLocalFormat } from '../../helper/functions';
import { beautifyLongTextArray } from '../../helper/functions';

import './EntityTable.scss';

const EntityTable = (props) => {
  const { entities, goToEntity, tableHeaderColumns, showStatusIndicator = true, extraContent } = props;

  const uniqeColumns = [
    { name: I18n.t('entity-list-table-header/name'), class: 'col-4 sticky', sortName: 'name' },
    {
      name: I18n.t('entity-list-table-header/creatorModule'),
      class: 'col-3 bigger-padding',
      sortName: 'creatorModule',
    },
    { name: I18n.t('entity-list-table-header/allocate'), class: 'allocate sticky', sortName: 'allocate' },
  ];
  const headerColumns = tableHeaderColumns.map((hc) => {
    let uniqeColumn = null;
    if (uniqeColumns.map((uc) => uc.sortName).includes(hc)) {
      uniqeColumn = uniqeColumns.find((uc) => uc.sortName === hc);
    }
    return {
      name: I18n.t('entity-list-table-header/' + hc),
      class: uniqeColumn ? uniqeColumn.class : '',
      sortName: hc,
    };
  });

  const renderCell = (headerColumn, entity) => {
    switch (headerColumn) {
      case 'name':
        return (
          <div className="entity-name">
            {showStatusIndicator && <div className={'status ' + entity.state}></div>}
            <div className="name-container">
              <TextWithTooltip textWithOverflow={entity.data[headerColumn]} />
            </div>
          </div>
        );
      case 'updated':
      case 'created':
      case 'lastSubmissionUpdate':
        return entity[headerColumn] ? getDateInLocalFormat(entity[headerColumn]) : '-';
      case 'activeSubmission':
      case 'allSubmission':
        return entity[headerColumn];
      case 'state':
        return <StatusIndicator state={entity[headerColumn]} />;
      case 'creatorModule':
        return <TextWithTooltip textWithOverflow={translate(entity[headerColumn].label)} />;
      case 'users':
        return (
          <TextWithTooltip
            textWithOverflow={entity[headerColumn].length === 0 ? '-' : entity[headerColumn][0].profile.name}
          />
        );
      case 'adminUsers':
        return (
          <TextWithTooltip
            textWithOverflow={beautifyLongTextArray(
              entity[headerColumn].map((user) => user.profile.name),
              2,
              'and',
              'others'
            )}
            tooltipContent={entity[headerColumn].map((user) => user.profile.name).join(', ')}
            showTooltipIfTextNotOverflow={entity[headerColumn].length > 2}
          />
        );
      default:
        if (extraContent) {
          return extraContent(entity);
        }
        return null;
    }
  };

  const onColumnClick = (column, entity) => {
    if (column !== 'allocate') {
      goToEntity(entity);
    }
  };

  return (
    <ResponsiveTable
      className="entity-table"
      headerColumns={headerColumns}
      data={entities}
      defaultSort={extraContent ? 'allocate' : 'created'}
      isAscending={false}
      renderRow={(entity, options) => {
        const { stickyPosition } = options;
        return (
          <div key={options.index} className={'table-row ' + entity.state}>
            {tableHeaderColumns.map((hc, columnIndex) => {
              const column = uniqeColumns.find((uc) => uc.sortName === hc);
              return (
                <div
                  key={columnIndex}
                  className={`column ${column ? column.class : ''}`}
                  onClick={() => onColumnClick(hc, entity)}
                  style={
                    tableHeaderColumns.includes('allocate') && hc === 'name' && !!stickyPosition
                      ? { left: stickyPosition }
                      : null
                  }
                >
                  {renderCell(hc, entity)}
                </div>
              );
            })}
          </div>
        );
      }}
    ></ResponsiveTable>
  );
};

export default EntityTable;

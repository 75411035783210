import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setSubmission } from '../../../reducers/submissions-slice';
import { setGroupValues } from '../../../reducers/groups-slice';
import { setFieldValues } from '../../../reducers/field-values-slice';

export function useLoadSubmissionFromLocalStorage() {
  const dispatch = useDispatch();
  useEffect(() => {
    const submission = JSON.parse(window.localStorage.getItem('submission'));
    const fieldValues = JSON.parse(window.localStorage.getItem('fieldValues'));
    const groupValues = JSON.parse(window.localStorage.getItem('groupValues'));

    if (submission && fieldValues && groupValues) {
      dispatch(setSubmission(submission));
      dispatch(setFieldValues(fieldValues));
      dispatch(setGroupValues(groupValues));
    }
  }, []);
}

export function setCheckboxValue(currentAnswer, setCurrentAnswer, value) {
  if (Array.isArray(currentAnswer) && currentAnswer.some((ca) => ca.id === value.id)) {
    setCurrentAnswer(currentAnswer.filter((ca) => ca.id !== value.id));
  } else {
    setCurrentAnswer([...currentAnswer, value]);
  }
}

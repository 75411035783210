import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';

import { SimplePopup } from '../../../../components/Popup';

import { useInterval } from '../../../../helper/hooks';
import { pageStates } from '../../../../helper/payment';
import { setPopupWrapper } from '../../../../reducers/current-stage';

import './PaymentPopup.scss';
import smiley from '../../../../assets/smiley.svg';
import sad from '../../../../assets/smiley_sad.svg';

function PaymentPopup({ isOpen, state, closeHandler }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPopupWrapper({ isOpen }));
  }, [isOpen]);

  return (
    <SimplePopup popupShown={isOpen}>
      <>{renderContent(state, closeHandler)}</>
    </SimplePopup>
  );
}

PaymentPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
};

export default PaymentPopup;

function renderContent(state, closeHandler) {
  switch (state) {
    case pageStates.errorPayment:
      return <Error closeHandler={closeHandler} />;
    case pageStates.payed:
      return <Payed closeHandler={closeHandler} />;
    case pageStates.processingPayment:
    case pageStates.waitingForPaymentInfo:
      return <Processing />;
    default:
      return null;
  }
}

function Error({ closeHandler }) {
  return (
    <div>
      <img src={sad} style={{ marginBottom: 18 }} />
      <div className="payment-text">{I18n.t('payment/error-popup')}</div>
      <button className="confirm" onClick={closeHandler}>
        {I18n.t('payment/popup-ok')}
      </button>
    </div>
  );
}

Error.propTypes = {
  closeHandler: PropTypes.func.isRequired,
};

function Payed({ closeHandler }) {
  return (
    <div>
      <img src={smiley} style={{ marginBottom: 18 }} />
      <div className="payment-text">{I18n.t('payment/successful-popup')}</div>
      <button className="confirm" onClick={closeHandler}>
        {I18n.t('payment/popup-ok')}
      </button>
    </div>
  );
}

Payed.propTypes = {
  closeHandler: PropTypes.func.isRequired,
};

function Processing() {
  const [progressbarWidth, setProgressbarWidth] = useState(0);
  useInterval(() => {
    if (progressbarWidth <= 98) {
      setProgressbarWidth(progressbarWidth + 0.09);
    }
  }, 10);

  return (
    <>
      <div className="progress-bar">
        <div className="data-progress" style={{ width: `${progressbarWidth}%` }}></div>
      </div>
      <div className="payment-text">{I18n.t('payment/please-wait')}</div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';

import GroupResult from '../GroupResult/GroupResult';
import Authorization from '../Authorization';
import SideBarNavigationMobile from '../SideBarNavigationMobile';
import ResponsiveSubHeader from '../SubHeader/ResponsiveSubHeader';
import MobileHeaderLinks from '../MobileHeaderLinks';

import { translate, getLanguage, useIsBigScreen } from '../../helper/functions';

import './EntityDataSummary.scss';

const EntityDataSummary = (props) => {
  const { entity, subPageTitle, groups, backButton, navigation } = props;
  const isBigScreen = useIsBigScreen();

  const defaultSearchAndFilter = {
    search: '',
    groupLabel: '',
  };
  const [searchAndFilter, setSearchAndFilter] = useState(defaultSearchAndFilter);
  const [entityData, setEntityData] = useState([]);

  useEffect(() => {
    setEntityData(
      entity.entityData
        .filter((group) => onlyMatchingGroupLabel(group))
        .sort((a, b) => new Date(a.created) - new Date(b.created))
        .map((group, index, a) => ({ ...group, isOpen: index === 0 }))
    );
  }, [entity, searchAndFilter]);

  const onlyMatchingGroupLabel = (group) =>
    ['', 'all', groups[group.groupId].label].includes(searchAndFilter.groupLabel);

  const filterbarProps = {
    searchbar: {
      shown: true,
      type: 'text',
      placeholder: 'entity-details/search-for',
      onSearchChange: () => {
        setSearchAndFilter({
          ...searchAndFilter,
          search: event.target.value,
        });
      },
    },
    selects: [
      {
        name: 'group-label',
        className: 'group-label-selector',
        selected: searchAndFilter.groupLabel,
        onSelectChange: (selectValue) =>
          setSearchAndFilter({
            ...searchAndFilter,
            groupLabel: selectValue,
          }),
        options: entity.entityData
          .filter(
            (g, i, a) =>
              !!groups[g.groupId] &&
              !groups[g.groupId].fieldMeta.every((f) => f.options.hidden) &&
              a.findIndex(
                (group) =>
                  groups[g.groupId] && groups[group.groupId] && groups[group.groupId].label === groups[g.groupId].label
              ) === i
          )
          .map((group) => ({
            value: groups[group.groupId].label,
            label: translate(groups[group.groupId].label),
          }))
          .sort((a, b) => new Intl.Collator(getLanguage()).compare(a.label, b.label)),
      },
    ],
    clearSearchAndFilter: () => setSearchAndFilter(defaultSearchAndFilter),
  };

  return (
    <div className="entity-data-summary">
      <ResponsiveSubHeader subPageTitle={subPageTitle} backButton={backButton} filterbarProps={filterbarProps} />
      {!isBigScreen && <SideBarNavigationMobile navigation={navigation} />}
      <div className="table-container hide-scrollbar scroll-to-top">
        <SimpleBar style={{ maxHeight: '100%' }}>
          {entityData
            .filter((g) => !!groups[g.groupId] && !groups[g.groupId].fieldMeta.every((f) => f.options.hidden))
            .map((data) => (
              <GroupResult result={data} key={data.groupId} search={searchAndFilter.search} />
            ))}
        </SimpleBar>
      </div>
      {isBigScreen ? null : <MobileHeaderLinks />}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    entity: state.entity.entity,
    groups: state.modules.groups,
  };
}

export default Authorization(connect(mapStateToProps, null)(EntityDataSummary), ['list-submissions']);

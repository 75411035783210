import { createSlice } from '@reduxjs/toolkit';

const groupsSlice = createSlice({
  name: 'groups',
  initialState: {},
  reducers: {
    setGroupValues: (state, { payload }) => {
      Object.entries(payload).forEach((entry) => {
        if (!state[entry[0]]) {
          state[entry[0]] = {};
        }

        state[entry[0]] = entry[1];
      });
    },
  },
  extraReducers: {
    'submissionsPlus/submissionsDataFetched': (state, { payload: { data } }) => {
      if (!state[data._id]) {
        state[data._id] = {};
      }

      for (const group of data.data) {
        state[data._id][group.groupId] = { ...group, values: group.values.map((field) => field.fieldId) };
      }
    },
    'submissions/addNewGroupResult': (state, { payload }) => {
      if (!state[payload.submissionId]) {
        state[payload.submissionId] = {};
      }

      if (!state[payload.submissionId][payload.groupId]) {
        state[payload.submissionId][payload.groupId] = {
          groupId: payload.groupId,
          position: payload.position,
          values: [],
        };
      }
    },
    'submissions/changeValue': (state, { payload }) => {
      if (!state[payload.submissionId][payload.groupId]) {
        state[payload.submissionId][payload.groupId] = {
          values: [],
        };
      }

      if (!state[payload.submissionId][payload.groupId].values.some((v) => v === payload.fieldId)) {
        state[payload.submissionId][payload.groupId].values.push(payload.fieldId);
      }
    },
  },
});

export const { setGroupValues } = groupsSlice.actions;
export default groupsSlice.reducer;

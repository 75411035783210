import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import axios from 'axios';

import { ReadonlyInput } from 'components/Input';
import Header from '../Header';

function AnonymusProfilePage(props) {
  const authenticated = useSelector((state) => state.auth.authenticated);
  const token = useSelector((state) => state.auth.token);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (authenticated) {
      axios
        .get('/user/profile', {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((response) => {
          setName(response.data.name);
          setEmail(response.data.email);
        });
    } else {
      this.props.history.push('/');
    }
  }, []);

  return (
    <div className="profile-page-content">
      <Header />
      <div bp="full-width-until@sm 6--max" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="form-wrapper" style={{ minHeight: 280 }}>
          <h1>
            <Translate value="profile/title" />
          </h1>
          <div className="form-container">
            <form>
              <div className="form-content">
                <ReadonlyInput
                  placeholder={I18n.t('form/name')}
                  name="name"
                  type="name"
                  value={name}
                  readOnly
                  disabled
                />
                <ReadonlyInput
                  placeholder={I18n.t('form/email')}
                  name="email"
                  type="email"
                  value={email}
                  readOnly
                  disabled
                />
              </div>
            </form>
          </div>
          <div className="button-container" style={{ justifyContent: 'center' }}>
            <button className="cancel" onClick={() => props.history.goBack()}>
              <Translate value="form/cancel" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

AnonymusProfilePage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AnonymusProfilePage;

import React, { useState, useLayoutEffect, useRef, useEffect, useCallback } from 'react';
import SimpleBar from 'simplebar-react';

import TextWithTooltip from '../TextWithTooltip';
import Svg from '../Svg';

import { getSortFunctionWithDirection } from '../../helper/functions';

import sort from '../../assets/sort.svg';
import ascending from '../../assets/sort_ascending_icon.svg';
import descending from '../../assets/sort_descending_icon.svg';
import arrow from '../../assets/accordion_arrow.svg';

import './ResponsiveTable.scss';

const ResponsiveTableWithGroupingDesktop = ({
  headerColumns,
  data,
  defaultSort = 'clientName',
  isAscending = true,
  className,
  renderRow = () => null,
}) => {
  const [propertyAndDirection, setPropertyAndDirection] = useState({
    property: defaultSort,
    isAscending,
  });
  const [stickyPosition, setStickyPosition] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef.current]);

  useLayoutEffect(() => {
    const resizeListener = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
      setTotalWidth(0);
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const stickyRef = useCallback((node) => {
    if (node !== null) {
      const width = node.getBoundingClientRect().width;
      setStickyPosition(width);
    }
  });
  const lastColumnRef = useCallback((node) => {
    if (node !== null) {
      const width = node.getBoundingClientRect().width;
      setTotalWidth(node.offsetLeft + width);
    }
  });
  const sortedData = [...data].map((d, index) => ({
    index,
    value: d.documents.sort(
      getSortFunctionWithDirection(propertyAndDirection.property, propertyAndDirection.isAscending)
    ),
    title: d.title,
    referenceTitle: d.referenceTitle,
  }));

  return (
    <div className={`responsive-table-container ${className || ''}`} ref={containerRef}>
      {/* extra wrapper div needed because of safari & position sticky https://stackoverflow.com/questions/57934803/workaround-for-a-safari-position-sticky-webkit-sticky-bug */}
      <SimpleBar style={{ maxHeight: '100%' }}>
        <div style={{ minWidth: totalWidth }}>
          <div className="table-header">
            {headerColumns.map((c, index) => (
              <div
                key={index}
                className={`column ${c.class} ${!index ? 'sticky' : ''}`}
                ref={!index ? stickyRef : index === headerColumns.length - 1 ? lastColumnRef : null}
                onClick={() => {
                  c.sortName &&
                    setPropertyAndDirection({
                      property: c.sortName,
                      isAscending: !propertyAndDirection.isAscending,
                    });
                }}
                style={c.class.includes('sticky') && index && stickyPosition ? { left: stickyPosition } : null}
              >
                <TextWithTooltip textWithOverflow={c.name} />
                {c.sortName && c.sortName !== propertyAndDirection.property && (
                  <Svg className="sort-svg" src={sort} otherColor="#000" />
                )}
                {c.sortName === propertyAndDirection.property && (
                  <Svg
                    className="sorted-svg"
                    src={propertyAndDirection.isAscending ? ascending : descending}
                    otherColor="#000"
                  />
                )}
              </div>
            ))}
          </div>
          {renderResponsiveTableGroup(sortedData, stickyPosition, containerWidth, renderRow)}
        </div>
      </SimpleBar>
    </div>
  );
};

export default ResponsiveTableWithGroupingDesktop;

function renderResponsiveTableGroup(sortedData, stickyPosition, containerWidth, renderRow) {
  if (sortedData.length === 1) {
    return sortedData.map((d) => (
      <ResponsiveTableGroupWithoutHeader
        key={d.index}
        rows={d.value}
        stickyPosition={stickyPosition}
        containerWidth={containerWidth}
        renderRow={renderRow}
      />
    ));
  }

  return sortedData.map((d) => (
    <ResponsiveTableGroup
      key={d.index}
      rows={d.value}
      title={d.title}
      referenceTitle={d.referenceTitle}
      stickyPosition={stickyPosition}
      containerWidth={containerWidth}
      renderRow={renderRow}
    />
  ));
}

function ResponsiveTableGroupWithoutHeader({ rows, stickyPosition, containerWidth, renderRow }) {
  return (
    <div className="table-group">
      <div className={`table-content hide-scrollbar table-group-content`}>
        {rows.map((d, index) => {
          return renderRow(d, { index, stickyPosition, containerWidth });
        })}
      </div>
    </div>
  );
}

function ResponsiveTableGroup({ rows, title, referenceTitle, stickyPosition, containerWidth, renderRow }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="table-group">
      <div className="table-group-title">
        <div className="column group-title" onClick={() => setIsOpen(!isOpen)}>
          <span style={{ position: 'sticky', left: '20px' }}>
            {title}
            <span className="group-header-reference-name">{referenceTitle}</span>
          </span>
          <div className="group-actions">
            <div className={`arrow-container ${isOpen ? 'opened' : 'closed'}`}>
              <Svg src={arrow} hasHover={true} />
            </div>
          </div>
        </div>
      </div>
      <div className={`table-content hide-scrollbar table-group-content ${isOpen ? 'opened' : 'closed'}`}>
        {rows.map((d, index) => {
          return renderRow(d, { index, stickyPosition, containerWidth });
        })}
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import * as moduleActions from '../../actions/modules';

export function useGetPredefinedGroups() {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [groups, setGroups] = useState({ status: 'loading' });

  useEffect(() => {
    axios
      .get('/admin/drf/predefined-groups/enabled-groups ', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        dispatch(moduleActions.setFields(mapFieldsFromPredefinedGroups(response.data)));
        setGroups({ status: 'success', data: response.data });
      })
      .catch((e) => {
        setGroups({ status: 'error', error: e });
      });
  }, []);

  return groups;
}

function mapFieldsFromPredefinedGroups(groups) {
  return groups.reduce((acc, group) => {
    return [...acc, ...group.fields];
  }, []);
}

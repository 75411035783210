export const types = {
  SET_MODULES: 'SET_MODULES',
  SET_MODULE_DATA: 'SET_MODULE_DATA',
  SET_FIELDS: 'SET_FIELDS',
  SET_REFERENCE_COLLECTION: 'SET_REFERENCE_COLLECTION',
};

export function setModules(data) {
  return {
    type: types.SET_MODULES,
    data,
  };
}

export function setModuleData(data) {
  return {
    type: types.SET_MODULE_DATA,
    data,
  };
}

export function setFields(data) {
  return {
    type: types.SET_FIELDS,
    data,
  };
}

export function setReferenceCollection(name, data) {
  return {
    type: types.SET_REFERENCE_COLLECTION,
    name,
    data,
  };
}

import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';

import Svg from '../Svg';

import { getDateInLocalFormat } from '../../helper/functions';
import * as errorActions from '../../actions/error';

import './Comment.scss';

import message from '../../assets/message.svg';
import message_notification from '../../assets/message_notification.svg';
import trash from '../../assets/trash.svg';
import close from '../../assets/close_icon.svg';

const Comment = ({
  comment,
  submissionId,
  target,
  targetName,
  targetUrl,
  token,
  alternateColor = false,
  throwError,
}) => {
  const textareaRef = useRef();
  const [popupShown, togglePopup] = useState(false);
  const [savedCommentText, setSavedCommentText] = useState(comment ? comment.comment : '');
  const [commentText, setCommentText] = useState(comment ? comment.comment : '');
  useEffect(() => {
    if (target) {
      setCommentText(savedCommentText);
    }
  }, [popupShown, savedCommentText]);

  const saveComment = (value) => {
    axios
      .put(
        '/admin/drf/submissions/' + submissionId + targetUrl,
        [
          {
            ...target,
            comment: value,
          },
        ],
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(() => {
        setSavedCommentText(value);
      })
      .catch((e) => {
        throwError(e);
      });
  };

  const deleteComment = () => {
    saveComment(null);
  };

  const authorInfo = (comment) => {
    return comment ? (
      <div className="comment-popup-author-info">
        <div className="comment-popup-author-info-name">{/* username */}</div>
        <div className="comment-popup-author-info-time">{getDateInLocalFormat(comment.date, true)}</div>
      </div>
    ) : null;
  };

  return (
    <div className="comment-container">
      <div className="comment-icon" onClick={() => togglePopup(!popupShown)}>
        <Svg hasHover={true} src={savedCommentText ? message_notification : message} flipColors={alternateColor} />
      </div>
      {popupShown && (
        <div className="comment-popup">
          <div className="comment-popup-header">
            <div className="comment-popup-header-title">
              {/* TODO{submissionName} › */ <span dangerouslySetInnerHTML={{ __html: targetName }}></span>}
            </div>
            <div className="comment-popup-close" onClick={() => togglePopup(false)}>
              <Svg otherColor={'black'} src={close} />
            </div>
          </div>
          {!target ? (
            <div className="comment-popup-content">
              {authorInfo(comment)}
              <div className="comment-popup-content-text">
                <span>{comment.comment}</span>
              </div>
            </div>
          ) : (
            <div className="comment-popup-edit">
              {authorInfo(comment)}
              <textarea
                value={commentText || ''}
                onChange={(e) => setCommentText(e.target.value)}
                ref={textareaRef}
              ></textarea>
              <div className="comment-popup-buttons">
                <button className="delete-comment" onClick={deleteComment} disabled={!savedCommentText}>
                  <Svg src={trash} />
                  <Translate value="comment/delete" />
                </button>
                <button
                  className="save-comment"
                  disabled={commentText === savedCommentText}
                  onClick={() => saveComment(commentText)}
                >
                  <Translate value="comment/save" />
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    token: state.auth.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Comment);

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import axios from 'axios';

import InfoBox from '../InfoBox';
import ReactSelectWrapper from '../ReactSelectWrapper';

import * as modulesActions from '../../actions/modules';
import * as errorActions from '../../actions/error';
import { changeValue } from '../../reducers/submissions';

import { fieldValidatorForSubmission, transformValue } from '../../helper/functions';

class ReferenceGroupResultSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasAllGroups: false,
    };
  }

  componentDidMount() {
    const { groups, group, token, submission, setModuleData } = this.props;
    if (group.referenceGroupIds) {
      const missingGroups = group.referenceGroupIds.filter((groupId) => !groups[groupId]);
      if (missingGroups.length) {
        const header = { headers: { Authorization: 'Bearer ' + token } };
        axios
          .get(`/drf/submissions?entityId=${submission.entityId}`, header)
          .then((response) => {
            let uniqueModuleIds = [...new Set(response.data.map((s) => s.moduleId))];
            let requests = uniqueModuleIds.map((moduleId) => axios.get('/drf/modules/' + moduleId, header));
            return Promise.all(requests);
          })
          .then((responses) => {
            responses.forEach((r) => {
              setModuleData(r.data);
            });
            this.setState({ hasAllGroups: true });
          })
          .catch((e) => this.props.throwError(e));
      } else {
        this.setState({ hasAllGroups: true });
      }
    }
  }

  handleReferenceResultSelectorChange = (value, referenceResults) => {
    const referenceResult = referenceResults.find((r) => r.resultId === value.value);
    if (!referenceResult) return;

    this.props.group.fieldMeta.forEach((fieldMeta) => {
      const field = this.props.fields[fieldMeta.fieldId];
      if (field.type === 'separator') {
        return;
      }
      const fieldResult = referenceResult.values.find((resultValue) => resultValue.fieldId === fieldMeta.fieldId);
      if (fieldResult) {
        this.props.changeValue({
          submissionId: this.props.submission._id,
          groupId: this.props.group._id,
          groupPosition: this.props.groupIndex,
          fieldId: field._id,
          value: fieldResult.value,
          error: fieldValidatorForSubmission(
            fieldResult.value,
            field.validationRules,
            field.type,
            this.props.submission,
            referenceResult.groupId
          ),
          referenceResultId: referenceResult.resultId,
          resultFieldId: fieldResult.resultFieldId,
        });
      } else if (
        !this.props.groupResult.referenceResultId ||
        value.value !== this.props.groupResult.referenceResultId
      ) {
        this.props.changeValue({
          submissionId: this.props.submission._id,
          groupId: this.props.group._id,
          groupPosition: this.props.groupIndex,
          fieldId: field._id,
          value: null,
          error: fieldValidatorForSubmission(
            null,
            field.validationRules,
            field.type,
            this.props.submission,
            referenceResult.groupId
          ),
        });
      }
    });
  };

  render() {
    const { groups, group, results, groupResult } = this.props;
    let referenceResults = [];
    if (group.referenceGroupIds && this.state.hasAllGroups) {
      group.referenceGroupIds.forEach((referenceGroupId) => {
        const referenceGroupResults = results.filter((r) => r.groupId === referenceGroupId && groups[referenceGroupId]);
        referenceGroupResults.forEach((referenceGroupResult) => {
          // only add new referenceResult option if it has more matching fields than an already existing identical one
          const existingGroupResultIndex = referenceResults.findIndex((rR) => {
            return (
              groups[referenceGroupResult.groupId] &&
              groups[rR.groupId] &&
              transformValue(
                groups[referenceGroupResult.groupId].referenceNameTemplate,
                referenceGroupResult.values,
                this.props.fields
              ) === transformValue(groups[rR.groupId].referenceNameTemplate, rR.values, this.props.fields)
            );
          });
          const existingGroupResult = referenceResults[existingGroupResultIndex];
          if (existingGroupResult) {
            const groupFieldIds = group.fieldMeta.map((fieldMeta) => fieldMeta.fieldId);
            const existingGroupResultMatchingFieldCount = existingGroupResult.values.filter((value) =>
              groupFieldIds.includes(value.fieldId)
            );
            const newGroupResultMatchingFieldCount = referenceGroupResult.values.filter((value) =>
              groupFieldIds.includes(value.fieldId)
            );
            if (newGroupResultMatchingFieldCount > existingGroupResultMatchingFieldCount) {
              referenceResults.splice(existingGroupResultIndex, 1);
              referenceResults.push(referenceGroupResult);
            }
          } else {
            referenceResults.push(referenceGroupResult);
          }
        });
      });
    }
    if (!group.referenceGroupIds || referenceResults.length === 0 || !this.state.hasAllGroups) {
      return null;
    }

    let selectValue = null;
    if (groupResult.referenceResultId && groups[groupResult.groupId]) {
      selectValue = {
        value: groupResult.referenceResultId,
        label: transformValue(groups[groupResult.groupId].referenceNameTemplate, groupResult.values, this.props.fields),
      };
    }

    return (
      <div bp="grid" className={`predefined-selector form-row ${this.props.isOpen ? 'opened' : 'closed'}`}>
        <div bp="11 7@lg" className="left-container">
          <ReactSelectWrapper
            placeholder={I18n.t('drf/group-to-group-select-placeholder')}
            options={referenceResults
              .filter((r) => groups[r.groupId])
              .map((referenceResult) => {
                return {
                  value: referenceResult.resultId,
                  label: transformValue(
                    groups[referenceResult.groupId].referenceNameTemplate,
                    referenceResult.values,
                    this.props.fields
                  ),
                };
              })}
            onChange={(value) => this.handleReferenceResultSelectorChange(value, results)}
            isSearchable={true}
            value={selectValue}
          />
        </div>
        <div bp="1 5@lg" className="right-container">
          <InfoBox text={I18n.t('drf/group-to-group-help-text')} />
        </div>
      </div>
    );
  }
}

ReferenceGroupResultSelector.propTypes = {};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    fields: state.modules.fields,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeValue,
      setModuleData: modulesActions.setModuleData,
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceGroupResultSelector);

import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as errorActions from '../../actions/error';

import './ErrorBoundary.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error !== this.props.error && this.props.error) {
      this.setState({ hasError: true });
    }
    if (prevState.hasError !== this.state.hasError) {
      if (this.state.hasError) {
        this.props.history.push('/error');
      } else {
        this.props.resetError();
      }
    }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="generic-error-container">
          <h3 className="generic-error-title">{I18n.t('dashboard/error/title')}</h3>
          <span>{I18n.t('dashboard/error/message')}</span>
          <NavLink className="button" to="/" onClick={() => this.setState({ hasError: false })}>
            {I18n.t('dashboard/congratulations/home-button')}
          </NavLink>
        </div>
      );
    }

    return this.props.children;
  }
}

function mapStateToProps(state) {
  const { error } = state;
  return {
    error,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetError: errorActions.resetErrorMessage,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);

import { types } from '../actions/entity';
import { types as authTypes } from '../actions/auth';

export default function(
  state = {
    entities: [],
    isLoaded: false,
    entity: {},
  },
  action
) {
  switch (action.type) {
    case types.SET_ENTITIES:
      return {
        ...state,
        entities: action.data,
        isLoaded: true,
      };
    case types.SET_ENTITY:
      return {
        ...state,
        entity: mapEntityData(action.data, action.fields),
      };
    case types.REQUEST_ENTITIES:
      return {
        ...state,
        isLoaded: false,
        entities: [],
      };
    case authTypes.LOGOUT:
      return {
        entities: [],
      };
    default:
      return state;
  }
}

function mapEntityData(data, fields) {
  for (let i = 0; i < data.entityData.length; i++) {
    const entityData = data.entityData[i];
    for (let j = 0; j < entityData.values.length; j++) {
      const fieldId = entityData.values[j].fieldId;
      if (fields && fields[fieldId] && fields[fieldId].type === 'radio' && Array.isArray(entityData.values[j].value)) {
        data.entityData[i].values[j].value = entityData.values[j].value[0];
      }
    }
  }

  return data;
}

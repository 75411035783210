export const masks = {
  none: 'none',
  bank: 'bank',
  penny: 'penny',
  phone: 'phone',
};

export function removeMask(value) {
  return value.toString().split('-').join('').split(' ').join('');
}

import React from 'react';
import SimpleBar from 'simplebar-react';

import TextWithTooltip from '../TextWithTooltip';

import './ResponsiveTileView.scss';

const ResponsiveTileView2 = ({ titleName, tileContent, tableHeaderColumns, renderRow, onTileClick }) => {
  return (
    <div className={'tile-container hide-scrollbar grid-container'}>
      <SimpleBar style={{ maxHeight: '100%' }}>
        {tileContent.map((content, i) => {
          return (
            <div className="tile" key={i} onClick={() => onTileClick(content)}>
              <div className="tile-header">
                <div className="img-container"></div>
                <div className="tile-title">
                  <TextWithTooltip textWithOverflow={content[titleName]} textClassName="title" />
                </div>
              </div>
              <div className="tile-content">
                {tableHeaderColumns.map((tc, index) => {
                  return renderRow(tc, content, index);
                })}
              </div>
            </div>
          );
        })}
      </SimpleBar>
    </div>
  );
};

export default ResponsiveTileView2;

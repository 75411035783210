import React, { useCallback } from 'react';

import Svg from '../Svg';

import filtersClosed from '../../assets/filter_icon.svg';
import '../ViewOptions/ViewOptions.scss';

function ViewOptionsMobile({ setFilterPopupOpen }) {
  const handleClick = useCallback(() => {
    setFilterPopupOpen(true);
  }, []);

  return (
    <div className="view-options">
      <div onClick={handleClick}>
        <Svg src={filtersClosed} hasHover={true} />
      </div>
    </div>
  );
}

export default ViewOptionsMobile;

import React, { useState } from 'react';
import { Translate } from 'react-redux-i18n';

import AuthComponentWrapper from '../AuthComponentWrapper';
import SubHeader from '../SubHeader';
import FilterPopup from '../FilterPopup';
import Filterbar from '../Filterbar';
import ViewOptions from '../ViewOptions';
import ViewOptionsMobile from '../ViewOptionsMobile';
import WrappedLink from '../WrappedLink';

import { useIsBigScreen } from '../../helper/functions';

import add from '../../assets/add_white.svg';

import './Filter.scss';

const Filter = ({ componentName, filterbarProps, searchAndFilter, setSearchAndFilter }) => {
  const isBigScreen = useIsBigScreen();

  if (isBigScreen) {
    return <BigScreenFilter componentName={componentName} filterbarProps={filterbarProps} />;
  } else {
    return (
      <MobileFilter
        filterbarProps={filterbarProps}
        searchAndFilter={searchAndFilter}
        setSearchAndFilter={setSearchAndFilter}
      />
    );
  }
};

export default Filter;

function MobileFilter({ filterbarProps, searchAndFilter, setSearchAndFilter }) {
  const [filterbarIsOpen, setFilterbarIsOpen] = useState(false);

  return (
    <>
      <div className="mobile-filter">
        <FilterPopup
          searchAndFilter={searchAndFilter}
          setSearchAndFilter={setSearchAndFilter}
          filterbarIsOpen={filterbarIsOpen}
          setFilterbarIsOpen={setFilterbarIsOpen}
          {...filterbarProps}
        />
      </div>
      <SubHeader>
        <div className="filterbar-container" bp="flex full-width">
          <div bp="fill">
            <Filterbar {...filterbarProps} />
          </div>
          <div bp="fit">
            <ViewOptionsMobile setFilterPopupOpen={setFilterbarIsOpen} />
          </div>
        </div>
        <div className="floating-subheader-buttons">
          <AuthComponentWrapper allowedRules={['create-entity']}>
            <WrappedLink to="/client-management/create-new-entity">
              <button className="subheader-button">
                <img src={add} />
              </button>
            </WrappedLink>
          </AuthComponentWrapper>
        </div>
      </SubHeader>
    </>
  );
}

function BigScreenFilter({ componentName, filterbarProps }) {
  return (
    <>
      <SubHeader>
        <div className="column"></div>
        <div className="column">
          <ViewOptions componentName={componentName} />
          <AuthComponentWrapper allowedRules={['create-entity']}>
            <WrappedLink to="/client-management/create-new-entity">
              <button className="subheader-button">
                <img src={add} />
                <Translate value="entity-details/create-new-entity" />
              </button>
            </WrappedLink>
          </AuthComponentWrapper>
        </div>
      </SubHeader>
      <Filterbar {...filterbarProps} />
    </>
  );
}

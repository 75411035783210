import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';

import Field from '../Field';
import ReactSelectWrapper from '../ReactSelectWrapper';
import ReferenceGroupResultSelector from '../ReferenceGroupResultSelector';
import Svg from '../Svg';
import Comment from '../Comment';

import * as errorActions from '../../actions/error';
import {
  deleteGroupResult as deleteGroupResultAction,
  addNewGroupResult as addNewGroupResultAction,
  setStageResults as setStageResultsAction,
} from '../../reducers/submissions';
import { translate, allConditionsMet, getConditionsForGroup } from '../../helper/functions';

import add from '../../assets/add_white.svg';
import arrow from '../../assets/accordion_arrow.svg';
import error_icon from '../../assets/error_icon.svg';
import trash from '../../assets/blue_trash.svg';

import './Group.scss';

class Group extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      predefinedOptions: [],
      selectedPredefinedOptions: [],
      openedGroupsNotSet: true,
      openedGroups: [],
    };
  }

  componentDidMount() {
    if (document.getElementsByClassName('form-container')[0]) {
      document.getElementsByClassName('form-container')[0].scrollTop = 0;
    }
    if (this.props.dependingConditionsFailed) {
      this.deleteAllGroupResult();
    }
    this.initGroups();
    if (this.props.params && this.props.params.groupId) {
      this.toggleOpenGroup(this.props.params.groupId);
    } else {
      this.setState((state) => ({
        ...state,
        openedGroups: this.props.groupResults.map((gr) => `${gr.groupId}${gr.position}`),
      }));
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.dependingConditionsFailed && this.props.dependingConditionsFailed) {
      this.deleteAllGroupResult();
    }

    if (
      prevProps.group._id !== this.props.group._id ||
      prevProps.submissionId !== this.props.submissionId ||
      (prevProps.submission.loading && !this.props.submission.loading) ||
      prevProps.dependingConditionsFailed !== this.props.dependingConditionsFailed
    ) {
      this.initGroups();
    }

    if (this.state.openedGroupsNotSet && this.props.groupResults.length > 0) {
      this.setState({
        openedGroups: this.props.groupResults.map((gr) => `${gr.groupId}${gr.position}`),
        openedGroupsNotSet: false,
      });
    }
  }

  initGroups = () => {
    this.getPredefinedOptions();

    const { groupResults, groupMeta, submissionId } = this.props;
    const minGroupCount = groupMeta.options && groupMeta.options.min >= 0 ? groupMeta.options.min : 1;

    if (groupResults.length < minGroupCount) {
      for (let index = groupResults.length; index < minGroupCount; index++) {
        this.props.addNewGroupResult({ submissionId, groupId: groupMeta.groupId, position: index });
      }
    }
  };

  getPredefinedOptions = () => {
    if (this.props.group.predefined) {
      axios
        .get(`/drf/submissions/${this.props.submissionId}/predefined-groups`, {
          params: {
            groupId: this.props.group._id,
          },
          headers: {
            Authorization: 'Bearer ' + this.props.token,
          },
        })
        .then((response) => {
          this.setState((state) => ({
            ...state,
            predefinedOptions: response.data.map((v) => ({ value: v._id, label: translate(v.label) })),
          }));
        })
        .catch((e) => {
          this.props.throwError(e);
        });
    }
  };

  addNewGroupResult = (event) => {
    const newScrollTop = event.target.offsetTop - 150;
    const container = document.querySelector('.form-container');
    const { submissionId, groupMeta, groupResults, group, addNewGroupResult } = this.props;
    if (this.props.groupResults.length) {
      setTimeout(() => {
        if (!navigator.appVersion.includes('Edge')) {
          container.scrollTo({
            top: newScrollTop,
            left: 0,
            behavior: 'smooth',
          });
        } else {
          container.scrollTop = newScrollTop;
        }
      }, 100);
    }

    if (groupResults.length === 0) {
      const promise = new Promise(() =>
        addNewGroupResult({ submissionId, groupId: groupMeta.groupId, position: groupResults.length })
      );
      promise.then(() => this.openGroup(`${group._id}${groupResults.length}`));
    } else {
      addNewGroupResult({ submissionId, groupId: groupMeta.groupId, position: groupResults.length });
      this.openGroup(`${this.props.group._id}${this.props.groupResults.length}`);
    }
  };

  deleteAllGroupResult = () => {
    this.props.groupResults.forEach((groupResult) => this.deleteGroupResult(groupResult));
  };

  deleteGroupResult = (groupResult) => {
    if (this.props.submission.isBlocked) {
      return;
    }

    if (groupResult.resultId) {
      axios
        .delete(`/drf/submissions/${this.props.submissionId}/results/${groupResult.resultId}`, {
          headers: {
            Authorization: 'Bearer ' + this.props.token,
          },
        })
        .then((response) => {})
        .catch((e) => {
          this.props.throwError(e);
        });
    }
    this.setState((state) => {
      return {
        selectedPredefinedOptions: [],
      };
    });
    const shouldDeleteWholeGroupResult = this.props.groupResults.length > this.props.groupMeta.options.min;
    this.props.deleteGroupResult({
      submissionId: this.props.submissionId,
      groupId: groupResult.groupId,
      position: groupResult.position,
      shouldDeleteWholeGroupResult,
      fields: this.props.fields,
      submission: this.props.submission,
      dependingConditionsFailed: this.props.dependingConditionsFailed,
    });
  };

  handlePredefinedSelectorChange = (value, index) => {
    axios
      .get(`/drf/submissions/${this.props.submissionId}/predefined-groups/${value.value}`, {
        headers: {
          Authorization: 'Bearer ' + this.props.token,
        },
      })
      .then((response) => {
        this.props.setStageResults({
          submissionId: this.props.submissionId,
          groupResults: [{ ...response.data, predefinedId: response.data._id, position: index }],
          stageId: undefined,
          status: { state: 'success' },
        });
      })
      .catch((e) => {
        this.props.throwError(e);
      });

    // delete the previously selected predefined group if there is any
    const foundGroup = this.props.submission.data.find((s) => s.groupId === this.props.group._id);
    if (foundGroup && foundGroup.resultId) {
      axios.delete(`/drf/submissions/${this.props.submissionId}/results/${foundGroup.resultId}`, {
        headers: {
          Authorization: 'Bearer ' + this.props.token,
        },
      });
    }

    const newSelectedOptions = [...this.state.selectedPredefinedOptions];
    newSelectedOptions[index] = value;
    this.setState({
      selectedPredefinedOptions: newSelectedOptions,
    });
  };

  openGroup = (groupIdAndPosition) => {
    if (!this.state.openedGroups.includes(groupIdAndPosition)) {
      this.setState((state) => ({
        ...state,
        openedGroups: state.openedGroups.concat([groupIdAndPosition]),
      }));
    }
  };

  toggleOpenGroup = (groupIdAndPosition) => {
    if (this.state.openedGroups.includes(groupIdAndPosition)) {
      this.setState((state) => ({
        ...state,
        openedGroups: state.openedGroups.filter((id) => id !== groupIdAndPosition),
      }));
    } else {
      this.setState((state) => ({
        ...state,
        openedGroups: state.openedGroups.concat([groupIdAndPosition]),
      }));
    }
    const element = document.getElementById(groupIdAndPosition);
    if (element && !this.state.openedGroups.includes(groupIdAndPosition)) {
      if (!element.nextSibling) {
        element.style.marginBottom = '1000px';
      }
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
        element.style.marginBottom = '0px';
      }, 500);
    }
  };

  filterOption(item, search) {
    return item.label.toLowerCase().startsWith(search.toLowerCase()) || item.label.toLowerCase().includes(search);
  }

  render() {
    if (this.props.dependingConditionsFailed) return null;
    const { groupResults, groups, group, groupMeta, fields, results, submission, adminEditMode } = this.props;

    return (
      <div className="group-type-container">
        {!adminEditMode && groupResults.length === 0 && (
          <div className="group-title">
            <div className="group-title-text">{translate(group.label)}</div>
          </div>
        )}
        <div>
          {groupResults.map((groupResult, j) => {
            if (groupResult.hidden && groupResult.values.length === 0) {
              return null;
            }

            const uniqeId = `${groupResult.groupId}${groupResult.position}`;
            const showErrorInHeader =
              !this.state.openedGroups.includes(uniqeId) &&
              groupResults.find((gr) => gr.groupId === groupResult.groupId).values.some((gr) => gr.error);
            const isOpen = this.state.openedGroups.includes(uniqeId);
            const groupHidden =
              groupResult.hidden || (groupMeta.options && groupMeta.options.hidden && !('hidden' in groupResult));
            if (!adminEditMode && !this.props.processViewShowHiddenFields && groupHidden) {
              return null;
            }

            let groupTitle;
            if (groupHidden) {
              groupTitle = `[${translate(group.label)}]`;
            } else {
              groupTitle = translate(group.label);
            }

            return (
              <div className="group-wrapper" key={j} id={uniqeId}>
                {!adminEditMode && (
                  <div className="group-title">
                    <div className="group-title-text">{groupTitle}</div>
                    <div className="group-actions">
                      {groupResult && groupResult.comment && groupResult.comment.comment && (
                        <Comment
                          comment={groupResult.comment}
                          targetName={translate(group.label)}
                          alternateColor={true}
                        />
                      )}
                      {isDeleteGroupVisible(group, groupMeta, groupResults) && (
                        <Svg
                          src={trash}
                          hasHover={true}
                          style={{ opacity: this.props.submission.isBlocked ? 0.3 : 1 }}
                          onClick={() => this.deleteGroupResult(groupResult)}
                        />
                      )}
                      {showErrorInHeader && <Svg src={error_icon} otherColor={'#ED001B'} />}
                    </div>
                    <div className={`arrow-container ${isOpen ? 'opened' : ''}`}>
                      <Svg src={arrow} hasHover={true} onClick={() => this.toggleOpenGroup(uniqeId)} />
                    </div>
                  </div>
                )}
                {group.predefined && !!this.state.predefinedOptions.length && (
                  <div className={`predefined-selector ${isOpen ? 'opened' : 'closed'}`}>
                    <ReactSelectWrapper
                      placeholder={I18n.t('drf/predefined-select-placeholder')}
                      options={this.state.predefinedOptions}
                      onChange={(value) => this.handlePredefinedSelectorChange(value, j)}
                      value={this.state.selectedPredefinedOptions[j]}
                      filterOption={this.filterOption}
                      isSearchable={true}
                    />
                  </div>
                )}

                <ReferenceGroupResultSelector
                  results={results}
                  groupIndex={j}
                  group={group}
                  groups={groups}
                  fields={fields}
                  groupResult={groupResult}
                  submission={submission}
                  isOpen={isOpen}
                />
                <div className={`group ${isOpen ? 'opened' : 'closed'}`} key={j}>
                  <div
                    className={`${
                      group.fieldMeta.filter((m) => 'hidden' in m.options && m.options.hidden === false).length === 1
                        ? 'single-child'
                        : ''
                    }`}
                  >
                    {group.fieldMeta.map((f, i) => {
                      const field = fields[f.fieldId];
                      let value = null;
                      let error = null;
                      let readonly = groupResult.readonly;
                      let fieldResultValue = null;
                      let state = '';
                      if (groupResult.values) {
                        fieldResultValue = groupResult.values.find((v) => v.fieldId === f.fieldId);
                        if (fieldResultValue) {
                          value = fieldResultValue.value;
                          error = fieldResultValue.error;
                          readonly = fieldResultValue.readonly;
                          state = fieldResultValue.state;
                        }
                      }

                      const hidden =
                        (fieldResultValue && fieldResultValue.hidden) ||
                        ((!fieldResultValue || !('hidden' in fieldResultValue)) && f.options && f.options.hidden);

                      if (!adminEditMode && hidden) {
                        return null;
                      }

                      if (!allConditionsMet(field, fields, group._id, groupResult)) {
                        return null;
                      }

                      if (
                        !('readonly' in groupResult) &&
                        ((fieldResultValue && !('readonly' in fieldResultValue)) || !fieldResultValue) &&
                        groupMeta.options
                      ) {
                        readonly = groupMeta.options.readonly;
                      }

                      return (
                        <Field
                          value={value}
                          error={error}
                          state={state}
                          groupId={groupResult.groupId}
                          groupPosition={groupResult.position}
                          hidden={hidden}
                          options={{
                            ...f.options,
                            disabled: group.predefined || readonly ? true : f.options.disabled,
                          }}
                          submissionId={this.props.submissionId}
                          key={f.fieldId + groupResult.groupId}
                          {...fields[f.fieldId]}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {groupMeta.options && groupMeta.options.max && groupResults.length < groupMeta.options.max && (
          <div className="button-container add-new">
            <button className="button add-new" onClick={this.addNewGroupResult}>
              <img className="new-group-icon" src={add} />
              <span>{I18n.t('drf/add-new')}</span>
            </button>
          </div>
        )}
      </div>
    );
  }
}

Group.propTypes = {};

function mapStateToProps(state, ownProps) {
  const submission = state.submissions[ownProps.submissionId];
  const results = submission ? submission.data : [];
  const groups = state.modules.groups;
  const group = ownProps.group;
  const fields = state.modules.fields || {};
  const [andConditionsFailed, orConditionsFailed] = getConditionsForGroup(group, results, fields);

  return {
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    submission,
    groups,
    group,
    fields,
    dependingConditionsFailed: andConditionsFailed || orConditionsFailed,
    results,
    processViewShowHiddenFields: state.currentStage.processViewShowHiddenFields,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addNewGroupResult: addNewGroupResultAction,
      deleteGroupResult: deleteGroupResultAction,
      setStageResults: setStageResultsAction,
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Group);

function isDeleteGroupVisible(group, groupMeta, groupResults) {
  const currentGroupResult = groupResults.find((gr) => gr.groupId === group._id);
  if (currentGroupResult.referenceResultId) {
    return true;
  }

  return groupMeta.options && groupMeta.options.min !== 'undefined' && groupResults.length > groupMeta.options.min;
}

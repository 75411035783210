import { types } from '../actions/layout';

export default function (
  state = {
    isMenuDropdownShown: false,
    isFilterBarOpen: false,
  },
  action
) {
  switch (action.type) {
    case types.TOGGLE_MENU_DROPDOWN:
      return {
        ...state,
        isMenuDropdownShown: action.data,
      };

    case types.TOGGLE_FILTERBAR:
      return {
        ...state,
        isFilterBarOpen: action.data,
      };

    case types.SET_VIEW_MODE:
      return {
        ...state,
        viewMode: {
          ...state.viewMode,
          [action.data.key]: action.data.mode,
        },
      };

    default:
      return state;
  }
}

import React from 'react';
import { connect } from 'react-redux';

import { isUserAllowed } from '../../helper/navigation';

const AuthComponentWrapper = (props) => {
  const { userData, children, allowedRules, fallbackComponent } = props;
  return isUserAllowed(userData, allowedRules) ? <>{children}</> : (fallbackComponent || null);
}

function mapStateToProps(state) {
  return {
    userData: state.auth.userData
  };
}

export default connect(mapStateToProps, null)(AuthComponentWrapper);
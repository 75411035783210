import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';

import auth from './reducers/auth';
import entity from './reducers/entity';
import layout from './reducers/layout';
import modules from './reducers/modules';
import submissions from './reducers/submissions';
import error from './reducers/error';
import currentStage from './reducers/current-stage';
import submissionsPlus from './reducers/submissions-slice';
import groups from './reducers/groups-slice';
import fieldValues from './reducers/field-values-slice';
import transactionHistory from './features/transactionHistory/transaction-history-slice';

const rootReducer = combineReducers({
  i18n: i18nReducer,
  error,
  auth,
  entity,
  layout,
  modules,
  submissions,
  currentStage,
  submissionsPlus,
  groups,
  fieldValues,
  transactionHistory,
});

export default rootReducer;

import React from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import Comment from '../Comment';
import StatusIndicator from '../StatusIndicator';
import Svg from '../Svg';
import { ResponsiveTableWithGroupingDesktop, ResponsiveTableWithGroupingMobile } from '../ResponsiveTable';
import Tile from '../Tile';

import { translate, useIsBigScreen } from '../../helper/functions';
import {
  getGroupedDocuments,
  addLabelsToGroupedDocuments,
  filterHiddenDocumentsIfNeeded,
} from '../DocumentLibrary/document-library-logic';

import document from '../../assets/document_icon.svg';
import upload from '../../assets/upload_icon.svg';
import download from '../../assets/download_icon.svg';
import loader from '../../assets/loader_circle_icon.svg';
import delete_icon from '../../assets/delete_icon.svg';
import info_icon from '../../assets/info_icon_blue.svg';
import error from '../../assets/error.svg';

import './ClientDocumentTable.scss';
import '../ResponsiveTileView/ResponsiveTileView.scss';

class ClientDocumentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openTableRows: [],
      dropdownOpenByDocId: '',
      documentsSortedBy: 'label',
      isAscendingSort: true,
    };
    this.headerColumns = [
      { name: I18n.t('document-library/table-header-1'), class: 'document-name', sortName: 'label' },
      { name: I18n.t('document-library/table-header-2'), class: 'document-icon', sortName: 'generatedFilePath' },
      { name: I18n.t('document-library/table-header-3'), class: 'document-icon', sortName: 'filePath' },
      { name: I18n.t('document-library/table-header-6'), class: 'document-icon', sortName: 'stateAndFilePath' },
      { name: I18n.t('document-library/table-header-7'), class: 'comment' },
      { name: I18n.t('document-library/table-header-5'), class: 'info' },
    ];
    this.openTableRow = this.openTableRow.bind(this);
  }

  openTableRow(rowToOpen) {
    let openRows = [...this.state.openTableRows];
    if (openRows.includes(rowToOpen)) {
      openRows.splice(openRows.indexOf(rowToOpen), 1);
    } else {
      openRows.push(rowToOpen);
    }
    this.setState({
      openTableRows: openRows,
    });
  }

  render() {
    const {
      documents,
      uploadDocument,
      downloadDocument,
      ableToUploadAndDelete,
      openDeletePopup,
      submission,
    } = this.props;

    if (!documents || !this.props.groups) {
      return null;
    }

    const grouppedDocuments = addLabelsToGroupedDocuments(
      getGroupedDocuments(filterHiddenDocumentsIfNeeded(documents, !this.props.processViewShowHiddenFields)),
      this.props.submission.data,
      this.props.groups
    );
    const transformedDocuments = Object.values(grouppedDocuments).sort((a, b) => a.order - b.order);

    return (
      <ResponsiveTableWithGrouping
        ableToUploadAndDelete={ableToUploadAndDelete}
        openTableRows={this.state.openTableRows}
        headerColumns={this.headerColumns}
        data={transformedDocuments}
        submission={submission}
        uploadDocument={uploadDocument}
        downloadDocument={downloadDocument}
        openDeletePopup={openDeletePopup}
        openTableRow={this.openTableRow}
      ></ResponsiveTableWithGrouping>
    );
  }
}

function mapStateToProps(state) {
  return {
    groups: state.modules.groups,
    processViewShowHiddenFields: state.currentStage.processViewShowHiddenFields,
  };
}

ClientDocumentTable.propTypes = {
  documents: PropTypes.array,
  uploadDocument: PropTypes.func,
  downloadDocument: PropTypes.func,
  deleteDocument: PropTypes.func,
};

export default connect(mapStateToProps, null)(ClientDocumentTable);

function ResponsiveTableWithGrouping({
  ableToUploadAndDelete,
  openTableRows,
  headerColumns,
  data,
  submission,
  uploadDocument,
  downloadDocument,
  openDeletePopup,
  openTableRow,
}) {
  const isBigScreen = useIsBigScreen();

  if (isBigScreen) {
    return (
      <ResponsiveTableWithGroupingDesktop
        className="client-document-table"
        headerColumns={headerColumns}
        data={data}
        defaultSort="label"
        isAscending={true}
        renderRow={(d, options) => {
          return (
            <RowDesktop
              key={d._id}
              d={d}
              options={options}
              ableToUploadAndDelete={ableToUploadAndDelete}
              openTableRows={openTableRows}
              submission={submission}
              uploadDocument={uploadDocument}
              downloadDocument={downloadDocument}
              openDeletePopup={openDeletePopup}
              openTableRow={openTableRow}
            />
          );
        }}
      ></ResponsiveTableWithGroupingDesktop>
    );
  } else {
    return (
      <ResponsiveTableWithGroupingMobile
        data={data}
        renderRow={(d, options) => {
          return (
            <Tile
              key={d._id}
              d={d}
              headerColumns={headerColumns}
              renderTitle={() => <RenderTitle d={d} />}
              renderTile={renderTile}
              ableToUploadAndDelete={ableToUploadAndDelete}
              submission={submission}
              options={options}
              uploadDocument={uploadDocument}
              downloadDocument={downloadDocument}
              openDeletePopup={openDeletePopup}
              openTableRow={openTableRow}
            />
          );
        }}
      />
    );
  }
}

function RowDesktop({
  d,
  options,
  ableToUploadAndDelete,
  openTableRows,
  submission,
  uploadDocument,
  downloadDocument,
  openDeletePopup,
  openTableRow,
}) {
  const processViewShowHiddenFields = useSelector((state) => state.currentStage.processViewShowHiddenFields);
  let title;
  if (processViewShowHiddenFields && d.hidden) {
    title = `[${translate(d.label)}]`;
  } else {
    title = translate(d.label);
  }

  return (
    <div className="table-row">
      <div className={`column document-name sticky ${d.state}`}>
        <div dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <div className={`column document-icon`}>
        {d.dataFlow !== 'upload' &&
          (d.generatedFilePath ? (
            <Svg onClick={() => downloadDocument(d)} className={'active'} src={download} hasHover={true} />
          ) : d.dataFlow === 'error' ? (
            <Svg className="error" src={error} hasHover={true} />
          ) : (
            <Svg className={'download rotating'} src={loader} hasHover={true} />
          ))}
      </div>
      <div
        className={`column document-icon upload ${
          (d.readonly && !d.filePath) || d.dataFlow === 'download' || d.dataFlow === 'error' ? 'inactive' : ''
        }`}
      >
        {d.filePath ? (
          <Svg className="doc-icon" src={document} hasHover={true} onClick={() => downloadDocument(d, true)} />
        ) : ableToUploadAndDelete && !d.uploading ? (
          <label
            htmlFor={'file-upload' + options.index + d._id}
            className={`custom-file-upload ${
              submission && submission.isBlocked && d.state === 'pending' ? 'inactive' : ''
            }`}
          >
            <Svg src={upload} hasHover={true} />
            <input
              id={'file-upload' + options.index + d._id}
              disabled={submission && submission.isBlocked && d.state === 'pending'}
              type="file"
              name={d._id}
              onChange={uploadDocument}
            />
          </label>
        ) : d.uploading ? (
          <Svg className={'rotating'} src={loader} hasHover={true} />
        ) : null}
        {d.filePath &&
          ableToUploadAndDelete &&
          d.state !== 'approved' &&
          !d.readonly &&
          (!submission || (submission && !submission.isBlocked) || d.state !== 'pending') && (
            <img onClick={() => openDeletePopup(d)} className="delete-icon" src={delete_icon} />
          )}
      </div>
      <div className="column document-icon status">
        {(d.filePath || (d.generatedFilePath && d.dataFlow === 'download')) && <StatusIndicator state={d.state} />}
      </div>
      <div className="column comment">
        {d.comment && d.comment.comment && (
          <Comment comment={d.comment} targetName={translate(d.label)} alternateColor={true} />
        )}
      </div>
      <div className="column info">
        <Svg
          className={!d.filePath && !d.description ? 'inactive' : ''}
          src={info_icon}
          onClick={() => {
            if (d.filePath || d.description) {
              openTableRow(d._id);
            }
          }}
          hasHover={d.filePath || d.description}
        />
      </div>
      <div className="break"></div>
      {openTableRows.includes(d._id) && (
        <div className="column document-description">
          <div className="inner-container" style={{ width: options.containerWidth }}>
            <div className={`desc ${d.state}`}>
              <div className="desc-content">
                {d.description && <div dangerouslySetInnerHTML={{ __html: translate(d.description) }} />}
              </div>
            </div>
            <div className="uploaded-doc">
              <div className="uploaded-doc-name">
                <span>{I18n.t('document-library/uploaded-label')}:</span>
              </div>
              <div className="uploaded-doc-desc">
                <span>{d.fileName || '-'}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function RenderTitle({ d }) {
  const processViewShowHiddenFields = useSelector((state) => state.currentStage.processViewShowHiddenFields);
  let title;
  if (processViewShowHiddenFields && d.hidden) {
    title = `[${translate(d.label)}]`;
  } else {
    title = translate(d.label);
  }

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: title }} />
    </>
  );
}

function renderTile(
  d,
  tc,
  ableToUploadAndDelete,
  submission,
  submissionId,
  options,
  uploadDocument,
  downloadDocument,
  openDeletePopup,
  openTableRow
) {
  let row;

  switch (tc.name) {
    case 'Download': {
      row = (
        <TileRow name={tc.name}>
          <div className={`column document-icon ${d.readonly ? 'inactive' : ''}`}>
            {d.dataFlow !== 'upload' &&
              (d.generatedFilePath ? (
                <Svg onClick={() => downloadDocument(d)} className={'active'} src={download} hasHover={true} />
              ) : d.dataFlow === 'error' ? (
                <Svg className="error" src={error} hasHover={true} />
              ) : (
                <Svg className={'download rotating'} src={loader} hasHover={true} />
              ))}
          </div>
        </TileRow>
      );
      break;
    }
    case 'Upload': {
      row = (
        <TileRow name={tc.name}>
          <div
            className={`column document-icon upload ${
              d.readonly || d.dataFlow === 'download' || d.dataFlow === 'error' ? 'inactive' : ''
            }`}
          >
            {d.filePath ? (
              <Svg className="doc-icon" src={document} hasHover={true} onClick={() => downloadDocument(d, true)} />
            ) : ableToUploadAndDelete && !d.uploading ? (
              <label
                htmlFor={'file-upload' + options.index + d._id}
                className={`custom-file-upload ${
                  submission && submission.isBlocked && d.state === 'pending' ? 'inactive' : ''
                }`}
              >
                <Svg src={upload} hasHover={true} />
                <input
                  id={'file-upload' + options.index + d._id}
                  disabled={submission && submission.isBlocked && d.state === 'pending'}
                  type="file"
                  name={d._id}
                  onChange={uploadDocument}
                />
              </label>
            ) : d.uploading ? (
              <Svg className={'rotating'} src={loader} hasHover={true} />
            ) : null}
            {d.filePath &&
              ableToUploadAndDelete &&
              d.state !== 'approved' &&
              (!submission || (submission && !submission.isBlocked) || d.state !== 'pending') && (
                <img onClick={() => openDeletePopup(d)} className="delete-icon" src={delete_icon} />
              )}
          </div>
        </TileRow>
      );
      break;
    }
    case 'Status': {
      row = (
        <TileRow name={tc.name}>
          <div className="column document-icon status">
            {(d.filePath || (d.generatedFilePath && d.dataFlow === 'download')) && <StatusIndicator state={d.state} />}
          </div>
        </TileRow>
      );
      break;
    }
    case 'Comment': {
      row = (
        <TileRow name={tc.name}>
          <div className="column comment">
            {d.comment && d.comment.comment && (
              <Comment comment={d.comment} targetName={translate(d.label)} alternateColor={true} />
            )}
          </div>
        </TileRow>
      );
      break;
    }
    case 'Info': {
      row = (
        <TileRow name={tc.name}>
          <div className="column info">
            <Svg
              className={!d.filePath && !d.description ? 'inactive' : ''}
              src={info_icon}
              onClick={() => {
                if (d.filePath || d.description) {
                  openTableRow(d._id);
                }
              }}
              hasHover={d.filePath || d.description}
            />
          </div>
        </TileRow>
      );
      break;
    }
  }

  return <div key={tc.name}>{row}</div>;
}

function TileRow({ name, children }) {
  return (
    <div className="tile-content-row">
      <span className="content-key">{name}</span>
      <span className="content-value">{children}</span>
    </div>
  );
}

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import PaymentPopup from './components/PaymentPopup';

import { useGetPaymentInfo, useCreatePayment } from './payment-stage';
import { pageStates } from '../../../helper/payment';

import './PaymentStage.scss';

export function PaymentStage({ children, submissionId, stageId }) {
  const token = useSelector((state) => state.auth.token);
  const config = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  const [pageState, setPageState] = useGetPaymentInfo(submissionId, stageId, config);
  const onPaymentClick = useCreatePayment(submissionId, stageId, config, pageState, setPageState);

  return (
    <div>
      <PaymentPopup
        isOpen={pageState.popupOpen}
        state={pageState.state}
        closeHandler={() => setPageState({ ...pageState, popupOpen: false })}
      />
      {children}
      {renderPage()}
    </div>
  );

  function renderPage() {
    switch (pageState.state) {
      case pageStates.waitingForPaymentInfo:
        return <div role="loader">Loading...</div>;
      case pageStates.errorPaymentInfo:
        return (
          <div className="payment-result" role="payment-stage-error">
            {pageState.error.message}
          </div>
        );
      case pageStates.payed:
        return (
          <>
            <PaymentInfo paymentInfo={pageState.data.info} />
            <div className="payment-result">{I18n.t('payment/successful')}</div>
          </>
        );
      case pageStates.notPayed:
        return (
          <>
            <PaymentInfo paymentInfo={pageState.data.info} />
            <button className="pay-button" onClick={onPaymentClick}>
              {I18n.t('payment/pay-with-simple')}
            </button>
          </>
        );
      case pageStates.errorPayment:
        return (
          <>
            <PaymentInfo paymentInfo={pageState.data.info} />
            <button className="pay-button" onClick={onPaymentClick}>
              {I18n.t('payment/retry-with-simple')}
            </button>
            <div className="payment-result">{pageState.errorMessage}</div>
          </>
        );
      case pageStates.processingPayment:
        return <ProcessingPayment pageState={pageState} setPageState={setPageState} />;
    }
  }
}

PaymentStage.propTypes = {
  children: PropTypes.node.isRequired,
  submissionId: PropTypes.string.isRequired,
  stageId: PropTypes.string.isRequired,
};

export default PaymentStage;

function ProcessingPayment({ pageState, setPageState }) {
  useEffect(() => {
    const id = setTimeout(() => {
      setPageState({ ...pageState, checkCount: pageState.checkCount + 1 });
    }, 10000);
    return () => {
      clearTimeout(id);
    };
  });

  return (
    <>
      <PaymentInfo paymentInfo={pageState.data.info} />
      <div>{I18n.t('payment/please-wait')}</div>
    </>
  );
}

const infoType = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string,
    price: PropTypes.number,
    currency: PropTypes.currency,
  })
);

ProcessingPayment.propTypes = {
  pageState: PropTypes.shape({
    checkCount: PropTypes.number,
    data: PropTypes.shape({
      info: infoType.isRequired,
    }).isRequired,
  }).isRequired,
  setPageState: PropTypes.func.isRequired,
};

function PaymentInfo({ paymentInfo }) {
  const priceSum = useMemo(() => paymentInfo.reduce((acc, info) => acc + info.price, 0), [paymentInfo]);
  const currency = useMemo(() => {
    if (paymentInfo.length > 0) {
      return paymentInfo[0].currency;
    } else {
      return '';
    }
  }, [paymentInfo]);

  return (
    <div className="payment-summary">
      <table>
        <tbody>
          <tr>
            <td colSpan="2" className="title">
              {I18n.t('payment/payment-sum-title')}
            </td>
          </tr>
          <tr className="header-row">
            <td className="left-column">{I18n.t('payment/item-text')}</td>
            <td className="right-column">{I18n.t('payment/price-text')}</td>
          </tr>
          {paymentInfo.map((info, index) => (
            <tr key={index} className="item-row">
              <td className="left-column item">{info.title}</td>
              <td className="right-column">
                {info.price} {info.currency}
              </td>
            </tr>
          ))}
          <tr className="summary">
            <td className="left-column">{I18n.t('payment/price-sum-text')}</td>
            <td className="right-column">
              {priceSum} {currency}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

PaymentInfo.propTypes = {
  paymentInfo: infoType.isRequired,
};

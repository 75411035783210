import { setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import moment from 'moment';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import rootRedcuer from './rootReducer';
import { getBrowserLang } from './helper/functions';

const persistedLocalStorageObject = localStorage.getItem('localStorageObject')
  ? JSON.parse(localStorage.getItem('localStorageObject'))
  : {};

const localStorageMiddleware = (storeAPI) => (next) => (action) => {
  let result = next(action);

  if (action.payload && action.payload.meta && action.payload.meta.saveToLocalStorage) {
    window.localStorage.setItem('submission', JSON.stringify(storeAPI.getState().submissionsPlus));
    window.localStorage.setItem('groupValues', JSON.stringify(storeAPI.getState().groups));
    window.localStorage.setItem('fieldValues', JSON.stringify(storeAPI.getState().fieldValues));
  }

  return result;
};

const store = configureStore({
  reducer: rootRedcuer,
  middleware: getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(localStorageMiddleware),
  preloadedState: {
    ...addViewMode(persistedLocalStorageObject),
  },
});

syncTranslationWithStore(store);

store.subscribe(() => {
  const lSobj = {
    auth: store.getState().auth,
    layout: store.getState().layout,
  };
  localStorage.setItem('localStorageObject', JSON.stringify(lSobj));
});

let currentLocale;
if (localStorage.getItem('currentLocale')) {
  currentLocale = localStorage.getItem('currentLocale');
}
moment.locale(currentLocale || getBrowserLang());
store.dispatch(setLocale(currentLocale || getBrowserLang()));

export default store;

function addViewMode(localStorageObject) {
  if (localStorageObject && (!localStorageObject.layout || !localStorageObject.layout.viewMode)) {
    return { ...localStorageObject, ...{ layout: { viewMode: {} } } };
  }

  return localStorageObject;
}

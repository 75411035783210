import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';

import Svg from '../Svg';
import { getSortFunctionWithDirection } from '../../helper/functions';

import arrow from '../../assets/accordion_arrow.svg';

const ResponsiveTableWithGroupingMobile = ({ data, renderRow }) => {
  const sortedData = [...data].map((d, index) => ({
    index,
    value: d.documents.sort(getSortFunctionWithDirection('label', true)),
    title: d.title,
    referenceTitle: d.referenceTitle,
  }));

  return (
    <SimpleBar style={{ maxHeight: '100%' }}>
      <div className="tile-container">
        {sortedData.map((d) => (
          <ResponsiveTableGroup
            key={d.index}
            rows={d.value}
            title={d.title}
            referenceTitle={d.referenceTitle}
            renderRow={renderRow}
          />
        ))}
      </div>
    </SimpleBar>
  );
};

export default ResponsiveTableWithGroupingMobile;

function ResponsiveTableGroup({ rows, title, referenceTitle, renderRow }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="table-group">
      <div className="table-group-title">
        <div className="column group-title" onClick={() => setIsOpen(!isOpen)}>
          <span style={{ position: 'sticky', left: '20px' }}>
            {title}
            <span className="group-header-reference-name">{referenceTitle}</span>
          </span>
          <div className="group-actions">
            <div className={`arrow-container ${isOpen ? 'opened' : 'closed'}`}>
              <Svg src={arrow} hasHover={true} />
            </div>
          </div>
        </div>
      </div>
      <div className={`table-content hide-scrollbar table-group-content ${isOpen ? 'opened' : 'closed'}`}>
        {rows.map((d, index) => {
          return renderRow(d, { index });
        })}
      </div>
    </div>
  );
}

import { useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

export function useHandleSave(id, values, stopEdit) {
  const token = useSelector((state) => state.auth.token);

  return useCallback(() => {
    stopEdit();

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios.put('/admin/drf/predefined-groups/' + id, { values }, { headers });
  }, [id, values, stopEdit]);
}

export function mapRows(data) {
  return Object.values(data).map((d) => ({ ...d, id: d._id }));
}

export function mapDataForUpdate(values) {
  return values.map((v) => ({
    fieldId: v.id,
    value: v.value,
  }));
}

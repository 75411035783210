import React from 'react';
import ReactSVG from 'react-svg';

import './Svg.scss';

class Svg extends React.Component {
  render() {
    const { src, className, otherColor, hasHover = false, flipColors = false, ...props } = this.props;
    let hoverColor = clientConfig.colors.svgHoverColor;
    let fillColor = clientConfig.colors.primary;
    if (flipColors) {
      [fillColor, hoverColor] = [hoverColor, fillColor];
    }
    return (
      <ReactSVG
        className="svg-wrapper"
        src={this.props.src}
        afterInjection={(error, svg) => {
          if (error) {
            console.error(error);
            return;
          }
        }}
        loading={() => <img style={{ visibility: 'hidden' }} src={this.props.src}></img>}
        beforeInjection={(svg) => {
          svg.setAttribute('style', `fill: ${otherColor ? otherColor : fillColor}`);
          svg.onmouseover = function () {
            if (hasHover) {
              this.style.fill = hoverColor;
            }
          };
          svg.onmouseleave = function () {
            if (hasHover) {
              this.style.fill = otherColor ? otherColor : fillColor;
            }
          };
          if (this.props.className) {
            svg.setAttribute('class', this.props.className);
          }
          let nodeToDelete = svg.querySelector('defs');
          if (nodeToDelete) {
            svg.removeChild(nodeToDelete);
          }
        }}
        {...props}
      />
    );
  }
}

export default Svg;

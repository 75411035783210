import React from 'react';

import { translate } from '../../helper/functions';

import './Separator.scss';

function Separator(props) {
  if (props.readonly && props.multiline) {
    return null;
  }

  return (
    <div className={'row ' + (!props.multiline ? 'separator' : 'separator multiline')}>
      <div className="left-container">
        <div dangerouslySetInnerHTML={{ __html: translate(props.label) }} />
      </div>
      <div className="right-container">
        <span></span>
      </div>
    </div>
  );
}

export default Separator;

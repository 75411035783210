import axios from 'axios';
import * as entityActions from '../actions/entity';
import * as errorActions from '../actions/error';

export function fetchEntities({ forceRefresh = true } = {}) {
  return (dispatch, getState) => {
    if (getState().entity.isLoaded && !forceRefresh) {
      return;
    }
    dispatch(entityActions.requestEntities());
    axios
      .get('/entities', {
        headers: {
          Authorization: 'Bearer ' + getState().auth.token,
        },
      })
      .then((response) => {
        dispatch(entityActions.setEntities(response.data));
      })
      .catch((error) => {
        dispatch(errorActions.throwServerError(error));
      });
  };
}

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import axios from 'axios';

import {
  transformValue,
  transformValuFromObject,
  fieldValidatorForConnectedFields,
  valuesHasError,
} from '../../../helper/functions';
import { useGetPredefinedGroups } from '../predefined-group-list-logic';
import * as errorActions from '../../../actions/error';
import * as moduleActions from '../../../actions/modules';

export function useGetPredefinedGroupDetails(groupId) {
  const token = useSelector((state) => state.auth.token);
  const [data, setData] = useState({ status: 'loading' });

  useEffect(() => {
    axios
      .get(`/admin/drf/predefined-groups?groupId=${groupId}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        setData({ status: 'success', data: response.data });
      })
      .catch((error) => {
        setData({ status: 'error', error });
      });
  }, [groupId]);

  return data;
}

export function useGetData() {
  const { groupId } = useParams();
  const groupDetails = useGetPredefinedGroupDetails(groupId);
  const enabledGroups = useGetPredefinedGroups();
  const [model, setModel] = useState();
  const startEdit = useStartEdit(model, setModel);
  const onChange = useOnChange(model, setModel);
  const startAdd = useStartAdd(model, setModel, groupId);
  const cancel = useCancel(model, setModel);
  const save = useSave(model, setModel);
  const activate = useActivate(model, setModel);
  const deactivate = useDeactivate(model, setModel);
  const fields = useSelector((state) => state.modules.fields);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (groupDetails.status === 'success' && enabledGroups.status === 'success') {
      let metadata = {};
      const currentGroup = enabledGroups.data.find((d) => d._id === groupId);
      currentGroup.fields
        .map((f) => {
          const foundFieldMeta = currentGroup.fieldMeta.find((fM) => fM.fieldId === f._id);

          if (f.referenceCollectionName) {
            getModuleDataForReferenceCollectionName(f.referenceCollectionName, token, dispatch);
          }

          return {
            ...f,
            position: foundFieldMeta.position,
          };
        })
        .sort(sortByPosition)
        .forEach((f) => {
          const foundFieldMeta = currentGroup.fieldMeta.find((fM) => fM.fieldId === f._id);
          metadata[f._id] = f;
          metadata[f._id].value = '';
          metadata[f._id].error = fieldValidatorForConnectedFields(
            '',
            metadata[f._id].validationRules,
            metadata[f._id].type
          );
          metadata[f._id].valueChanged = false;
          metadata[f._id].position = foundFieldMeta.position;
          metadata[f._id].options = foundFieldMeta.options;
        });

      const groupLabel = enabledGroups.data.find((d) => d._id === groupId).label;
      const groups = mapToPredefinedGroups(groupDetails.data, fields, metadata);

      setModel({
        mode: 'readonly',
        referenceNameTemplate: currentGroup.referenceNameTemplate,
        groupLabel,
        metadata,
        groups,
      });
    }
  }, [groupDetails.status, enabledGroups.status]);

  if (groupDetails.status === 'error' || enabledGroups.status === 'error') {
    return { status: 'error' };
  }
  if (groupDetails.status === 'loading' || enabledGroups.status === 'loading') {
    return { status: 'loading' };
  }

  if (!model) {
    return { status: 'loading' };
  }

  return {
    model,
    status: 'success',
    startEdit,
    cancel,
    save,
    startAdd,
    activate,
    deactivate,
    onChange,
  };
}

function useStartEdit(model, setModel) {
  return useCallback(
    (id) => {
      // if any item is in edit mode, don't do anything
      if (model.mode !== 'readonly') {
        return;
      }

      // set edit mode for the specified group
      setModel({
        ...model,
        mode: 'edit',
        groups: {
          ...model.groups,
          [id]: {
            ...model.groups[id],
            mode: 'edit',
            originalValues: model.groups[id].values,
          },
        },
      });
    },
    [model, setModel]
  );
}

function useCancel(model, setModel) {
  return useCallback(
    (id) => {
      if (model.mode === 'add') {
        // cancel add
        setModel({
          ...model,
          mode: 'readonly',
        });
      } else {
        // cancel edit
        setModel({
          ...model,
          mode: 'readonly',
          groups: {
            ...model.groups,
            [id]: {
              ...model.groups[id],
              mode: 'readonly',
              values: model.groups[id].originalValues,
            },
          },
        });
      }
    },
    [model, setModel]
  );
}

function useOnChange(model, setModel) {
  return useCallback(
    (id, fieldId, value) => {
      if (model.mode === 'add') {
        const label = transformValuFromObject(model.referenceNameTemplate, model.groupToAdd.values);
        setModel({
          ...model,
          groupToAdd: {
            ...model.groupToAdd,
            values: {
              ...model.groupToAdd.values,
              [fieldId]: {
                ...model.groupToAdd.values[fieldId],
                value,
                error: fieldValidatorForConnectedFields(
                  value,
                  model.groupToAdd.values[fieldId].validationRules,
                  model.groupToAdd.values[fieldId].type
                ),
                valueChanged: true,
              },
            },
            label: { en_US: label },
          },
        });
      } else {
        const label = transformValuFromObject(model.referenceNameTemplate, model.groups[id].values);
        setModel({
          ...model,
          groups: {
            ...model.groups,
            [id]: {
              ...model.groups[id],
              values: {
                ...model.groups[id].values,
                [fieldId]: {
                  ...model.groups[id].values[fieldId],
                  value,
                  error: fieldValidatorForConnectedFields(
                    value,
                    model.groups[id].values[fieldId].validationRules,
                    model.groups[id].values[fieldId].type
                  ),
                  valueChanged: true,
                },
              },
              label: { en_US: label },
            },
          },
        });
      }
    },
    [model, setModel]
  );
}

function useStartAdd(model, setModel, groupId) {
  return useCallback(() => {
    if (model.mode !== 'readonly') {
      return;
    }

    setModel({
      ...model,
      mode: 'add',
      groupToAdd: {
        mode: 'edit',
        groupId,
        visible: true,
        values: {
          ...model.metadata,
        },
      },
    });
  }, [model, setModel, groupId]);
}

function useSave(model, setModel) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const fields = useSelector((state) => state.modules.fields);

  return useCallback(
    (id) => {
      if (model.mode === 'add') {
        if (valuesHasError(Object.values(model.groupToAdd.values))) {
          return;
        }

        // add new group
        axios
          .post('/admin/drf/predefined-groups/', mapDataToAdd(model.groupToAdd), {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
          .then((response) => {
            setModel({
              ...model,
              mode: 'readonly',
              groupToAdd: undefined,
              groups: {
                ...model.groups,
                [response.data._id]: {
                  ...model.groupToAdd,
                  _id: response.data._id,
                  mode: 'readonly',
                },
              },
            });
          })
          .catch((error) => {
            dispatch(errorActions.throwServerError(error));
          });
      } else {
        if (valuesHasError(Object.values(model.groups[id].values))) {
          return;
        }

        // save existing group
        const values = Object.values(model.groups[id].values).map((v) => ({ fieldId: v._id, value: v.value }));
        const label = {
          ...model.groups[id].label,
          en_US: transformValue(model.referenceNameTemplate, values, fields),
        };

        axios
          .put(
            '/admin/drf/predefined-groups/' + id,
            { label, values },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            setModel({
              ...model,
              mode: 'readonly',
              groups: {
                ...model.groups,
                [id]: {
                  ...model.groups[id],
                  mode: 'readonly',
                },
              },
            });
          })
          .catch((error) => {
            dispatch(errorActions.throwServerError(error));
          });
      }
    },
    [token, model, setModel]
  );
}

function useActivate(model, setModel) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  return useCallback(
    (id) => {
      axios
        .put(
          '/admin/drf/predefined-groups/' + id,
          { visible: true },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setModel({
            ...model,
            groups: {
              ...model.groups,
              [id]: {
                ...model.groups[id],
                visible: true,
              },
            },
          });
        })
        .catch((error) => {
          dispatch(errorActions.throwServerError(error));
        });
    },
    [token, model, setModel]
  );
}

function useDeactivate(model, setModel) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  return useCallback(
    (id) => {
      axios
        .put(
          '/admin/drf/predefined-groups/' + id,
          { visible: false },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setModel({
            ...model,
            groups: {
              ...model.groups,
              [id]: {
                ...model.groups[id],
                visible: false,
              },
            },
          });
        })
        .catch((error) => {
          dispatch(errorActions.throwServerError(error));
        });
    },
    [token, model, setModel]
  );
}

function mapDataToAdd(group) {
  return {
    ...group,
    values: Object.values(group.values).map((v) => ({
      fieldId: v._id,
      value: v.value,
    })),
  };
}

function mapToPredefinedGroups(groups, fields, metadata) {
  let newGroups = {};
  for (let i = 0; i < groups.length; i++) {
    let newValues = {};
    for (let j = 0; j < groups[i].values.length; j++) {
      let currentValue = { ...fields[groups[i].values[j].fieldId] };
      currentValue.value = groups[i].values[j].value;
      currentValue.position = metadata[currentValue._id].position;
      currentValue.options = metadata[currentValue._id].options;
      newValues[currentValue._id] = currentValue;
    }
    newGroups[groups[i]._id] = { ...groups[i], mode: 'readonly' };
    newGroups[groups[i]._id].values = newValues;
  }

  return newGroups;
}

export function sortByPosition(a, b) {
  return a.position - b.position;
}

function getModuleDataForReferenceCollectionName(referenceCollectionName, token, dispatch) {
  axios
    .get(`/drf/reference-collection/${referenceCollectionName}?_limit=1000&_page=0&_sort=name.en_US&_order=asc`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    .then((response) => dispatch(moduleActions.setReferenceCollection(referenceCollectionName, response.data)))
    .catch(() => console.log(`Can't get module data for ${referenceCollectionName}`));
}

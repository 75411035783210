import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';

import Header, { headerDataShape } from './components/Header';
import Body, { dataShape } from './components/Body';
import TileGroup from './components/TileGroup';
import { getSortFunctionWithDirection, useIsBigScreen } from '../../helper/functions';

import './DataView.scss';

function GrouppedDataView({ headerData, data, renderRow, renderTile }) {
  const isBigScreen = useIsBigScreen();
  const [propertyAndDirection, setPropertyAndDirection] = useState({
    property: 'transactionTime',
    isAscending: false,
  });

  const sortedData = useMemo(() => {
    return data.map((d) => ({
      ...d,
      items: [...d.items].sort(
        getSortFunctionWithDirection(propertyAndDirection.property, propertyAndDirection.isAscending)
      ),
    }));
  }, [propertyAndDirection.property, propertyAndDirection.isAscending, data]);

  if (isBigScreen) {
    return (
      <div className="document-library-content-container hide-scrollbar">
        <div className="responsive-table-container documents-admin-table-container">
          <SimpleBar style={{ maxHeight: '100%' }}>
            <div className="data-view" style={{ minWidth: '100%' }}>
              <Header
                headerData={headerData}
                propertyAndDirection={propertyAndDirection}
                setPropertyAndDirection={setPropertyAndDirection}
              />
              <Body data={sortedData} renderRow={renderRow} />
            </div>
          </SimpleBar>
        </div>
      </div>
    );
  } else {
    return (
      <SimpleBar style={{ maxHeight: '100%' }}>
        <div className="tile-container">
          {data.map((d) => (
            <TileGroup key={d.stageId} label={d.label} items={d.items} renderTile={renderTile} />
          ))}
        </div>
      </SimpleBar>
    );
  }
}

GrouppedDataView.propTypes = {
  headerData: headerDataShape.isRequired,
  data: dataShape.isRequired,
  renderRow: PropTypes.func.isRequired,
  renderTile: PropTypes.func.isRequired,
};

export default GrouppedDataView;

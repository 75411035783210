import { DEFAULT_LOCALE } from '../constants';
import { I18n } from 'react-redux-i18n';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import ms from 'ms';
import axios from 'axios';
import dot from 'dot-object';

import Esign from '../components/Esign';

import { calculateRoutePath } from './navigation';
import { getBankMask, getPhoneMask } from 'components/Field/TextField/text-field';
import { getPennyMask } from 'components/Field/NumberField/number-field';

export function getBrowserLang() {
  let language;
  let sessionData = JSON.parse(localStorage.getItem('localStorageObject'));
  let langCode;
  if (sessionData && sessionData.auth && sessionData.auth.locale) {
    langCode = sessionData.auth.locale;
  } else {
    langCode = navigator.language || navigator.userLanguage;
  }
  switch (langCode) {
    case 'hu':
    case 'hu-HU':
    case 'hu_HU':
      moment.locale('hu');
      language = 'hu_HU';
      break;
    case 'en':
    case 'en-US':
    case 'en_US':
      moment.locale('en');
      language = 'en_US';
      break;
    default:
      language = 'en_US';
  }

  return language;
}

export function translate(langObject) {
  return langObject[getLocaleConfig()] || langObject[DEFAULT_LOCALE] || '';
}

export function formValidator(name, value, valueToCompare, isProfilePage = false) {
  const errors = {};
  switch (name) {
    case 'email':
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        errors.email = I18n.t('form-error/invalid-email');
      }
      break;
    case 'password':
    case 'currentPassword':
      if (isProfilePage && value.length === 0) break;
      if (value.length < 8) {
        errors[name] = I18n.t('form-error/short-password');
      } else {
        const containsNumeric = /[0-9]/.test(value);
        const containsSpecial = /[^a-zA-Z0-9]/.test(value);
        const containsLowerCase = /[a-z]/.test(value);
        const containsUpperCase = /[A-Z]/.test(value);
        if (containsNumeric + containsSpecial + containsLowerCase + containsUpperCase < 3) {
          errors[name] = I18n.t('form-error/invalid-password');
        }
      }
      break;
    case 'confirmPassword':
      if (valueToCompare !== value) {
        errors[name] = I18n.t('form-error/different-password');
      }
  }

  if (!isProfilePage && !value) {
    errors[name] = I18n.t('form-error/required');
  }

  if (!errors[name]) {
    errors[name] = null;
  }
  return errors;
}

export function fieldValidatorForConnectedFields(value, validationRules, type, connectedFieldResult) {
  if (!validationRules) {
    return;
  }

  return fieldValidator(value, validationRules, type, connectedFieldResult);
}

export function fieldValidatorForSubmission(
  value,
  validationRules,
  type,
  submission,
  groupId,
  groupPosition,
  groupDependingConditionFailed = false,
  textMask = undefined
) {
  if (!validationRules || groupDependingConditionFailed) return;

  let connectedFieldResult;
  if (validationRules.connectedField && submission.data) {
    const groupResult = submission.data.find((gR) => {
      return groupId === gR.groupId && groupPosition === gR.position;
    });
    if (groupResult && groupResult.values) {
      connectedFieldResult = groupResult.values.find((item) => {
        return item.fieldId === validationRules.connectedField.fieldId;
      });
    }
  }

  return fieldValidator(value, validationRules, type, connectedFieldResult, textMask);
}

export function fieldValidator(value, validationRules, type, connectedFieldResult, textMask) {
  let error = null;

  if (
    (validationRules.required && !value && value !== false) ||
    (validationRules.required && type === 'checkbox' && value.length === 0)
  ) {
    return I18n.t('form-error/required');
  }

  switch (type) {
    case 'text':
    case 'textarea':
      if (validationRules.email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
          error = I18n.t('form-error/invalid-email');
        }
      }
      if (validationRules.min && value && value.length <= validationRules.min) {
        error = I18n.t('form-error/too-short-text');
      }
      if (validationRules.max && value && value.length >= validationRules.max) {
        error = I18n.t('form-error/too-long-text');
      }
      if (connectedFieldResult) {
        if (validationRules.connectedField.rules.equals && value !== connectedFieldResult.value) {
          error = I18n.t('form-error/no-match');
        }
      }
      if (textMask === 'bank' && value.length !== 16 && value.length !== 24) {
        error = I18n.t('form-error/bank-length-incorrect');
      }
      break;
    case 'number':
      if (validationRules.min && validationRules.min > value) {
        error = I18n.t('form-error/low-number');
      } else if (validationRules.max && validationRules.max < value) {
        error = I18n.t('form-error/high-number');
      }
      if (connectedFieldResult) {
        if (validationRules.connectedField.rules.percentage) {
          if (
            validationRules.connectedField.rules.percentage.min &&
            (parseFloat(validationRules.connectedField.rules.percentage.min) / 100.0) * connectedFieldResult.value >
              value
          ) {
            error = I18n.t('form-error/low-number');
          }
        }
        if (validationRules.connectedField.rules.max === 'field' && value * 1 > connectedFieldResult.value * 1) {
          error = I18n.t('form-error/high-number');
        }

        if (validationRules.connectedField.rules.equals && value !== connectedFieldResult.value) {
          error = I18n.t('form-error/no-match');
        }
      }
      if (validationRules.integer) {
        if (!isNormalInteger(value)) {
          error = I18n.t('form-error/integer');
        }
      }
      break;
    case 'date':
      const dateValue = typeof value === 'string' ? new Date(value) : value;

      if (validationRules.min && dateValue) {
        if (validationRules.min === 'now' && dateValue.getTime() - Date.now() < 0) {
          error = I18n.t('form-error/invalid-date');
        }
      }
      if (validationRules.max && dateValue) {
        if (validationRules.max === 'now' && dateValue.getTime() - Date.now() > 0) {
          error = I18n.t('form-error/invalid-date');
        }
      }
      if (validationRules.ms && dateValue) {
        const minTime = validationRules.ms.min && new Date(new Date().getTime() + ms(validationRules.ms.min));
        const maxTime = validationRules.ms.max && new Date(new Date().getTime() + ms(validationRules.ms.max));
        if (minTime && minTime.getTime() > dateValue.getTime()) {
          error = I18n.t('form-error/invalid-date');
        } else if (maxTime && maxTime.getTime() < dateValue.getTime()) {
          error = I18n.t('form-error/invalid-date');
        }
      }
      if (connectedFieldResult) {
        if (
          validationRules.connectedField.rules.equals &&
          dateValue.getTime() !== new Date(connectedFieldResult.value).getTime()
        ) {
          error = I18n.t('form-error/no-match');
        }
      }
      break;
    default:
  }

  return error;
}

function isNormalInteger(str) {
  const n = Math.floor(Number(str));
  return n !== Infinity && String(n) === str && n >= 0;
}

const sortFunctions = {
  entityName: (a, b) => {
    return Intl.Collator(getLanguage()).compare(
      a.entityData.data.name.toLowerCase(),
      b.entityData.data.name.toLowerCase()
    );
  },
  email: (a, b) => {
    return Intl.Collator(getLanguage()).compare(a.email, b.email);
  },
  clientName: (a, b) => {
    return Intl.Collator(getLanguage()).compare(a.profile.name.toLowerCase(), b.profile.name.toLowerCase());
  },
  status: (a, b) => {
    return a.entityData.state > b.entityData.state ? 1 : b.entityData.state > a.entityData.state ? -1 : 0;
  },
  label: (a, b) => {
    return Intl.Collator(getLanguage()).compare(translate(a.label), translate(b.label));
  },
  filePath: (a, b) => {
    return !!a.filePath - !!b.filePath;
  },
  generatedFilePath: (a, b) => {
    return !!a.generatedFilePath - !!b.generatedFilePath;
  },
  dataFlow: (a, b) => {
    const sortingArray = ['upload', 'both', 'download'];
    let sort = sortingArray.indexOf(a.dataFlow) - sortingArray.indexOf(b.dataFlow);
    if (!sort) {
      sort = !!b.filePath - !!a.filePath;
    }
    return sort;
  },
  created: (a, b) => {
    return new Date(a.created) - new Date(b.created);
  },
  updated: (a, b) => {
    return new Date(a.updated) - new Date(b.updated);
  },
  state: (a, b) => {
    return a.state > b.state ? 1 : b.state > a.state ? -1 : 0;
  },
  stateAndFilePath: (a, b) => {
    return a.state > b.state ? 1 : b.state > a.state ? -1 : !!b.filePath - !!a.filePath;
  },
  moduleId: (a, b) => {
    return a.moduleId > b.moduleId ? 1 : b.moduleId > a.moduleId ? -1 : 0;
  },
  activeSubmission: (a, b) => {
    return a.activeSubmission - b.activeSubmission;
  },
  allSubmission: (a, b) => {
    return a.allSubmission - b.allSubmission;
  },
  name: (a, b) => {
    return Intl.Collator(getLanguage()).compare(a.data.name.toLowerCase(), b.data.name.toLowerCase());
  },
  userName: (a, b) => {
    return Intl.Collator(getLanguage()).compare(a.userName.toLowerCase(), b.userName.toLowerCase());
  },
  submissionName: (a, b) => {
    return Intl.Collator(getLanguage()).compare(a.name.toLowerCase(), b.name.toLowerCase());
  },
  completed: (a, b) => {
    return !!a.completed - !!b.completed;
  },
  lastLogin: (a, b) => {
    let minDate = new Date(-8640000000000000);
    let aDate = a.lastLogin ? new Date(a.lastLogin) : minDate;
    let bDate = b.lastLogin ? new Date(b.lastLogin) : minDate;
    return aDate - bDate;
  },
  lastSubmissionUpdate: (a, b) => {
    return new Date(a.lastSubmissionUpdate) - new Date(b.lastSubmissionUpdate);
  },
  creatorModule: (a, b) => {
    return Intl.Collator(getLanguage()).compare(translate(a.creatorModule.label), translate(b.creatorModule.label));
  },
  users: (a, b) => {
    let usersA = a.users.map((u) => u.profile.name).join('');
    let usersB = b.users.map((u) => u.profile.name).join('');
    return Intl.Collator(getLanguage()).compare(usersA, usersB);
  },
  adminUsers: (a, b) => {
    let usersA = a.adminUsers.map((u) => u.profile.name).join('');
    let usersB = b.adminUsers.map((u) => u.profile.name).join('');
    return Intl.Collator(getLanguage()).compare(usersA, usersB);
  },
  role: (a, b) => {
    return a.profile.role > b.profile.role ? 1 : b.profile.role > a.profile.role ? -1 : 0;
  },
  claim: (a, b) => {
    let profileA = a.profile.claim ? a.profile.claim.trim().toLowerCase() : '';
    let profileB = b.profile.claim ? b.profile.claim.trim().toLowerCase() : '';
    return Intl.Collator(getLanguage()).compare(profileA, profileB);
  },
  allocatedEntityCount: (a, b) => {
    return a.allocatedEntityCount - b.allocatedEntityCount;
  },
  allocate: (a, b) => {
    return a.allocate - b.allocate;
  },
  delegate: (a, b) => {
    return a.delegate - b.delegate;
  },
  numberOfElements: (a, b) => {
    return a.values.length - b.values.length;
  },
  transactionTime: (a, b) => {
    if (moment(a.transactionTime).isAfter(moment(b.transactionTime))) {
      return -1;
    }

    return 1;
  },
  transactionStatus: (a, b) => {
    return a.state > b.state ? 1 : b.state > a.state ? -1 : 0;
  },
};

export function getSortFunctionWithDirection(property, direction) {
  return function (a, b) {
    return sortFunctions[property](a, b) * (direction ? 1 : -1);
  };
}

export const getNiceValueFormat = (field, value) => {
  if (!value) {
    return '-';
  }
  let niceValue = null;
  switch (field.type) {
    case 'checkbox':
      niceValue =
        value.length > 0
          ? value
              .map((v, i) => {
                return translate(v.label);
              })
              .join(', ')
          : '-';
      break;
    case 'date':
      const date = new Date(value);
      niceValue = date.getDate() + '/' + (+date.getMonth() + 1) + '/' + date.getFullYear();
      break;
    case 'select':
      niceValue = value.length
        ? value
            .map((v, i) => {
              return v.label;
            })
            .join(', ')
        : value.label;
      break;
    case 'radio':
      niceValue = translate(value.label);
      break;
    case 'esignature':
      niceValue = <Esign value={value}></Esign>;
      break;
    default:
      switch (field.textMask) {
        case 'penny':
          niceValue = getPennyMask(value);
          break;
        case 'bank':
          niceValue = getBankMask(value);
          break;
        case 'phone':
          niceValue = getPhoneMask(value);
          break;
        default:
          niceValue = value;
      }
  }
  return niceValue;
};

export const downloadDocumentWithBlob = (doc, submissionId, token, withFilePath) => {
  axios
    .get(
      withFilePath
        ? `/drf/submissions/${submissionId}/documents/${doc._id}/download-uploaded-document`
        : `/drf/submissions/${submissionId}/documents/${doc._id}/download`,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      let name = translate(doc.label)
        .replace(/<[^>]*>/g, '_')
        .replace(/ /g, '_')
        .replace(/__/g, '_')
        .toLowerCase();
      const filename = withFilePath ? doc.fileName : name + '.' + doc.extension;
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

export const setSelectorDirectionClass = () => {
  setTimeout(() => {
    const optionsList = document.querySelector('.subject-select__menu');
    if (optionsList) {
      const selector = document.querySelector('.subject-select__control--menu-is-open');
      const top = window.getComputedStyle(optionsList, null).getPropertyValue('top');
      const extraClass = parseInt(top) > 0 ? 'bottom-menu' : 'top-menu';
      optionsList.classList.add(extraClass);
      selector.classList.add(extraClass);
    }
  }, 50);
};

export const beautifyLongTextArray = (textArray, elementsShown, conjuctive, pronoun) => {
  if (textArray.length === 0) {
    return '-';
  } else if (textArray.length <= elementsShown) {
    return textArray.filter((t, i) => i < elementsShown).join(', ');
  } else {
    return [
      textArray.filter((t, i) => i < elementsShown).join(', '),
      conjuctive,
      textArray.length - elementsShown,
      pronoun,
    ].join(' ');
  }
};

export const getDateTimeFormat = (countryCode) => {
  const dateTimeFormats = {
    hu_HU: 'yyyy. MM. dd.',
    en_US: 'dd/MM/yyyy',
  };
  return countryCode && Object.keys(dateTimeFormats).includes(countryCode)
    ? dateTimeFormats[countryCode]
    : dateTimeFormats['en_US'];
};

export const getDateInLocalFormat = (date, long) => {
  const supportedFormat = getLocaleConfig().replace('_', '-');
  if (long) {
    return new Date(date).toLocaleString(supportedFormat);
  }
  return new Date(date).toLocaleDateString(supportedFormat);
};

export const getLocaleConfig = () => {
  let locale = clientConfig && clientConfig.locale ? clientConfig.locale : DEFAULT_LOCALE;
  const localStorageObject = JSON.parse(localStorage.getItem('localStorageObject'));
  if (clientConfig && clientConfig.locales && clientConfig.locales.includes(getBrowserLang())) {
    locale = getBrowserLang();
  }
  if (
    localStorageObject &&
    localStorageObject.auth &&
    localStorageObject.auth.locale &&
    clientConfig &&
    clientConfig.locales &&
    clientConfig.locales.includes(localStorageObject.auth.locale)
  ) {
    locale = localStorageObject.auth.locale;
  }
  return locale;
};

export const getLanguage = () => getLocaleConfig().substr(0, 2);

export const filterBy = (item, filters, searchAndFilter) => {
  return filters.every((filter) => {
    const values = filter.itemProperties.map((itemProp) => dot.pick(itemProp, item));
    return (
      !searchAndFilter[filter.searchProperty] ||
      filterFunctions[filter.type](values, searchAndFilter[filter.searchProperty])
    );
  });
};

export const filterFunctions = {
  boolean: (values, filter) => values && values.every((value) => value) && filter,
  text: (values, filter) => values && filter && values.join('').toLowerCase().includes(filter.toLowerCase()),
  selector: (values, filter) =>
    values && filter && ['', 'all', values.join('').toLowerCase()].includes(filter.toLowerCase()),
  translateObject: (values, filter) =>
    values && filter && Array.isArray(values) && translate(values[0]).toLowerCase().includes(filter.toLowerCase()),
};

export function isNumeric(str) {
  return !isNaN(str) && !isNaN(parseFloat(str));
}

export function isNumberFragment(str) {
  return str === '-' || isNumeric(str);
}

export function transformValue(value, dataset, fields = {}) {
  if (!value || !dataset) {
    return '';
  }

  return value.replace(/\{\{([^}]+)\}\}/g, ($0, $1) => {
    const value = dataset.find((f) => f.fieldId === $1);
    const field = fields[$1];

    if (!value) {
      return '';
    }

    if (!field) {
      return value.value;
    }

    switch (field.type) {
      case 'select':
      case 'radio':
        return value.value.label;
      default:
        return value.value;
    }
  });
}

export function transformValuFromObject(value, dataset) {
  if (!value || !dataset) {
    return '';
  }

  return value.replace(/\{\{([^}]+)\}\}/g, ($0, $1) => {
    if (dataset[$1] && dataset[$1].value) {
      const value = dataset[$1].value;
      return value;
    }

    return '';
  });
}

export function isP4y() {
  return clientConfig && clientConfig.views && clientConfig.views.login === 'p4y';
}

export function flattenArrayByValue(array) {
  return array.reduce((acc, val) => {
    return [...acc, ...val.values];
  }, []);
}

export function valuesHasError(values) {
  return values.some((v) => v.error);
}

export function filterBySearch(v, search) {
  return (
    translate(v.label).toLowerCase().includes(search.toLowerCase()) ||
    getNiceValueFormat(v, v.value).toLowerCase().includes(search.toLowerCase())
  );
}

export function mapFieldValues(groups, fields) {
  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    for (let j = 0; j < group.values.length; j++) {
      const fieldId = group.values[j].fieldId;
      if (fields[fieldId] && fields[fieldId].type === 'radio' && Array.isArray(group.values[j].value)) {
        groups[i].values[j].value = group.values[j].value[0];
      }
      if (fields[fieldId] && fields[fieldId].type === 'select' && typeof group.values[j].value === 'string') {
        groups[i].values[j].value = { label: group.values[j].value, value: group.values[j].fieldId };
      }
    }
  }

  return groups;
}

export function afterLogin(login, response, email, userData, history) {
  login(response.data);
  if (email) {
    Sentry.setUser({ email });
  }
  const navigateAfterLogin = localStorage.getItem('navigateAfterLogin');
  const usersMainRoute = userData && userData.mainRoute;
  if (navigateAfterLogin) {
    history.push(calculateRoutePath(usersMainRoute, navigateAfterLogin));
    localStorage.removeItem('navigateAfterLogin');
  } else {
    history.push('/');
  }
}

export const deviceType = {
  bigScreen: 'bigScreen',
  tablet: 'tablet',
  phone: 'phone',
};

export function useGetDevice() {
  const isBigScreen = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 480, maxWidth: 992 });
  const isPhone = useMediaQuery({ maxWidth: 480 });

  if (isBigScreen) {
    return deviceType.bigScreen;
  }
  if (isTablet) {
    return deviceType.tablet;
  }
  if (isPhone) {
    return deviceType.phone;
  }
}

export function useIsBigScreen() {
  const device = useGetDevice();

  return device === deviceType.bigScreen;
}

export function useGetViewMode(viewMode, componentName) {
  const device = useGetDevice();

  if (device === deviceType.phone || device === deviceType.tablet) {
    return 'tile';
  }

  return viewMode[componentName];
}

export function getHeaderLinks() {
  const headerLinks = [
    { to: '/client-management', translateKey: 'header/client', allowedRules: ['list-entities'], mobileSubMenu: true },
    { to: '/user-management', translateKey: 'header/user', allowedRules: ['list-users'], mobileSubMenu: true },
  ];

  if (window.clientConfig && window.clientConfig.dataManagementEnabled) {
    headerLinks.push({
      to: '/data-management',
      translateKey: 'header/data',
      allowedRules: ['list-predefined-groups'],
      mobileSubMenu: true,
    });
  }

  return headerLinks;
}

export function isProcessViewEnabled(rules) {
  return window.clientConfig.enableProcessView && rules.includes('enable-process-view');
}

export function isLibraryView(pathName) {
  return pathName.includes('data');
}

export function isNewsletterEnabled() {
  return window.clientConfig.mailchimpEnabled;
}

export function numberToString(value) {
  let val = value;
  if (typeof value === 'string') {
    val = value.replaceAll('-', '').replaceAll(' ', '').replaceAll('+', '');
  }

  return BigInt(val).toString();
}

export function tryGetRObject() {
  const r = decodeURIComponent(
    window.location.search.replace(
      new RegExp('^(?:.*[&\\?]' + encodeURIComponent('r').replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'),
      '$1'
    )
  );

  if (r === '') {
    return;
  }

  try {
    return JSON.parse(window.atob(r));
  } catch {
    return;
  }
}

export function getStage(stages, stageMeta, stageId, isLimited) {
  if (isLimited && !stages[stageId]) {
    const sortedStageMeta = stageMeta.sort((a, b) => a.position - b.psotion);
    if (sortedStageMeta.length > 0) {
      return stages[sortedStageMeta[0].stageId];
    } else {
      return null;
    }
  } else {
    return stages[stageId] || null;
  }
}

export function arrayToObject(array) {
  return array.reduce(function (map, obj) {
    map[obj._id] = obj;
    return map;
  }, {});
}

export function isTermsEnabled() {
  return (
    window.clientConfig.termsAndConditionsRegistration === undefined ||
    window.clientConfig.termsAndConditionsRegistration
  );
}

export function isPrivacyEnabled() {
  return window.clientConfig.privacyPolicyRegistration === undefined || window.clientConfig.privacyPolicyRegistration;
}

export function mapGroupsToSubmission(submission, groups) {
  return { ...submission, data: submission.data.map((groupId) => groups[groupId]) };
}

export function mapValuesToGroup(group, fields) {
  return { ...group, values: group.values.map((fieldId) => fields[fieldId]) };
}

export function mapWholeSubmission(submission, groups, fields) {
  const t1 = performance.now();
  let result;
  for (let i = 0; i < 100; i++) {
    result = { ...submission, data: submission.data.map((groupId) => mapValuesToGroup(groups[groupId], fields)) };
  }
  const t2 = performance.now();
  console.log(t2 - t1);
  return result;
}

export let allTime = 0;

export function onAdminRenderCallback(
  id, // the "id" prop of the Profiler tree that has just committed
  phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration, // time spent rendering the committed update
  baseDuration, // estimated time to render the entire subtree without memoization
  startTime, // when React began rendering this update
  commitTime, // when React committed this update
  interactions // the Set of interactions belonging to this update
) {
  if (actualDuration < 50) return;

  allTime += actualDuration;
  console.log(`alltime: ${id}: `, allTime);
  console.log('intercations: ', interactions);
  console.log('phase: ', phase);
}

export let groupRowTime = 0;
let count = 0;

export function onGroupRowRenderCallback(
  id, // the "id" prop of the Profiler tree that has just committed
  phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration, // time spent rendering the committed update
  baseDuration, // estimated time to render the entire subtree without memoization
  startTime, // when React began rendering this update
  commitTime, // when React committed this update
  interactions // the Set of interactions belonging to this update
) {
  groupRowTime += actualDuration;
  count += 1;
  // console.log(`alltime: ${id}: `, groupRowTime);
  // console.log('intercations: ', interactions);
  // console.log('phase: ', phase);
  // console.log('count: ', count);
  // console.log('avarage: ', groupRowTime / count);
  console.log('all group: ', groupRowTime);
}

export function getConditionMatch(conditionalFieldValue, conditionalValues, field) {
  if (!conditionalFieldValue.value && conditionalFieldValue.value !== false) {
    return false;
  }
  switch (field.type) {
    case 'text':
    case 'number':
    case 'textarea':
      return conditionalValues.includes(conditionalFieldValue.value);
    case 'radio':
      return conditionalValues.includes(conditionalFieldValue.value.id);
    case 'date':
      break;
    case 'checkbox': {
      const conditionalFieldValueIds = conditionalFieldValue.value.map((v) => v.id);
      const matchingValues = conditionalValues.filter((value) => conditionalFieldValueIds.includes(value));
      return matchingValues.length > 0;
    }
    case 'select':
      if (conditionalFieldValue.value.length) {
        const conditionalFieldValueIds = conditionalFieldValue.value.map((v) => v.value);
        const matchingValues = conditionalValues.filter((value) => conditionalFieldValueIds.includes(value));
        return matchingValues.length > 0;
      } else {
        return conditionalValues.includes(conditionalFieldValue.value.value);
      }
    default:
  }
  return false;
}

export function allConditionsMet(field, fields, groupId, groupResult) {
  const andConditions = [],
    orConditions = [];
  if (field.dependsOn && field.dependsOn.length > 0) {
    field.dependsOn
      .filter((condition) => condition.groupId === groupId)
      .forEach((condition) => {
        const conditionalFieldResult = groupResult.values
          ? groupResult.values.find((v) => v.fieldId === condition.fieldId)
          : null;
        let conditionMet = false;
        if (conditionalFieldResult) {
          conditionMet = getConditionMatch(
            conditionalFieldResult,
            condition.conditionalValues,
            fields[condition.fieldId]
          );
        }
        if (condition.conditionType === 'or') {
          orConditions.push(conditionMet);
        } else if (condition.conditionType === 'and') {
          andConditions.push(conditionMet);
        }
      });
    const andConditionsFailed = andConditions.length > 0 && andConditions.filter((c) => !c).length > 0;
    const orConditionsFailed = orConditions.length > 0 && orConditions.filter((c) => c).length === 0;
    if (andConditionsFailed || orConditionsFailed) {
      return false;
    }
  }

  return true;
}

export function getConditionsForGroup(group, results, fields) {
  const andConditions = [],
    orConditions = [];
  if (group.dependsOn && group.dependsOn.length > 0) {
    group.dependsOn.forEach((condition) => {
      const conditionalGroupResult = results ? results.find((r) => r.groupId === condition.groupId) : { values: [] };
      let conditionalFieldValue = null;
      let conditionMet = false;
      if (conditionalGroupResult) {
        conditionalFieldValue = conditionalGroupResult.values.find((value) => value.fieldId === condition.fieldId);
        if (conditionalFieldValue) {
          conditionMet = getConditionMatch(
            conditionalFieldValue,
            condition.conditionalValues,
            fields[conditionalFieldValue.fieldId]
          );
        }
      }
      if (condition.conditionType === 'or') {
        orConditions.push(conditionMet);
      } else if (condition.conditionType === 'and') {
        andConditions.push(conditionMet);
      }
    });
  }
  const andConditionsFailed = andConditions.length > 0 && andConditions.filter((c) => !c).length > 0;
  const orConditionsFailed = orConditions.length > 0 && orConditions.filter((c) => c).length === 0;

  return [andConditionsFailed, orConditionsFailed];
}

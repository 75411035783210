import React, { useRef, useEffect } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';

import Svg from '../Svg';

import './Esign.scss';

import trash from '../../assets/trash.svg';

const Esign = ({ value, onChange, disabled = false }) => {
  const signaturePad = useRef(null);
  useEffect(() => {
    if (!signaturePad && !signaturePad.current) return;
    if (value) {
      const signData = JSON.parse(atob(value));
      signaturePad.current.fromData(signData);
    }
    if (onChange) {
      signaturePad.current.onEnd = () => {
        const data = signaturePad.current.toData();
        onChange(btoa(JSON.stringify(data)));
      };
    } else {
      signaturePad.current.off();
    }
  }, [signaturePad, value]);

  return (
    <div className={`signature-pad-wrapper ${disabled ? 'disabled' : ''}`}>
      <SignaturePad redrawOnResize={true} ref={signaturePad} onChange={() => console.log('mizu')} />
      {onChange && <Svg className="trash" src={trash} onClick={() => signaturePad.current.clear()} />}
    </div>
  );
};

export default Esign;

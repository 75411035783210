import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button/Button';
import Svg from '../../../../components/Svg';
import MessengerTextInput from './MessengerTextInput/MessengerTextInput';
import { isNumberFragment } from '../../../../helper/functions';

import sendButton from '../../../../assets/send_button.svg';
import cancel from '../../../../assets/cancel.svg';
import './Footer.scss';

export function Footer({ children }) {
  return <div className="upsoo-footer msger-inputarea">{children}</div>;
}

Footer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export function InputArea({
  submissionId,
  isFormDone,
  nextStage,
  lastQuestion,
  editMode,
  editedField,
  isTextInputActive,
  currentAnswer,
  originalValue,
  history,
  handleAnswerChange,
  handleUpdateClick,
  handleSendClick,
  handleFocus,
}) {
  const inputValue = typeof currentAnswer === 'string' ? currentAnswer : '';
  const mode = getMode(editMode, isFormDone, nextStage);
  const handleCancelClick = () => {
    history.push(`/${submissionId}/${nextStage._id}`);
  };

  switch (mode) {
    case 'edit':
      return (
        <>
          <InputField
            isDisabled={!isTextInputActive}
            inputValue={inputValue}
            meta={editedField}
            handleAnswerChange={handleAnswerChange}
          />
          <div className="msger-buttons">
            <button
              onClick={handleUpdateClick}
              disabled={(!inputValue && isTextInputActive) || currentAnswer === originalValue}
            >
              <img className="msger-send-btn" alt="Send" src={sendButton} />
            </button>
            <button onClick={handleCancelClick}>
              <Svg className="msger-cancel-btn" src={cancel} onClick={handleCancelClick} otherColor="#c0392b" />
            </button>
          </div>
        </>
      );
    case 'formEnded':
      return (
        <Button
          extraClass="messenger"
          onClick={() => {
            history.push(`/${submissionId}/${nextStage._id}`);
          }}
        >
          Go to summary
        </Button>
      );
    case 'fill':
    default:
      return (
        <>
          <InputField
            isDisabled={!isTextInputActive}
            inputValue={inputValue}
            meta={lastQuestion.meta}
            handleAnswerChange={handleAnswerChange}
            handleFocus={handleFocus}
          />
          <div className="msger-buttons">
            <button onClick={handleSendClick} disabled={!inputValue && isTextInputActive}>
              <img className="msger-send-btn" alt="Send" src={sendButton} />
            </button>
          </div>
        </>
      );
  }
}

InputArea.propTypes = {
  submissionId: PropTypes.string.isRequired,
  isFormDone: PropTypes.bool.isRequired,
  nextStage: PropTypes.object.isRequired,
  lastQuestion: PropTypes.object,
  editMode: PropTypes.bool.isRequired,
  editedField: PropTypes.object,
  isTextInputActive: PropTypes.bool,
  currentAnswer: PropTypes.any.isRequired,
  originalValue: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
  handleAnswerChange: PropTypes.func.isRequired,
  handleUpdateClick: PropTypes.func.isRequired,
  handleSendClick: PropTypes.func.isRequired,
};

InputArea.defaultProps = {
  lastQuestion: undefined,
  isTextInputActive: undefined,
  editedField: undefined,
};

function getMode(editMode, isFormDone, nextStage) {
  if (editMode) {
    return 'edit';
  }

  if (isFormDone && !!nextStage) {
    return 'formEnded';
  }

  return 'fill';
}

function InputField({ isDisabled, inputValue, meta, handleAnswerChange, handleFocus }) {
  if (meta.type === 'date') {
    return (
      <input
        className="msger-input single-line"
        type="date"
        disabled={isDisabled}
        value={inputValue}
        onChange={handleAnswerChange}
        min="1900-01-01"
        max="2050-12-31"
        onFocus={handleFocus}
      />
    );
  }

  if (meta.numberMask === 'penny') {
    const handleOnChange = (e) => {
      if (e.target.value === '' || isNumberFragment(e.target.value)) {
        handleAnswerChange(e);
      }
    };

    return (
      <input
        className="msger-input single-line"
        placeholder="Csak szamokat irhat be..."
        type="text"
        disabled={isDisabled}
        value={inputValue}
        onChange={handleOnChange}
        onFocus={handleFocus}
      />
    );
  }

  return (
    <MessengerTextInput
      inputValue={inputValue}
      isDisabled={isDisabled}
      handleAnswerChange={handleAnswerChange}
      fieldId={meta.fieldId}
      handleFocus={handleFocus}
    />
  );
}

InputField.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meta: PropTypes.object.isRequired,
  handleAnswerChange: PropTypes.func.isRequired,
};

InputField.defaultProps = {
  inputValue: '',
};

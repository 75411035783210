export const navigateWithRules = (userData, rules, onMatch, notMatch) => {
  if (isUserAllowed(userData, rules)) {
    onMatch();
  } else {
    notMatch();
  }
}

export const isUserAllowed = (userData, rules) => {
  const userRules = (userData && userData.rules) ? userData.rules : [];
  return rules.some(rule => userRules.includes(rule)) || !rules.length;
}

export const calculateRoutePath = (usersMainRoute, route) => {
  let calculatedRoute;
  if (route.includes(usersMainRoute)) {
    calculatedRoute = route.replace(usersMainRoute, '')
    if (calculatedRoute.length === 0) {
      calculatedRoute = '/'
    }
  } else {
    calculatedRoute = route
  }
  return calculatedRoute;
}
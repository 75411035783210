export function hasNonNumbers(value) {
  return !/^[0-9]*$/.test(value);
}

export function getBankMask(value) {
  let group1 = '';
  let group2 = '';
  let group3 = '';
  for (let i = 0; i < value.length; i++) {
    if (i < 8) {
      group1 += value[i];
    }
    if (i >= 8 && i < 16) {
      group2 += value[i];
    }
    if (i >= 16) {
      group3 += value[i];
    }
  }

  if (group1.length <= 8 && group2.length === 0) {
    return group1;
  }

  if (group2.length > 0 && group2.length <= 8 && group3.length === 0) {
    return `${group1}-${group2}`;
  }

  if (group3.length > 0) {
    return `${group1}-${group2}-${group3}`;
  }
}

export function getPhoneMask(value) {
  switch (value.length) {
    case 0:
    case 1:
    case 2:
      return value;
    case 3:
      return `${value.slice(0, 2)}-${value[2]}`;
    case 4:
      return `${value.slice(0, 2)}-${value.slice(2, 4)}`;
    case 5:
      return `${value.slice(0, 2)}-${value.slice(2, 5)}`;
    case 6:
      return `${value.slice(0, 2)}-${value.slice(2, 5)}-${value[5]}`;
    case 7:
      return `${value[0]}-${value.slice(1, 4)}-${value.slice(4, 7)}`;
    case 8:
      return `${value.slice(0, 2)}-${value.slice(2, 5)}-${value.slice(5, 8)}`;
    case 9:
      return `${value.slice(0, 2)}-${value.slice(2, 5)}-${value.slice(5, 7)}-${value.slice(7, 9)}`;
    default:
      return `${value.slice(0, 2)}-${value.slice(2, 5)}-${value.slice(5, 7)}-${value.slice(7, 20)}`;
  }
}

import React from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {
	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			const scrollToTopDiv = document.getElementsByClassName('scroll-to-top')[0];
			if(scrollToTopDiv) {
				scrollToTopDiv.scrollTop = 0;
			}
		}
	}

	render() {
	return this.props.children
	}
}

export default withRouter(ScrollToTop);
import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import Svg from '../../../components/Svg';

import checkmark from '../../../assets/check.svg';
import delete_icon from '../../../assets/delete_icon.svg';
import pending from '../../../assets/pending.svg';
import './Fitness.scss';

const values = {
  0: 'applicants/under-assessment',
  1: 'applicants/unsuitable',
  2: 'applicants/suitable',
};

export default function Fitness({ value }) {
  switch (value) {
    case 0:
      return (
        <div className="row">
          <Svg className="icon" otherColor="#FFBA00" src={pending} />
          <span className="text">{I18n.t(values[value])}</span>
        </div>
      );
    case 1:
      return (
        <div className="row">
          <Svg className="icon" otherColor="#F02B2B" src={delete_icon} />
          <span className="text">{I18n.t(values[value])}</span>
        </div>
      );
    default:
      return (
        <div className="row">
          <Svg className="icon" otherColor="#00D96D" src={checkmark} />
          <span className="text">{I18n.t(values[value])}</span>
        </div>
      );
  }
}

Fitness.propTypes = {
  value: PropTypes.number.isRequired,
};

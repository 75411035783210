import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import axios from 'axios';
import WrappedLink from '../WrappedLink/WrappedLink';

import InputWithError from '../InputWithError';
import Header from '../Header';
import Footer from '../Footer';
import Popup from '../Popup';

import { formValidator } from '../../helper/functions';

import sent_icon from '../../assets/sent_icon.svg';
import './ForgottenPassword.scss';

class ForgottenPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      formData: {
        email: '',
      },
      errors: {},
      popup: {
        shown: false,
        title: '',
        message: '',
      },
    };
    this.headerLinks = [{ to: '/login', translateKey: 'menu/login' }];
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push('/');
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState((state) => {
      return {
        formData: {
          ...state.formData,
          [name]: value,
        },
        errors: { ...state.errors, ...formValidator(name, value) },
      };
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post('/auth/request-reset-password', { email: this.state.formData.email, type: 'email' })
      .then((response) => {
        this.setState((state) => {
          return {
            ...state,
            popup: {
              ...this.state.popup,
              shown: true,
              title: I18n.t('general/success'),
              message: I18n.t('forgotten-password/popup-message'),
            },
          };
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  okHandler = () => {
    this.setState((state) => {
      return {
        ...state,
        popup: {
          shown: false,
          title: '',
          message: '',
        },
      };
    });
    this.props.history.push('/');
  };

  render() {
    const { formData, errors, popup } = this.state;
    const formValid =
      Object.keys(errors)
        .map((k) => errors[k])
        .filter((e) => e).length === 0;
    const formEmpty = formData.email === '';

    return (
      <div className="authentication-page-content">
        <Popup popupShown={popup.shown} popupTitle={popup.title} okHandler={this.okHandler}>
          <span>{popup.message}</span>
          {window.theme === 'axial' && <img alt="icon_sent" src={sent_icon} />}
        </Popup>
        <Header links={this.headerLinks} />
        <div bp="full-width-until@sm 6--max" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <div className="form-wrapper no-header">
            <h1 className="login-headline">
              <Translate value="forgotten-password/title" />
            </h1>
            <div className="form-container">
              <h4 className="">
                <Translate value="forgotten-password/registered-email" />
              </h4>
              <form onSubmit={this.handleSubmit}>
                <InputWithError
                  placeholder={I18n.t('form/email')}
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={this.handleInputChange}
                  errorMsg={errors.email}
                />
                <div className="button-container more-children">
                  <WrappedLink to={'/login'}>
                    <Translate className="cancel" value="form/cancel" />
                  </WrappedLink>
                  <button type="submit" disabled={!formValid || formEmpty} className="medium">
                    <Translate value="forgotten-password/reset-password" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

ForgottenPassword.propTypes = {
  authenticated: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  };
}

export default connect(mapStateToProps, null)(ForgottenPassword);

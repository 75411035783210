import React from 'react';

import AuthComponentWrapper from '../AuthComponentWrapper';
import WrappedLink from '../WrappedLink';
import TextWithTooltip from '../TextWithTooltip';

import './SideBarNavigationMobile.scss';

function SideBarNavigationMobile({ navigation }) {
  if (navigation) {
    return (
      <div bp="full-width flex" className="sidebar-navigation-mobile">
        {navigation.map((link, index) => (
          <div key={index} bp="fill" className={`menu-item ${link.class}`}>
            <AuthComponentWrapper allowedRules={link.rules}>
              {link.class === 'inactive' ? (
                <TextWithTooltip textWithOverflow={link.name} />
              ) : (
                <WrappedLink to={link.destination} className={link.class} navLink={true}>
                  <TextWithTooltip textWithOverflow={link.name} />
                </WrappedLink>
              )}
            </AuthComponentWrapper>
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
}

export default SideBarNavigationMobile;

import React from 'react';
import SimpleBar from 'simplebar-react';

import { transformValuFromObject, filterBySearch } from '../../../helper/functions';
import PredefinedGroupResult from '../PredefinedGroupResult/PredefinedGroupResult';

const DataSummary = ({ predefinedGroupData, search }) => {
  return (
    <div className="admin-data-container scroll-to-top hide-scrollbar">
      <SimpleBar style={{ maxHeight: '100%' }}>{renderGroupDetails(predefinedGroupData, search)}</SimpleBar>
    </div>
  );
};

function renderGroupDetails(predefinedGroupData, search) {
  switch (predefinedGroupData.status) {
    case 'loading':
      return <div role="loader"></div>;
    case 'error':
      return <div role="data-summary-error">Error while loading predefined groups!</div>;
    case 'success':
      return <SuccessfulDataSummary predefinedGroupData={predefinedGroupData} search={search} />;
  }
}

function SuccessfulDataSummary({ predefinedGroupData, search }) {
  return (
    <>
      {renderAdd(predefinedGroupData)}
      {Object.values(predefinedGroupData.model.groups)
        .filter((g) => g.visible)
        .filter((g) => Object.values(g.values).filter((v) => filterBySearch(v, search)).length > 0)
        .reverse()
        .map((group) => {
          const label = transformValuFromObject(predefinedGroupData.model.referenceNameTemplate, group.values);
          return (
            <PredefinedGroupResult
              key={group._id}
              {...group}
              predefinedGroupData={predefinedGroupData}
              label={label}
              search={search}
            />
          );
        })}
      {Object.values(predefinedGroupData.model.groups)
        .filter((g) => !g.visible)
        .filter((g) => Object.values(g.values).filter((v) => filterBySearch(v, search)).length > 0)
        .reverse()
        .map((group) => {
          const label = transformValuFromObject(predefinedGroupData.model.referenceNameTemplate, group.values);
          return (
            <PredefinedGroupResult
              key={group._id}
              {...group}
              predefinedGroupData={predefinedGroupData}
              label={label}
              disabled
              search={search}
            />
          );
        })}
    </>
  );
}

function renderAdd(predefinedGroupData) {
  if (predefinedGroupData.model.mode === 'add') {
    const groupToAdd = predefinedGroupData.model.groupToAdd;
    const label = transformValuFromObject(
      predefinedGroupData.model.referenceNameTemplate,
      predefinedGroupData.model.groupToAdd.values
    );
    return (
      <PredefinedGroupResult
        key={groupToAdd.groupId}
        {...groupToAdd}
        predefinedGroupData={predefinedGroupData}
        label={label}
      />
    );
  }

  return null;
}

export default DataSummary;

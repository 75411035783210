import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import ScrollToTop from '../ScrollToTop';
import Admins from '../Admins';
import Users from '../Users';
import RegisteredUsers from '../RegisteredUsers';
import Authorization from '../Authorization';
import AdminDetails from '../AdminDetails';

const UserManagement = ({ match }) => {
  const routes = [
    { path: '/users', rules: ['list-users'] },
    { path: '/admins', rules: ['list-admins'] },
    ...(clientConfig.manualRegistration
      ? [{ path: '/users-waiting-for-registration', rules: ['list-waiting-users'] }]
      : []),
  ];

  const mainRoute = match.url === '/' ? '' : match.url;

  return (
    <ScrollToTop>
      <Switch>
        <Route path={`${mainRoute}/admins`} render={(routeProps) => <Admins {...routeProps} routes={routes} />} />
        <Route
          path={`${mainRoute}/admin/:adminId`}
          render={(routeProps) => <AdminDetails {...routeProps} routes={routes} />}
        />
        <Route path={`${mainRoute}/users`} render={(routeProps) => <Users {...routeProps} routes={routes} />} />
        <Route
          path={`${mainRoute}/users-waiting-for-registration`}
          render={(routeProps) => <RegisteredUsers {...routeProps} routes={routes} />}
        />
        <Redirect from={``} to={`${mainRoute}/users`} />
      </Switch>
    </ScrollToTop>
  );
};

export default Authorization(UserManagement, ['list-users']);

import React, { Suspense } from 'react';

// clientConfig
window.theme = getSlug();

const ThemeAxial = React.lazy(() => import('./ThemeAxial'));
const ThemeDefault = React.lazy(() => import('./ThemeDefault'));

export default function Theme() {
  return <Suspense fallback={<div>Loading...</div>}>{renderTeheme()}</Suspense>;

  function renderTeheme() {
    switch (window.theme) {
      case 'axial':
        return <ThemeAxial />;
      default:
        return <ThemeDefault />;
    }
  }
}

function getSlug() {
  return window.location.hostname.split('.')[0];
}

import React from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../helper/functions';

import '../Upsoo.scss';
import './SubmissionSummary.scss';

export default function SummaryDataCheck({ header, children }) {
  return (
    <div className="summary">
      <table>
        {header}
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}

SummaryDataCheck.propTypes = {
  header: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

SummaryDataCheck.defaultProps = {
  header: null,
};

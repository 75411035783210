import { createSlice } from '@reduxjs/toolkit';

const filedValuesSlice = createSlice({
  name: 'fieldValues',
  initialState: {},
  reducers: {
    setFieldValues: (state, { payload }) => {
      Object.entries(payload).forEach((entry) => {
        state[entry[0]] = entry[1];
      });
    },
  },
  extraReducers: {
    'submissionsPlus/submissionsDataFetched': (state, { payload: { data } }) => {
      if (!state[data._id]) {
        state[data._id] = {};
      }

      // TODO map field values (radio, checkbox hack)
      for (const group of data.data) {
        for (const field of group.values) {
          if (!state[data._id][field.fieldId]) {
            state[data._id][field.fieldId] = field;
          }
        }
      }
    },
    'submissions/changeValue': (state, { payload }) => {
      if (!state[payload.submissionId]) {
        state[payload.submissionId] = {};
      }

      if (!state[payload.submissionId][payload.fieldId]) {
        state[payload.submissionId][payload.fieldId] = {
          fieldId: payload.fieldId,
          value: payload.value,
        };
      } else {
        state[payload.submissionId][payload.fieldId].value = payload.value;
      }
    },
  },
});

export const { setFieldValues } = filedValuesSlice.actions;
export default filedValuesSlice.reducer;

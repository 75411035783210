import React from 'react';
import PropTypes from 'prop-types';

import './Button.scss';

function Button({ type, onClick, children, extraClass }) {
  return (
    <button type="button" onClick={onClick} className={`upsoo-button ${type} ${extraClass}`}>
      {children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.oneOf(['', 'primary', 'secondary']),
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  extraClass: PropTypes.string,
};

Button.defaultProps = {
  type: '',
  extraClass: '',
};

export default Button;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';

import Authorization from '../Authorization';
import Submissions from '../Submissions';
import ModuleSelector from '../ModuleSelector';
import DataVault from '../DataVault';
import DataSummary from '../EntityDataSummary';
import Delegates from '../Delegates';
import Popup from '../Popup';
import InputWithError from '../InputWithError';
import SideBarLayout from '../SideBarLayout';

import * as entityActions from '../../actions/entity';
import * as modulesActions from '../../actions/modules';
import * as errorActions from '../../actions/error';
import * as entityThunks from '../../thunks/entity';

import { translate, formValidator } from '../../helper/functions';
import { calculateRoutePath } from '../../helper/navigation';

import './EntityDetails.scss';

const EntityDetails = (props) => {
  const { entityId } = props.params;
  const {
    token,
    match,
    setEntity,
    modules,
    setModuleData,
    entity,
    fetchEntities,
    userData,
    history,
    entities,
    throwError,
  } = props;
  const [submissions, setSubmissions] = useState([]);
  const [dataArrived, setArrived] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [popup, setPopup] = useState({
    shown: false,
    password: '',
    errors: {},
  });

  useEffect(() => {
    const header = { headers: { Authorization: 'Bearer ' + token } };
    const moduleRequests = axios
      .get(`/drf/submissions?entityId=${entityId}`, header)
      .then((response) => {
        setSubmissions(response.data);
        let uniqueModuleIds = [...new Set(response.data.map((s) => s.moduleId))];
        let requests = uniqueModuleIds.map((moduleId) => axios.get('/drf/modules/' + moduleId, header));
        return axios.all(requests);
      })
      .then((response) => {
        response.forEach((r) => {
          setModuleData(r.data);

          const fields = {};
          r.data.fields.forEach((f) => (fields[f._id] = { ...f }));

          const requests = [
            moduleRequests,
            axios.get(`/entities/${entityId}`, header),
            axios.get(`/entities/${entityId}/media-library`, header),
          ];

          fetchEntities({ forceRefresh: false });
          axios
            .all(requests)
            .then((response) => {
              setEntity(response[1].data, fields);
              setDocuments(response[2].data);
              setArrived(true);
            })
            .catch((e) => {
              console.log(e);
              throwError(e);
            });
        });
      })
      .catch((e) => {
        console.log(e);
        throwError(e);
      });
  }, []);

  if (!dataArrived) return null;

  const usersMainRoute = userData && userData.mainRoute;
  let userRole = 'client';
  if (userData && userData.role !== 'user') {
    userRole = 'admin';
  }
  const unableToSeeEntityData =
    (entity && entity.entityData && entity.entityData.length === 0) || (entity && entity.state === 'incomplete');
  const unableToStartNewProcess = clientConfig.blockMultipleSubmission
    ? submissions.length > 0 && submissions.filter((s) => !s.completed).length > 0
    : submissions.filter((s) => !s.completed && modules[s.moduleId] && modules[s.moduleId].blockMultipleSubmission)
        .length > 0;
  const subPageTitle =
    Object.values(entity).length > 0
      ? entity.data.name
        ? entity.data.name
        : `${I18n.t('general/draft')} - ${translate(entity.creatorModule.label)}`
      : null;
  const hideNewProcesses = !Object.values(modules).find((item) => !item.autoCreateEntity);
  const hideBackButton = clientConfig && clientConfig.maxEntityCount === 1 && entities.length === 1;

  const navigation = {
    submissions: {
      destination: `/client-management/entity/${entityId}/submissions`,
      name: I18n.t('entity-details/sidebar/submissions'),
      class: '',
      component: (routeProps) => (
        <Submissions
          {...props}
          {...routeProps}
          submissions={submissions}
          setSubmissions={setSubmissions}
          subPageTitle={subPageTitle}
        />
      ),
      rules: ['list-submissions'],
    },
    'data-summary': {
      destination: `/client-management/entity/${entityId}/data-summary`,
      name: I18n.t('entity-details/sidebar/data-summary'),
      class: unableToSeeEntityData ? 'inactive' : '',
      component: (routeProps) => <DataSummary {...props} {...routeProps} subPageTitle={subPageTitle} />,
      rules: ['list-submissions'],
    },
    'data-vault': {
      destination: `/client-management/entity/${entityId}/data-vault`,
      name: I18n.t('entity-details/sidebar/data-vault'),
      class: unableToSeeEntityData ? 'inactive' : '',
      component: (routeProps) => (
        <DataVault
          {...props}
          {...routeProps}
          documents={documents}
          subPageTitle={subPageTitle}
          submissions={submissions}
        />
      ),
      rules: ['list-submission-documents'],
    },
    'new-processes': {
      destination: `/client-management/entity/${entityId}/new-processes`,
      name: I18n.t('entity-details/sidebar/new-processes'),
      class: hideNewProcesses ? 'hidden' : unableToStartNewProcess ? 'inactive' : '',
      component: (routeProps) => (
        <ModuleSelector {...props} {...routeProps} filterAutoCreate={true} subPageTitle={subPageTitle} />
      ),
      rules: ['create-submission'],
    },
    delegates: {
      destination: `/client-management/entity/${entityId}/delegates`,
      name: I18n.t('entity-details/sidebar/delegates'),
      class: '',
      component: (routeProps) => <Delegates {...props} {...routeProps} subPageTitle={subPageTitle} />,
      rules: ['delegate-user'],
    },
  };
  const sideBarElements = {
    redirectRoute: `${match.url}/${
      clientConfig && clientConfig.sidebars && clientConfig.sidebars.entityDetails
        ? clientConfig.sidebars.entityDetails[userRole].filter(
            (sb) => navigation[sb].class !== 'hidden' && navigation[sb].class !== 'inactive'
          )[0]
        : 'submissions'
    }`,
    navigation:
      clientConfig && clientConfig.sidebars && clientConfig.sidebars.entityDetails
        ? clientConfig.sidebars.entityDetails[userRole].map((navElem) => navigation[navElem])
        : Object.values(navigation),
    backButton: {
      title: I18n.t('entity-details/sidebar/back-button'),
      url: '/client-management',
    },
    deleteButton: {
      text: I18n.t('entity-details/sidebar/delete-button'),
      onClick: () => setPopup({ ...popup, shown: true }),
      rules: ['delete-entity'],
    },
  };

  const popupOkDisabled = !!popup.errors.password || popup.password.length < 1;
  const closePopup = () => {
    setPopup({
      ...popup,
      shown: false,
      password: '',
      errors: {},
    });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    setPopup({
      ...popup,
      password: value,
      errors: { ...popup.errors, ...formValidator(name, value) },
    });
  };

  const deleteEntity = () => {
    const header = { headers: { Authorization: 'Bearer ' + token } };
    axios
      .delete('/entities/' + entityId, { data: { currentPassword: popup.password }, ...header })
      .then((response) => {
        fetchEntities();
        closePopup();
        history.push(calculateRoutePath(usersMainRoute, '/client-management'));
      })
      .catch((e) => {
        setPopup({
          ...popup,
          errors: {
            ...popup.errors,
            password: I18n.t(e.response.data.error),
          },
        });
      });
  };

  return (
    <div className="entity-details-container container row-direction">
      <Popup
        popupShown={popup.shown}
        popupTitle={I18n.t('delete-process/popup-title')}
        cancelHandler={closePopup}
        okHandler={deleteEntity}
        okDisabled={popupOkDisabled}
      >
        <div className="form-container">
          <span>{I18n.t('delete-process/popup-text')}</span>
          <InputWithError
            placeholder={I18n.t('form/password')}
            name="password"
            type="password"
            value={popup.password}
            onChange={handleInputChange}
            errorMsg={popup.errors.password}
          />
        </div>
      </Popup>
      <SideBarLayout sideBarElements={sideBarElements} dataArrived={dataArrived} hideBackButton={hideBackButton} />
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { params } = ownProps.match;
  const { history } = ownProps;
  return {
    params,
    history,
    modules: state.modules.modules,
    token: state.auth.token,
    entity: state.entity.entity,
    entities: state.entity.entities,
    userData: state.auth.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEntity: entityActions.setEntity,
      setModuleData: modulesActions.setModuleData,
      fetchEntities: entityThunks.fetchEntities,
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default Authorization(connect(mapStateToProps, mapDispatchToProps)(EntityDetails), [
  'list-submissions',
  'list-submission-documents',
  'create-submission',
  'delegate-user',
  'risk-assesment',
]);

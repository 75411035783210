import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';

import { updatePaymentInfo } from '../../../reducers/current-stage';
import { pageStates } from '../../../helper/payment';
import { tryGetRObject } from '../../../helper/functions';

export function useGetPaymentInfo(submissionId, stageId, config) {
  const rObject = tryGetRObject();
  const [pageState, setPageState] = useState({
    state: pageStates.waitingForPaymentInfo,
    checkCount: 0,
    popupOpen: !!rObject,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updatePaymentInfo({ state: pageStates.waitingForPaymentInfo }));
  }, []);

  useEffect(() => {
    if (pageState.state === pageStates.waitingForPaymentInfo || pageState.state === pageStates.processingPayment) {
      axios
        .post(
          `/drf/submissions/${submissionId}/payment-info`,
          {
            provider: 'simple',
            stageId,
            returnUrl: window.location.href,
          },
          config
        )
        .then((response) => {
          if (response.data.transactionLogs.length === 0) {
            setPageState({ ...pageState, state: pageStates.notPayed, data: response.data });
            dispatch(updatePaymentInfo({ state: pageStates.notPayed }));
            return;
          }
          const sortedTransactions = response.data.transactionLogs
            .filter((transaction) => transaction.status !== null)
            .sort((a, b) => {
              if (moment(a.created).isAfter(moment(b.created))) {
                return -1;
              }

              return 1;
            });
          const lastTransaction = sortedTransactions[0];

          switch (lastTransaction.status) {
            case 'infraud':
            case 'authorized':
            case 'inpayment':
              setPageState({ ...pageState, state: pageStates.processingPayment, data: response.data });
              dispatch(updatePaymentInfo({ state: pageStates.processingPayment }));
              return;
            case 'init':
              setPageState({ ...pageState, state: pageStates.notPayed, data: response.data });
              dispatch(updatePaymentInfo({ state: pageStates.notPayed }));
              return;
            case 'finished':
              setPageState({ ...pageState, state: pageStates.payed, data: response.data });
              dispatch(updatePaymentInfo({ state: pageStates.payed }));
              return;
            case 'timeout':
              setPageState({
                ...pageState,
                state: pageStates.errorPayment,
                data: response.data,
                errorMessage: I18n.t('payment/timeout-error'),
              });
              dispatch(updatePaymentInfo({ state: pageStates.errorPayment }));
              return;
            case 'cancelled':
              setPageState({
                ...pageState,
                state: pageStates.errorPayment,
                data: response.data,
                errorMessage: I18n.t('payment/cancelled-error'),
              });
              dispatch(updatePaymentInfo({ state: pageStates.errorPayment }));
              return;
            case 'fraud':
            case 'notauthorized':
            default:
              setPageState({
                ...pageState,
                state: pageStates.errorPayment,
                data: response.data,
                errorMessage: I18n.t('payment/general-error'),
              });
              dispatch(updatePaymentInfo({ state: pageStates.errorPayment }));
              return;
          }
        })
        .catch((error) => {
          setPageState({ ...pageState, state: pageStates.errorPaymentInfo, error });
          dispatch(updatePaymentInfo({ state: pageStates.errorPaymentInfo }));
        });
    }
  }, [pageState.state, pageState.checkCount]);

  return [pageState, setPageState];
}

export function useCreatePayment(submissionId, stageId, config, pageState, setPageState) {
  const dispatch = useDispatch();

  return useCallback(() => {
    axios
      .post(
        `/drf/submissions/${submissionId}/create-payment`,
        {
          provider: 'simple',
          stageId,
          returnUrl: window.location.origin + window.location.pathname,
        },
        config
      )
      .then(({ data }) => (window.location.href = data.paymentUrl))
      .catch((error) => {
        setPageState({ ...pageState, state: pageStates.errorPayment, errorMessage: error.message });
        dispatch(updatePaymentInfo({ state: pageStates.errorPayment }));
      });
  }, [submissionId, stageId, config, pageState, setPageState]);
}

import React from 'react';
import SimpleBar from 'simplebar-react';
import ReactTooltip from 'react-tooltip';

import Button from '../Button/Button';

import '../Upsoo.scss';
import './SubmissionList.scss';

const submissionListData = [
  {
    title: 'Energetikai és biztonságtechnikai mérnök ez a harmadik sor lenne',
    location: 'Miskolc',
  },
  {
    title: 'Mobil szervizszerelő (földmunkagép)',
    location: 'Nyíregyháza',
  },
  {
    title: 'Alkatrész értékesítő',
    location: 'Balassagyarmat',
  },
  {
    title: 'Kertész-udvaros',
    location: 'Baja',
  },
  {
    title: 'HR-asszisztens',
    location: 'Miskolc',
  },
  {
    title: 'Mobil szervizszerelő (földmunkagép)',
    location: 'Nyíregyháza',
  },
  {
    title: 'Alkatrész értékesítő',
    location: 'Balassagyarmat',
  },
  {
    title: 'Kertész-udvaros',
    location: 'Baja',
  },
  {
    title: 'Energetikai és biztonságtechnikai mérnök',
    location: 'Miskolc',
  },
  {
    title: 'Mobil szervizszerelő (földmunkagép)',
    location: 'Nyíregyháza',
  },
  {
    title: 'Alkatrész értékesítő',
    location: 'Balassagyarmat',
  },
];

function SubmissionList() {
  return (
    <SimpleBar style={{ maxHeight: '100%' }}>
      <div className="submission-list">
        {submissionListData.map((card, index) => (
          <div key={index} className="upsoo-card submission-card">
            <div className="title-container">
              <h1 data-for="tt" data-tip={card.title}>
                {card.title}
              </h1>
              <ReactTooltip
                /* className={classes["overrideMe"]} */
                data-html={true}
                insecure={true}
                multiline={true}
                id="tt"
              />
            </div>
            <div className="location">{card.location}</div>
            <div className="button-container">
              <Button type="secondary" onClick={() => {}}>
                Bővebben
              </Button>
            </div>
            <div className="button-container">
              <Button type="primary" onClick={() => {}}>
                Jelentkezem
              </Button>
            </div>
          </div>
        ))}
      </div>
    </SimpleBar>
  );
}

export default SubmissionList;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Translate, I18n } from 'react-redux-i18n';

import Authorization from '../Authorization';
import Filterbar from '../Filterbar';
import NavigateTabs from '../NavigateTabs';
import Popup from '../Popup';
import SubHeader from '../SubHeader';
import Svg from '../Svg';
import TextWithTooltip from '../TextWithTooltip';
import ResponsiveTable from '../ResponsiveTable';
import ViewOptions from '../ViewOptions';

import { getDateInLocalFormat, filterBy } from '../../helper/functions';
import * as errorActions from '../../actions/error';

import './RegisteredUsers.scss';

import arrow_down from '../../assets/arrow_down.svg';
import arrow_up from '../../assets/arrow_up.svg';
import approve from '../../assets/approve_icon.svg';
import del from '../../assets/del_icon.svg';

const RegisteredUsers = (props) => {
  const { token, routes, throwError } = props;
  const [data, setData] = useState({ registeredUsers: [], fetched: false });
  const [filteredData, setFilteredData] = useState([]);
  const [popup, setPopup] = useState({
    isOpen: false,
    type: '',
    selectedUser: null,
  });
  const [openTableRows, setOpenTableRows] = useState([]);
  const defaultSearchAndFilter = { search: '' };
  const [searchAndFilter, setSearchAndFilter] = useState(defaultSearchAndFilter);
  const headerColums = [
    { name: I18n.t('users-table/table-header-1'), class: 'col-4 sticky', sortName: 'clientName' },
    { name: I18n.t('users-table/table-header-2'), class: 'col-3', sortName: 'email' },
    { name: I18n.t('users-table/table-header-3'), class: '', sortName: 'created' },
    { name: I18n.t('users-table/table-header-9'), class: 'col-2', sortName: '' },
    { name: I18n.t('users-table/table-header-10'), class: 'half', sortName: '' },
  ];

  useEffect(() => {
    if (!data.fetched) {
      fetchData();
    }
    setFilteredData(
      data.registeredUsers.filter((ru) =>
        filterBy(
          ru,
          [{ type: 'text', itemProperties: ['profile.name', 'email'], searchProperty: 'search' }],
          searchAndFilter
        )
      )
    );
  }, [data.fetched, searchAndFilter]);

  const fetchData = async () => {
    try {
      const response = await axios.get('/admin/users?loginEnabled=false', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      setData({
        registeredUsers: response.data,
        fetched: true,
      });
    } catch (e) {
      throwError(e);
    }
  };
  const closePopup = () => setPopup({ isOpen: false, type: '', selectedUser: null });
  const openPopup = (state) => setPopup(state);
  const approveUser = () => {
    const user = data.registeredUsers.find((user) => user._id === popup.selectedUser);
    if (!user) return;
    axios
      .put(
        '/admin/users/' + popup.selectedUser,
        {
          loginEnabled: true,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        setData({ registeredUsers: data.registeredUsers.filter((c) => c._id !== popup.selectedUser) });
        closePopup();
      })
      .catch((e) => throwError(e));
  };
  const deleteUser = () => {
    axios
      .delete('/admin/users/' + popup.selectedUser, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        setData({ registeredUsers: data.registeredUsers.filter((c) => c._id !== popup.selectedUser) });
        closePopup();
      })
      .catch((e) => throwError(e));
  };
  const openTableRow = (user) => {
    const newOpenTableRows = [...openTableRows];
    if (newOpenTableRows.includes(user)) {
      newOpenTableRows.splice(newOpenTableRows.indexOf(user), 1);
    } else {
      newOpenTableRows.push(user);
    }
    setOpenTableRows(newOpenTableRows);
  };
  const filterbarProps = {
    searchbar: {
      shown: true,
      resultsLength: filteredData.length,
      type: 'text',
      placeholder: 'entity-details/search-for',
      onSearchChange: () => {
        setSearchAndFilter({
          ...searchAndFilter,
          search: event.target.value,
        });
      },
    },
    clearSearchAndFilter: () => setSearchAndFilter(defaultSearchAndFilter),
  };

  return (
    <div className="registered-users-container container">
      <SubHeader>
        <div className="column">
          <NavigateTabs routes={routes} />
        </div>
        <div className="column">
          <ViewOptions options={['toggleFilterBar']} />
        </div>
      </SubHeader>
      <Filterbar {...filterbarProps} />
      <ResponsiveTable
        headerColumns={headerColums}
        data={filteredData}
        renderRow={(user, options) => (
          <div key={user._id} className="table-row">
            <div className="column col-4 main-color align-left bigger-padding">
              <TextWithTooltip textWithOverflow={user.profile.name} />
            </div>
            <div className="column col-3 main-color">
              <TextWithTooltip textWithOverflow={user.email} />
            </div>
            <div className="column">{getDateInLocalFormat(user.created)}</div>
            <div className="column col-2 consider">
              <div
                onClick={() => openPopup({ isOpen: true, type: 'approve', selectedUser: user._id })}
                className="approve"
              >
                <Svg src={approve} otherColor={'#00d38a'} />
                <Translate value="form/approve" />
              </div>
              <div
                onClick={() => openPopup({ isOpen: true, type: 'delete', selectedUser: user._id })}
                className="delete"
              >
                <Svg src={del} otherColor={'#f02b2b'} />
                <Translate value="form/delete" />
              </div>
            </div>
            <div className="column half more" onClick={() => openTableRow(user._id)}>
              <Svg src={openTableRows.includes(user._id) ? arrow_up : arrow_down} />
            </div>
            <div className="break"></div>
            {openTableRows.includes(user._id) && (
              <div className="column user-description">
                <div className="inner-container" style={{ width: options.containerWidth }}>
                  <div>
                    <Translate value="registered-clients/table/details" />
                    {user.profile.claim}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      ></ResponsiveTable>
      <Popup
        popupShown={popup.isOpen && popup.type === 'approve'}
        popupTitle={I18n.t('registered-clients/popup/approve-title')}
        okHandler={approveUser}
        cancelHandler={() => setPopup({ ...popup, isOpen: false })}
      >
        <Translate value="registered-clients/popup/approve-subtitle" />
      </Popup>
      <Popup
        popupShown={popup.isOpen && popup.type === 'delete'}
        popupTitle={I18n.t('registered-clients/popup/delete-title')}
        okHandler={deleteUser}
        cancelHandler={() => setPopup({ ...popup, isOpen: false })}
      >
        <Translate value="registered-clients/popup/delete-subtitle" />
      </Popup>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default Authorization(connect(mapStateToProps, mapDispatchToProps)(RegisteredUsers), ['list-waiting-users']);

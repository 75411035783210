import React from 'react';
import { connect } from 'react-redux';

import TextWithTooltip from '../TextWithTooltip';
import WrappedLink from '../WrappedLink';

import { translate, getStage } from '../../helper/functions';

import './StageList.scss';

class StageList extends React.Component {
  render() {
    const { filteredStageMeta, stages, stage, submission, stageMeta, navigatable = false } = this.props;
    const { isBlocked, stageHistory, completed } = submission;

    if (!submission || !stageMeta.length) {
      return null;
    }

    const blockedPosition = filteredStageMeta.findIndex((sm) => sm.stageId === isBlocked);

    return (
      <div className="stage-list">
        <ul>
          {filteredStageMeta.map((sm, i) => {
            const title = sm.hidden ? `[${translate(stages[sm.stageId].label)}]` : translate(stages[sm.stageId].label);
            if (sm.stageId === stage._id) {
              return (
                <li key={i} className="active current">
                  <a>
                    <TextWithTooltip textWithOverflow={title} />
                  </a>
                </li>
              );
            } else if (
              completed &&
              (stages[sm.stageId].type === 'summary' || stages[sm.stageId].type === 'document-library')
            ) {
              return (
                <li className="active" key={i}>
                  <WrappedLink to={`/client-management/${this.props.params.submissionId}/${sm.stageId}`}>
                    <TextWithTooltip textWithOverflow={title} />
                  </WrappedLink>
                </li>
              );
            } else if (!stageHistory.includes(sm.stageId) || (isBlocked && blockedPosition < i)) {
              return (
                <li className="inactive" key={i}>
                  <TextWithTooltip textWithOverflow={title} />
                </li>
              );
            } else {
              return (
                <li key={i} className="active">
                  {navigatable ? (
                    <WrappedLink to={`/client-management/${this.props.params.submissionId}/${sm.stageId}`}>
                      <TextWithTooltip textWithOverflow={translate(stages[sm.stageId].label)} />
                    </WrappedLink>
                  ) : (
                    <TextWithTooltip textWithOverflow={title} />
                  )}
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const submission = state.submissions[ownProps.params.submissionId];
  const stageMeta =
    submission && state.modules.modules[submission.moduleId] && state.modules.modules[submission.moduleId].stageMeta
      ? state.modules.modules[submission.moduleId].stageMeta
      : [];
  const stage = getStage(state.modules.stages, stageMeta, ownProps.params.stageId, state.auth.userData.isLimited);

  return {
    stages: state.modules.stages,
    stageMeta,
    stage,
    submission,
  };
}

export default connect(mapStateToProps, null)(StageList);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';

import InputWithError from '../InputWithError';
import ReactSelectWrapper from '../ReactSelectWrapper';

import { formValidator, translate } from '../../helper/functions';
import * as errorActions from '../../actions/error';

import './AdminForm.scss';

const AdminForm = (props) => {
  const { token, setFormValid, user, onChange, throwError, emailInUseError } = props;
  const { name, email, claim, role } = user;
  const [errors, setErrors] = useState({
    name: null,
    email: null,
    role: null,
  });
  const [roles, setRoles] = useState({
    data: [],
    fetched: false,
  });

  useEffect(() => {
    if (emailInUseError) {
      setErrors({ ...errors, email: I18n.t('registration/email-already-exists') });
    } else {
      setErrors({ ...errors, email: null });
    }
  }, [emailInUseError]);

  useEffect(() => {
    const isValid = Object.values(errors).every((e) => !Boolean(e)) && Object.keys(errors).every((e) => user[e] !== '');
    setFormValid(isValid);
    if (!roles.fetched) {
      getRoles();
      setFormValid(false);
    }
  }, [errors, user]);

  const onChangeWithValidation = (e) => {
    if (Object.keys(errors).includes(e.target.name)) {
      setErrors({
        ...errors,
        ...formValidator(e.target.name, e.target.value),
      });
    }
    onChange(e);
  };

  const getRoles = async () => {
    const headers = {
      Authorization: 'Bearer ' + token,
    };
    try {
      const response = await axios.get('/auth/acl-data', { headers });
      setRoles({
        data: Object.values(response.data.roles)
          .filter((r) => r.roleType === 'client-admin')
          .map((r) => ({ value: r._id, label: translate(r.name) })),
        fetched: true,
      });
    } catch (e) {
      throwError(e);
    }
  };

  return (
    <div className="admin-editing form-container">
      <InputWithError
        name="name"
        type="text"
        placeholder={I18n.t('users-table/table-header-1')}
        value={name}
        onChange={onChangeWithValidation}
        errorMsg={errors.name}
      />
      <InputWithError
        name="email"
        type="email"
        disabled={true}
        placeholder={I18n.t('users-table/table-header-2')}
        value={email}
        onChange={onChangeWithValidation}
        errorMsg={errors.email}
      />
      <InputWithError
        name="claim"
        type="text"
        placeholder={I18n.t('users-table/table-header-6')}
        value={claim}
        onChange={onChangeWithValidation}
      />
      <div className="dropdown-container">
        <ReactSelectWrapper
          onChange={(e) => {
            onChangeWithValidation({
              target: {
                name: 'role',
                value: e.value,
              },
            });
          }}
          placeholder={I18n.t('user-management/admins/select-role')}
          options={roles.data}
          value={
            role
              ? {
                  value: role,
                  label: roles.fetched ? roles.data.find((r) => r.value === role).label : null,
                }
              : null
          }
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminForm);

import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { I18n } from 'react-redux-i18n';

import ModuleSelectorPopup from '../ModuleSelectorPopup';
import { createSubmissionCallback } from '../ModuleSelectorPopup/module-selector-logic';
import { translate, getDateInLocalFormat } from '../../helper/functions';

import './WelcomePageOfficer.scss';

export default function WelcomePageOfficer() {
  const userRole = useSelector((state) => state.auth.userData.role);

  const history = useHistory();
  const modules = useSelector((state) => state.modules.modules);

  const handleWelcomeClick = useCallback(() => {
    const autoCreateModules = Object.values(modules).filter((m) => m.autoCreateEntity);

    if (autoCreateModules.length === 1) {
      const autoCreateModule = autoCreateModules[0];
      popupSetter({
        isPopupOpen: true,
        moduleId: autoCreateModule._id,
        submissionName: translate(modules[autoCreateModule._id].label) + ' ' + getDateInLocalFormat(new Date()),
      });
    } else {
      history.push('/create-new-entity');
    }
  }, [modules]);

  const [popup, popupSetter] = useState({
    isPopupOpen: false,
    moduleId: null,
    submissionName: '',
  });

  const createSubmission = createSubmissionCallback(popup, modules, null, popupSetter);

  if (userRole === 'lawyer' && history.location.pathname === '/') {
    return (
      <div className="welcome-page-container">
        <div className="welcome-page-wrapper">
          <div className="welcome-page-title">{I18n.t('welcome-page-officer/headline')}</div>
          <div className="welcome-page-text">
            <div dangerouslySetInnerHTML={{ __html: I18n.t('welcome-page-officer/text') }} />
          </div>
          <div className="button-container" style={{ margin: '30px 0' }}>
            <button onClick={handleWelcomeClick}>{I18n.t('welcome-page-officer/button')}</button>
          </div>
        </div>
        <ModuleSelectorPopup popup={popup} popupSetter={popupSetter} createSubmission={createSubmission} />
      </div>
    );
  }

  return null;
}

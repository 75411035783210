import { useCallback, useMemo } from 'react';
import moment from 'moment';

import { translate } from '../../../helper/functions';

export function useGetPlaceholder(placeholder, validationRules) {
  return useMemo(() => {
    return translate(placeholder) + (validationRules && validationRules.required ? '*' : '');
  }, [placeholder, validationRules]);
}

export function useESignatureHandleChange(value, onChange) {
  return useCallback(() => {});
}

export function useHandleChange(id, groupId, onChange) {
  return useCallback((e) => onChange(id, groupId, e.target.value), [id, onChange]);
}

export function useHandleChangeForDate(id, groupId, onChange) {
  return useCallback(
    (e) => {
      const strDate = moment(e).format('MM-DD-YYYY');
      const value = moment(strDate + ' +0000', 'MM-DD-YYYY Z').toISOString();

      onChange(id, groupId, value);
    },
    [id, onChange]
  );
}

export function useHandleChangeForCheckbox(id, groupId, onChange, originalValue) {
  return useCallback(
    (e) => {
      let value;
      if (originalValue) {
        if (originalValue.map((v) => v.id).includes(e.id)) {
          value = originalValue.filter((v) => v.id !== e.id);
        } else {
          value = [...originalValue, e];
        }
      } else {
        value = [e];
      }

      onChange(id, groupId, value);
    },
    [id, onChange]
  );
}

export function useHandleChangeForRadio(id, groupId, onChange) {
  return useCallback((e) => onChange(id, groupId, e), [id, onChange]);
}

import React from 'react';
import PropTypes from 'prop-types';

import TextWithTooltip from '../../../components/TextWithTooltip';
import { useIsBigScreen } from '../../../helper/functions';

import boot from '../../../assets/upsoo-boot.svg';
import bus from '../../../assets/upsoo-bus.svg';
import hand from '../../../assets/upsoo-hand.svg';
import health from '../../../assets/upsoo-health.svg';

function Benefits({ benefits }) {
  const isBigScreen = useIsBigScreen();

  return (
    <div className="benefits">
      {benefits.map((benefit, index) => {
        const convertedBenefit = getConvertedBenefit(benefit);

        return (
          <span className="benefit" key={index}>
            {isBigScreen ? (
              <TextWithTooltip
                textWithOverflow={<img src={convertedBenefit.src} alt={convertedBenefit.title} />}
                tooltipContent={convertedBenefit.title}
                showTooltipIfTextNotOverflow={true}
              />
            ) : (
              <>
                <img src={convertedBenefit.src} alt="" />
                <span className="description">{convertedBenefit.text}</span>
              </>
            )}
          </span>
        );
      })}
    </div>
  );
}

Benefits.propTypes = {
  benefits: PropTypes.array.isRequired,
};

export default Benefits;

function getConvertedBenefit(benefit) {
  switch (benefit) {
    case 'boot':
      return { src: boot, text: 'Text for boot image', title: 'Safety boots needed' };
    case 'bus':
      return { src: bus, text: 'Text for bus image', title: 'Bus transport' };
    case 'hand':
      return { src: hand, text: 'Text for hand image', title: 'Wash your hands' };
    case 'health':
      return { src: health, text: 'Text for health image', title: 'Health insurance' };
  }
}

import React from 'react';
import SimpleBar from 'simplebar-react';

import SummaryDataCheck from '../SummaryDataCheck/SummaryDataCheck';
import Benefits from './Benefits';

const submissionData = {
  url:
    'https://www.nahb.org/-/media/NAHB/news-and-economics/images/housing-economics/news-economics-safety-1600x900.jpg?w=615&hash=4075C923F315DD6BED6450C14051DE70',
  position: 'Mezőgazdasági raktáros kisegítő munkatárs',
  location: '1123 Sed, location line 125 B.',
  netSallary: '280.000 Ft',
  intervallum: 'Hónap',
  type: 'Határozott időtartamú, project alapú',
  hours: 'Teljes munkaidő, heti 40 óra',
  assigment: 'Beosztás',
  requirements: [
    'Sikerorientáltság',
    'Csapatjátékos',
    'Megbízhatóság',
    'Teherbírás',
    'Sikerorientáltság',
    'Csapatjátékos',
    'Megbízhatóság',
    'Teherbírás',
  ],
  benefits: ['boot', 'bus', 'hand', 'health'],
  details:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
};

function SubmissionSummary() {
  return (
    <SimpleBar style={{ maxHeight: '100%' }}>
      <div className="upsoo-card submission-summary">
        <div className="image-container">
          <img src={submissionData.url} alt="" />
        </div>
        <SummaryDataCheck header={<Header />}>
          <tr>
            <td>Helyszín:</td>
            <td>{submissionData.location}</td>
          </tr>
          <tr>
            <td>Nettó munkabér:</td>
            <td>
              <div className="net-sallary">
                {submissionData.netSallary} / {submissionData.intervallum}
              </div>
            </td>
          </tr>
          <tr>
            <td>Munkaszerződés típusa:</td>
            <td>{submissionData.type}</td>
          </tr>
          <tr>
            <td>Munkaidő beosztás:</td>
            <td>{submissionData.hours}</td>
          </tr>
          <tr>
            <td>Elvárások:</td>
            <td>
              <ul>
                {submissionData.requirements.map((requirement, index) => (
                  <li key={index}>{requirement}</li>
                ))}
              </ul>
            </td>
          </tr>
          <tr>
            <td>Juttatások:</td>
            <td>
              <Benefits benefits={submissionData.benefits} />
            </td>
          </tr>
        </SummaryDataCheck>
        <div className="details">
          <h1>Hirdetés részletei</h1>
          <div>{submissionData.details}</div>
        </div>
      </div>
    </SimpleBar>
  );
}

export default SubmissionSummary;

function Header() {
  return (
    <thead>
      <tr className="main">
        <td>Munkakör:</td>
        <td>
          <div>{submissionData.position}</div>
        </td>
      </tr>
    </thead>
  );
}

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { I18n } from 'react-redux-i18n';

import Comment from '../Comment';
import StatusSelector from '../StatusSelector';
import Svg from '../Svg';
import { ResponsiveTableWithGroupingDesktop, ResponsiveTableWithGroupingMobile } from '../ResponsiveTable';
import Tile from '../Tile';

import { translate, useIsBigScreen } from '../../helper/functions';
import { getGroupedDocuments, addLabelsToGroupedDocuments } from '../DocumentLibrary/document-library-logic';

import './AdminDocumentTable.scss';

import eye from '../../assets/eye.svg';
import eye_no from '../../assets/eye_no.svg';
import document from '../../assets/document_icon.svg';
import upload from '../../assets/upload_icon.svg';
import download from '../../assets/download_icon.svg';
import delete_icon from '../../assets/delete_icon.svg';
import loader from '../../assets/loader_circle_icon.svg';
import error from '../../assets/error.svg';

class AdminDocumentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpenByDocId: '',
    };
    this.headerColumns = [
      { name: I18n.t('document-library/table-header-1'), class: 'document-name', sortName: 'label' },
      { name: I18n.t('document-library/table-header-2'), class: 'document-icon', sortName: 'generatedFilePath' },
      { name: I18n.t('document-library/table-header-3'), class: 'document-icon', sortName: 'dataFlow' },
      { name: I18n.t('document-library/table-header-6'), class: 'document-status', sortName: 'state' },
      { name: I18n.t('document-library/table-header-8'), class: 'activity' },
      { name: I18n.t('document-library/table-header-9'), class: 'message' },
    ];
  }

  clickOnDropdown = (docId) => {
    let newDropdownToOpen = this.state.dropdownOpenByDocId === docId ? '' : docId;
    this.setState((state) => {
      return {
        dropdownOpenByDocId: newDropdownToOpen,
      };
    });
  };

  handleUploadClick = (e) => {
    this.setState({ dropdownOpenByDocId: '' });
    this.props.uploadDocument(e);
  };

  filterDocuments = (documents) => {
    const search = this.props.search;
    const regex = /<(.*?)>/g;
    return Object.values(documents).map((documentGroup) => ({
      ...documentGroup,
      documents: documentGroup.documents.filter((dG) =>
        translate(dG.label).replace(regex, '').toLowerCase().includes(search.toLowerCase())
      ),
    }));
  };

  handleSelectChange = (event, docId) => {
    this.props.setDocumentSettings('state', event.target.dataset.state, docId);
  };

  render() {
    const {
      documents,
      downloadDocument,
      ableToUploadAndDelete,
      setDocumentSettings,
      openDeletePopup,
      submissionId,
    } = this.props;

    const grouppedDocuments = addLabelsToGroupedDocuments(
      getGroupedDocuments(documents),
      this.props.submission.data,
      this.props.groups
    );
    const filterDocuments = this.filterDocuments(grouppedDocuments).sort((a, b) => a.order - b.order);

    return (
      <ResponsiveTableWithGrouping
        headerColumns={this.headerColumns}
        filterDocuments={filterDocuments}
        handleUploadClick={this.handleUploadClick}
        submissionCompleted={this.props.submission}
        handleSelectChange={this.handleSelectChange}
        submissionId={submissionId}
        ableToUploadAndDelete={ableToUploadAndDelete}
        setDocumentSettings={setDocumentSettings}
        downloadDocument={downloadDocument}
        openDeletePopup={openDeletePopup}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    groups: state.modules.groups,
  };
}

AdminDocumentTable.propTypes = {
  documents: PropTypes.array,
  uploadDocument: PropTypes.func,
  downloadDocument: PropTypes.func,
  deleteDocument: PropTypes.func,
  descriptionShown: PropTypes.bool,
  search: PropTypes.string,
  setDocumentSettings: PropTypes.func,
};

export default connect(mapStateToProps, null)(AdminDocumentTable);

function ResponsiveTableWithGrouping({
  headerColumns,
  filterDocuments,
  handleUploadClick,
  submission,
  handleSelectChange,
  submissionId,
  ableToUploadAndDelete,
  setDocumentSettings,
  downloadDocument,
  openDeletePopup,
}) {
  const isBigScreen = useIsBigScreen();

  if (isBigScreen) {
    return (
      <ResponsiveTableWithGroupingDesktop
        className="documents-admin-table-container"
        headerColumns={headerColumns}
        data={filterDocuments}
        defaultSort="label"
        isAscending={true}
        renderRow={(d, options) => {
          return (
            <div className={'table-row'} key={d._id}>
              <div className={'column document-name sticky ' + d.state}>
                <div className="img-container eye">
                  <Svg
                    src={d.hidden ? eye_no : eye}
                    hasHover={true}
                    onClick={() => setDocumentSettings('hidden', d.hidden ? false : true, d._id)}
                  />
                </div>
                <div dangerouslySetInnerHTML={{ __html: translate(d.label) }} />
              </div>
              <div className="column document-icon">
                {d.dataFlow !== 'upload' &&
                  (d.generatedFilePath ? (
                    <Svg onClick={() => downloadDocument(d)} className={'active'} src={download} hasHover={true} />
                  ) : d.dataFlow === 'error' ? (
                    <Svg className="error" src={error} hasHover={true} />
                  ) : (
                    <Svg className={'download rotating'} src={loader} hasHover={true} />
                  ))}
              </div>
              <div
                className={`column document-icon ${
                  d.dataFlow === 'download' || d.dataFlow === 'error' || (d.readonly && !d.filePath) ? 'inactive' : ''
                }`}
              >
                {d.filePath ? (
                  <Svg className="doc-icon" src={document} hasHover={true} onClick={() => downloadDocument(d, true)} />
                ) : d.uploading ? (
                  <Svg src={loader} className="rotating" hasHover={true} />
                ) : ableToUploadAndDelete ? (
                  <label htmlFor={'file-upload' + options.index + d._id} className="custom-file-upload">
                    <Svg src={upload} hasHover={true} />
                    <input
                      id={'file-upload' + options.index + d._id}
                      type="file"
                      name={d._id}
                      onChange={(e) => handleUploadClick(e)}
                    />
                  </label>
                ) : null}
                {d.filePath && ableToUploadAndDelete && !d.readonly && (
                  <img onClick={() => openDeletePopup(d)} className="delete-icon" src={delete_icon} />
                )}
              </div>
              <div className="column document-status">
                <div className="status">
                  <StatusSelector
                    isDisabled={submission && submission.completed}
                    state={d.state}
                    handleSelectChange={(event) => handleSelectChange(event, d._id)}
                  />
                </div>
              </div>
              <div className="column activity switcher">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={d.readonly ? true : false}
                    onChange={() => setDocumentSettings('readonly', d.readonly ? false : true, d._id)}
                  />
                  <span className={`slider round ${d.readonly ? 'off' : 'on'}`}></span>
                </label>
                <Translate
                  className={`switcher-status ${d.readonly ? 'off' : 'on'}`}
                  value={d.readonly ? 'datacheck/inactive' : 'datacheck/active'}
                />
              </div>
              <div className="column message">
                {!d.hidden && (
                  <Comment
                    comment={d.comment}
                    submissionId={submissionId}
                    target={{ documentId: d._id }}
                    targetName={translate(d.label)}
                    targetUrl={'/documents/comment'}
                  />
                )}
              </div>
            </div>
          );
        }}
      ></ResponsiveTableWithGroupingDesktop>
    );
  } else {
    return (
      <ResponsiveTableWithGroupingMobile
        data={filterDocuments}
        renderRow={(d, options) => {
          return (
            <Tile
              key={d._id}
              d={d}
              headerColumns={headerColumns}
              renderTitle={() => renderTitle(d, setDocumentSettings)}
              renderTile={renderTile}
              ableToUploadAndDelete={ableToUploadAndDelete}
              submission={submission}
              submissionId={submissionId}
              options={options}
              downloadDocument={downloadDocument}
              openDeletePopup={openDeletePopup}
            />
          );
        }}
      />
    );
  }
}

function renderTitle(d, setDocumentSettings) {
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: translate(d.label) }} />
      <div className="img-container eye">
        <Svg
          src={d.hidden ? eye_no : eye}
          hasHover={true}
          onClick={() => setDocumentSettings('hidden', d.hidden ? false : true, d._id)}
        />
      </div>
    </>
  );
}

function renderTile(
  d,
  tc,
  ableToUploadAndDelete,
  submission,
  submissionId,
  options,
  uploadDocument,
  downloadDocument,
  openDeletePopup
) {
  let row;

  switch (tc.name) {
    case 'Download':
      row = (
        <>
          <div>Download:</div>
          <div className="column document-icon">
            {d.dataFlow !== 'upload' &&
              (d.generatedFilePath ? (
                <Svg onClick={() => downloadDocument(d)} className={'active'} src={download} hasHover={true} />
              ) : d.dataFlow === 'error' ? (
                <Svg className="error" src={error} hasHover={true} />
              ) : (
                <Svg className={'download rotating'} src={loader} hasHover={true} />
              ))}
          </div>
        </>
      );
      break;
    case 'Upload':
      row = (
        <div>
          Upload:
          <div
            className={`column document-icon ${d.dataFlow === 'download' || d.dataFlow === 'error' ? 'inactive' : ''}`}
          >
            {d.filePath ? (
              <Svg className="doc-icon" src={document} hasHover={true} onClick={() => downloadDocument(d, true)} />
            ) : d.uploading ? (
              <Svg src={loader} className="rotating" hasHover={true} />
            ) : ableToUploadAndDelete ? (
              <label htmlFor={'file-upload' + options.index + d._id} className="custom-file-upload">
                <Svg src={upload} hasHover={true} />
                <input
                  id={'file-upload' + options.index + d._id}
                  type="file"
                  name={d._id}
                  onChange={(e) => handleUploadClick(e)}
                />
              </label>
            ) : null}
            {d.filePath && ableToUploadAndDelete && (
              <img onClick={() => openDeletePopup(d)} className="delete-icon" src={delete_icon} />
            )}
          </div>
        </div>
      );
      break;
    case 'Status':
      row = (
        <div>
          Status:
          <div className="column document-status">
            <div className="status">
              <StatusSelector
                isDisabled={submission && submission.completed}
                state={d.state}
                handleSelectChange={(event) => handleSelectChange(event, d._id)}
              />
            </div>
          </div>
        </div>
      );
      break;
    case 'Activity':
      row = (
        <div>
          Activity:
          <div className="column activity switcher">
            <label className="switch">
              <input
                type="checkbox"
                checked={d.readonly ? true : false}
                onChange={() => setDocumentSettings('readonly', d.readonly ? false : true, d._id)}
              />
              <span className={`slider round ${d.readonly ? 'off' : 'on'}`}></span>
            </label>
            <Translate
              className={`switcher-status ${d.readonly ? 'off' : 'on'}`}
              value={d.readonly ? 'datacheck/inactive' : 'datacheck/active'}
            />
          </div>
        </div>
      );
      break;
    case 'Message':
      row = (
        <div>
          Message:
          <div className="column message">
            {!d.hidden && (
              <Comment
                comment={d.comment}
                submissionId={submissionId}
                target={{ documentId: d._id }}
                targetName={translate(d.label)}
                targetUrl={'/documents/comment'}
              />
            )}
          </div>
        </div>
      );
      break;
  }

  return <div key={tc.name}>{row}</div>;
}

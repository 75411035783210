import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-redux-i18n';

import './Popup.scss';

import error from '../../assets/error.svg';
import success from '../../assets/success.svg';

const Popup = (props) => {
  const {
    popupShown,
    popupImg,
    popupTitle,
    popupSubtitle,
    okHandler,
    okDisabled,
    cancelHandler,
    children,
    noHeader,
    noButtons,
  } = props;
  const img = {
    error: error,
    success: success,
  };
  const cancelButton = (
    <button className="cancel" onClick={cancelHandler}>
      {' '}
      <Translate value="form/cancel" />{' '}
    </button>
  );
  const okButton = (
    <button disabled={okDisabled} onClick={okHandler}>
      <Translate value="form/ok" />
    </button>
  );

  if (!popupShown) return null;

  return (
    <div id="popup-wrapper" onClick={cancelHandler ? cancelHandler : okHandler}>
      <div className="popup-container" onClick={(e) => e.stopPropagation()}>
        {!noHeader && (
          <div className="popup-header">
            {popupImg && <img src={img[popupImg]} />}
            {popupTitle && <h1>{popupTitle}</h1>}
            {popupSubtitle && <div className="subtitle">{popupSubtitle}</div>}
          </div>
        )}
        <div className="popup-content-container">{children}</div>
        {!noButtons && (
          <div className="button-container">
            {cancelHandler && cancelButton}
            {okHandler && okButton}
          </div>
        )}
      </div>
    </div>
  );
};

Popup.propTypes = {
  popupShown: PropTypes.bool,
  popupType: PropTypes.string,
  popupMsg: PropTypes.string,
  popupTitle: PropTypes.string,
  okHandler: PropTypes.func,
  okDisabled: PropTypes.bool,
  cancelHandler: PropTypes.func,
  children: PropTypes.node,
  noHeader: PropTypes.bool,
  noButtons: PropTypes.bool,
};

Popup.defaultProps = {
  noHeader: false,
  noButtons: false,
};

export default Popup;

export function SimplePopup({ popupShown, children }) {
  if (!popupShown) return null;

  return (
    <div id="popup-wrapper">
      <div className="popup-container" onClick={(e) => e.stopPropagation()} style={{ backgroundColor: '#beacc4' }}>
        <div className="simple-popup-content-container">{children}</div>
      </div>
    </div>
  );
}

SimplePopup.propTypes = {
  popupShown: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

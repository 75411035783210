import { I18n } from 'react-redux-i18n';

import { transformValue, translate, flattenArrayByValue } from '../../helper/functions';

export function getGroupedDocuments(documents) {
  return documents.reduce((acc, d) => {
    if (d.resultId) {
      if (!Array.isArray(acc[d.resultId])) {
        acc[d.resultId] = [];
      }
      acc[d.resultId].push(d);
    } else {
      if (!Array.isArray(acc.rest)) {
        acc.rest = [];
      }
      acc.rest.push(d);
    }

    return acc;
  }, {});
}

const documentLibraryElementsOrder = [
  { re: /Company Information/, order: 1 },
  { re: /Ultimate Beneficial Owner - holding.*/, order: 2 },
  { re: /Individual Director/, order: 3 },
  { re: /Corporate Director/, order: 4 },
  { re: /Ultimate Beneficial Owner Details/, order: 5 },
  { re: /Account Operators - Authorised Signatories/, order: 6 },
];

export function addLabelsToGroupedDocuments(groupedDocuments, dataset, groups) {
  const keys = Object.keys(groupedDocuments);
  const result = {};

  keys.forEach((k) => {
    result[k] = {};
    result[k].documents = groupedDocuments[k];

    const foundData = dataset.find((e) => e.resultId === k);

    if (foundData) {
      const group = groups[foundData.groupId];

      if (group) {
        result[k].title = translate(group.label);
        if (group.label.en_US) {
          const foundOrder = documentLibraryElementsOrder.find((de) => {
            return de.re.test(group.label.en_US);
          });

          if (foundOrder) {
            result[k].order = foundOrder.order;
          } else {
            result[k].order = 100;
          }
        }

        if (group.referenceNameTemplate) {
          const foundDataSet = dataset.find((d) => d.groupId === group._id && d.resultId === k);

          if (foundDataSet) {
            result[k].referenceTitle = transformValue(group.referenceNameTemplate, foundDataSet.values);
          }
        }
      }
    } else {
      result[k].title = [I18n.t('document-library/general-documents')];
      result[k].order = 0;
    }
  });

  return result;
}

export function filterHiddenDocumentsIfNeeded(documents, filterOn) {
  if (filterOn) {
    return documents.filter((d) => !d.hidden);
  }

  return documents;
}

import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { translate, getNiceValueFormat, valuesHasError, filterBySearch } from '../../../helper/functions';
import Svg from '../../Svg/Svg';
import Field, { Input } from './PredefinedField';
import Separator from '../../Separator';

import { mapRows } from './predefined-group-result-logic';
import { sortByPosition } from '../PredefinedGroupDataSummary/data-summary-logic';

import editIcon from '../../../assets/edit.svg';
import eye from '../../../assets/eye.svg';
import eye_no from '../../../assets/eye_no.svg';
import cancelIcon from '../../../assets/cancel.svg';
import saveIcon from '../../../assets/save.svg';

const PredefinedGroupResult = (props) => {
  const { _id, label, visible, predefinedGroupData, mode, disabled, search } = props;
  const formEditMode = predefinedGroupData.model.mode;
  const rows = useMemo(() => mapRows(props.values), [props.values]);
  const groupHasErrors = useMemo(() => valuesHasError(rows), [props.values]);

  return (
    <div role="predefined-group-result" className={`admin-data-section-container ${disabled ? 'disabled' : ''}`}>
      <Header
        id={_id}
        isGroupVisible={visible}
        groupLabel={label}
        activate={predefinedGroupData.activate}
        deactivate={predefinedGroupData.deactivate}
        mode={mode}
      >
        <HeaderButtons
          id={props._id}
          cancel={predefinedGroupData.cancel}
          save={() => predefinedGroupData.save(_id)}
          edit={predefinedGroupData.startEdit}
          mode={mode}
          formEditMode={formEditMode}
          disabled={disabled}
          groupHasErrors={groupHasErrors}
        />
      </Header>
      <Rows
        mode={mode}
        rows={rows}
        groupId={props._id}
        predefinedGroupData={props.predefinedGroupData}
        search={search}
      />
    </div>
  );
};

PredefinedGroupResult.propTypes = {};

export default PredefinedGroupResult;

function Header({ id, isGroupVisible, groupLabel, children, activate, deactivate, mode }) {
  const handleEyeClick = () => (isGroupVisible ? deactivate(id) : activate(id));

  return (
    <div className="group-header">
      <div className="group-header-title" style={{ width: 'calc(100% - 40px)' }}>
        {renderEye(mode, isGroupVisible, handleEyeClick)}

        <span>{groupLabel}</span>
      </div>
      {children}
    </div>
  );
}

function renderEye(mode, isGroupVisible, handleEyeClick) {
  if (mode === 'edit') {
    return null;
  }

  return (
    <div className={`img-container eye ${isGroupVisible ? '' : 'inactive'}`}>
      <Svg src={isGroupVisible ? eye : eye_no} hasHover={true} onClick={handleEyeClick} />
    </div>
  );
}

Header.propTypes = {
  isGroupVisible: PropTypes.bool.isRequired,
  groupLabel: PropTypes.string.isRequired,
};

function HeaderButtons({ id, cancel, save, edit, mode, disabled, formEditMode, groupHasErrors }) {
  const handleEdit = useCallback(() => edit(id), [edit]);
  const handleCancel = useCallback(() => cancel(id), [cancel]);

  switch (mode) {
    case 'edit':
      return (
        <>
          <div className="img-container edit">
            <Svg src={cancelIcon} hasHover={true} onClick={handleCancel} />
          </div>
          <div className="img-container edit">
            <Svg
              src={saveIcon}
              hasHover={!groupHasErrors}
              otherColor={groupHasErrors ? '#f2f4f4' : undefined}
              disabled
              onClick={save}
            />
          </div>
        </>
      );
    case 'readonly':
    default:
      if (disabled || formEditMode !== 'readonly') return null;
      return (
        <div className="img-container edit">
          <Svg role="edit-button" src={editIcon} hasHover={true} onClick={handleEdit} />
        </div>
      );
  }
}

HeaderButtons.propTypes = {
  mode: PropTypes.string.isRequired,
};

function Rows({ groupId, mode, rows, predefinedGroupData, search }) {
  switch (mode) {
    case 'edit':
      return <RowsEditing rows={rows} groupId={groupId} onChange={predefinedGroupData.onChange} />;
    case 'readonly':
    default:
      return <RowsReadonly rows={rows} search={search} />;
  }
}

const rowPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
});

const rowsPropTypes = PropTypes.arrayOf(rowPropTypes).isRequired;

Rows.propTypes = {
  mode: PropTypes.string.isRequired,
  rows: rowsPropTypes,
};

function RowsReadonly({ rows, search }) {
  return (
    <div role="readonly-rows" className="content-rows">
      {rows
        .filter((row) => filterBySearch(row, search))
        .sort(sortByPosition)
        .map((row) => (
          <RowReadonly key={row.id} {...row} />
        ))}
    </div>
  );
}

RowsReadonly.propTypes = {
  rows: rowsPropTypes,
};

function RowReadonly(props) {
  const { label, value } = props;

  if (props.type === 'separator') {
    return <Separator {...props} />;
  }

  return (
    <div className="row group-wrapper-with-separator">
      <div className="cell"></div>
      <div className="cell label">{translate(label)}</div>
      <div className="cell">{getNiceValueFormat(props, value)}</div>
    </div>
  );
}

function RowsEditing({ rows, groupId, onChange }) {
  return (
    <div className="form-container edit-group-container">
      <div className="group-type-container">
        <div className="group-wrapper">
          <div className="group opened">
            <div role="editable-rows">
              {rows.map((row) => (
                <RowEditing key={row.id} row={row} groupId={groupId} onChange={onChange} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

RowsEditing.propTypes = {
  rows: rowsPropTypes,
};

function RowEditing({ row, groupId, onChange }) {
  const referenceCollections = useSelector((state) => state.modules.referenceCollections);

  if (row.type === 'separator') {
    return <Separator {...row} />;
  }

  return (
    <Field errorMsg={row.errorMsg} description={row.description} error={row.error} valueChanged={row.valueChanged}>
      <Input {...row} groupId={groupId} onChange={onChange} referenceCollections={referenceCollections} />
    </Field>
  );
}

RowEditing.propTypes = {
  row: rowPropTypes,
};

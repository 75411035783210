import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { I18n, Translate } from 'react-redux-i18n';

import SubHeader from '../SubHeader';
import ResponsiveTable from '../ResponsiveTable';
import Authorization from '../Authorization';
import TextWithTooltip from '../TextWithTooltip';
import Filterbar from '../Filterbar';
import NavigateTabs from '../NavigateTabs';
import ViewOptions from '../ViewOptions';
import ResponsiveTileView from '../ResponsiveTileView';
import FilterPopup from '../FilterPopup';
import AuthComponentWrapper from '../AuthComponentWrapper';
import WrappedLink from '../WrappedLink';
import MobileHeaderLinks from '../MobileHeaderLinks';

import { getDateInLocalFormat, filterBy, useGetViewMode, useIsBigScreen } from '../../helper/functions';
import * as errorActions from '../../actions/error';

import './Users.scss';

const Users = (props) => {
  const componentName = 'users';
  const { token, routes, viewMode, throwError } = props;
  const [data, setData] = useState({ users: [], fetched: false });
  const [filteredData, setFilteredData] = useState([]);
  const headerColumns = [
    { name: I18n.t('users-table/table-header-1'), class: 'col-4', sortName: 'clientName' },
    { name: I18n.t('users-table/table-header-2'), class: 'col-3', sortName: 'email' },
    { name: I18n.t('users-table/table-header-3'), class: '', sortName: 'created' },
    { name: I18n.t('users-table/table-header-4'), class: '', sortName: 'lastLogin' },
  ];
  WrappedLink;
  const defaultSearchAndFilter = { search: '' };
  const [searchAndFilter, setSearchAndFilter] = useState(defaultSearchAndFilter);
  const vm = useGetViewMode(viewMode, componentName);
  const isBigScreen = useIsBigScreen();

  useEffect(() => {
    if (!data.fetched) {
      fetchData();
    }
    setFilteredData(
      data.users.filter((u) =>
        filterBy(
          u,
          [{ type: 'text', itemProperties: ['profile.name', 'email'], searchProperty: 'search' }],
          searchAndFilter
        )
      )
    );
  }, [data.fetched, searchAndFilter]);

  const fetchData = async () => {
    try {
      const response = await axios.get('/admin/users?loginEnabled=true&role=user', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      setData({
        users: response.data,
        fetched: true,
      });
    } catch (e) {
      throwError(e);
    }
  };
  const filterbarProps = {
    searchbar: {
      shown: true,
      resultsLength: filteredData.length,
      type: 'text',
      placeholder: 'entity-details/search-for',
      onSearchChange: () => {
        setSearchAndFilter({
          ...searchAndFilter,
          search: event.target.value,
        });
      },
    },
    clearSearchAndFilter: () => setSearchAndFilter(defaultSearchAndFilter),
  };

  return (
    <div className="users-container container">
      <UsersSubheader routes={routes} componentName={componentName} filterbarProps={filterbarProps} />
      {!isBigScreen && <SideBarNavigationMobile routes={routes} />}
      {vm === 'tile' ? (
        <ResponsiveTileView
          tableHeaderColumns={headerColumns.map((hc) => hc.sortName)}
          type="user"
          tileContent={filteredData}
          defaultSort="clientName"
        />
      ) : (
        <ResponsiveTable
          headerColumns={headerColumns}
          data={filteredData}
          renderRow={(user) => (
            <div key={user._id} className="table-row">
              <div className="column col-4 main-color align-left bigger-padding sticky">
                <TextWithTooltip textWithOverflow={user.profile.name} />
              </div>
              <div className="column col-3 main-color bigger-padding">
                <TextWithTooltip textWithOverflow={user.email} />
              </div>
              <div className="column">{getDateInLocalFormat(user.created)}</div>
              <div className="column">{getDateInLocalFormat(user.lastLogin)}</div>
            </div>
          )}
        ></ResponsiveTable>
      )}
      {isBigScreen ? null : <MobileHeaderLinks />}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    viewMode: state.layout.viewMode,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}
export default Authorization(connect(mapStateToProps, mapDispatchToProps)(Users), ['list-users']);

function UsersSubheader({ routes, componentName, filterbarProps }) {
  const isBigScreen = useIsBigScreen();
  const [filterbarIsOpen, setFilterbarIsOpen] = useState(false);

  if (isBigScreen) {
    return (
      <>
        <SubHeader>
          <div className="column">
            <NavigateTabs routes={routes} />
          </div>
          <div className="column">
            <ViewOptions componentName={componentName} />
          </div>
        </SubHeader>
        <Filterbar {...filterbarProps} />
      </>
    );
  } else {
    return (
      <>
        <div className="mobile-filter">
          <FilterPopup filterbarIsOpen={filterbarIsOpen} setFilterbarIsOpen={setFilterbarIsOpen} {...filterbarProps} />
        </div>
        <SubHeader>
          <div bp="flex full-width vertical-center" className="filter-bar">
            <div bp="fill">
              <Filterbar {...filterbarProps} />
            </div>
          </div>
        </SubHeader>
      </>
    );
  }
}

function SideBarNavigationMobile({ routes }) {
  if (routes) {
    return (
      <div bp="full-width flex" className="sidebar-navigation-mobile">
        {routes.map((r, index) => (
          <div key={index} bp="fill" className="menu-item">
            <AuthComponentWrapper allowedRules={r.rules}>
              <WrappedLink to={'/user-management' + r.path} navLink={true}>
                <Translate value={'navigation-tabs' + r.path} />
              </WrappedLink>
            </AuthComponentWrapper>
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
}

import React from 'react';

import { translate } from '../../helper/functions';

import './Tile.scss';

const Tile = ({
  d,
  headerColumns,
  renderTitle,
  renderTile,
  ableToUploadAndDelete,
  submission,
  submissionId,
  options,
  uploadDocument,
  downloadDocument,
  openDeletePopup,
  openTableRow,
}) => {
  return (
    <div key={d._id} className="tile">
      <div className="tile-header">
        <div className="tile-title">{renderTitle()}</div>
      </div>
      <div className="tile-content">
        {headerColumns.map((tc, index) =>
          renderTile(
            d,
            tc,
            ableToUploadAndDelete,
            submission,
            submissionId,
            options,
            uploadDocument,
            downloadDocument,
            openDeletePopup,
            openTableRow
          )
        )}
      </div>
    </div>
  );
};

export default Tile;

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function MessengerTextInput({ inputValue, isDisabled, handleAnswerChange, fieldId, handleFocus }) {
  const [textAreaRows, setTextAreaRows] = useState(1);
  const textAreaRef = useRef(null);
  useEffect(() => {
    setTextAreaRows(1);
  }, [fieldId]);

  useEffect(() => {
    document.querySelector('.msger-chat').lastElementChild.scrollIntoView({ behavior: 'auto', block: 'start' });
  }, [textAreaRows]);

  const recalculateTextAreaDimensions = () => {
    const textArea = textAreaRef.current;
    const style = window.getComputedStyle ? window.getComputedStyle(textArea) : textArea.currentStyle;
    const textAreaLineHeight = parseInt(style.lineHeight, 10);
    const textAreaHeight = calculateContentHeight(textArea, textAreaLineHeight);
    const numberOfLines = Math.floor(textAreaHeight / textAreaLineHeight);

    if (numberOfLines <= 5) {
      setTextAreaRows(numberOfLines);
    }
  };

  return (
    <textarea
      ref={textAreaRef}
      rows={textAreaRows}
      className="msger-input multi-line"
      placeholder="Ide irjon uzenetet..."
      disabled={isDisabled}
      value={inputValue}
      onChange={handleAnswerChange}
      onKeyUp={recalculateTextAreaDimensions}
      onKeyDown={recalculateTextAreaDimensions}
      style={{ width: '100%' }}
      onFocus={handleFocus}
    />
  );
}

MessengerTextInput.propTypes = {
  inputValue: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleAnswerChange: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
};

function calculateContentHeight(textArea, scanAmount) {
  const origHeight = textArea.style.height;
  let height = textArea.offsetHeight;
  const scrollHeight = textArea.scrollHeight;
  const overflow = textArea.style.overflow;
  /// only bother if the ta is bigger than content
  if (height >= scrollHeight) {
    /// check that our browser supports changing dimension
    /// calculations mid-way through a function call...
    textArea.style.height = height + scanAmount + 'px';
    /// because the scrollbar can cause calculation problems
    textArea.style.overflow = 'hidden';
    /// by checking that scrollHeight has updated
    if (scrollHeight < textArea.scrollHeight) {
      /// now try and scan the ta's height downwards
      /// until scrollHeight becomes larger than height
      while (textArea.offsetHeight >= textArea.scrollHeight) {
        textArea.style.height = (height -= scanAmount) + 'px';
      }
      /// be more specific to get the exact height
      while (textArea.offsetHeight < textArea.scrollHeight) {
        textArea.style.height = height++ + 'px';
      }
      /// reset the ta back to it's original height
      textArea.style.height = origHeight;
      /// put the overflow back
      textArea.style.overflow = overflow;
      return height;
    }
  } else {
    return scrollHeight;
  }
}

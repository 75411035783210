export const types = {
  TOGGLE_MENU_DROPDOWN: 'TOGGLE_MENU_DROPDOWN',
  TOGGLE_FILTERBAR: 'TOGGLE_FILTERBAR',
  SET_VIEW_MODE: 'SET_VIEW_MODE',
};

export function toggleMenuDropdown(isMenuDropdownShown) {
  return {
    type: types.TOGGLE_MENU_DROPDOWN,
    data: isMenuDropdownShown,
  };
}

export function toggleFilterBar(isFilterBarOpen) {
  return {
    type: types.TOGGLE_FILTERBAR,
    data: isFilterBarOpen,
  };
}

export function setViewMode(viewMode) {
  return {
    type: types.SET_VIEW_MODE,
    data: viewMode,
  };
}

import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { PredefinedGroupList, PredefinedGroupDetails } from '../PredefinedGroup';

const DataManagement = ({ match }) => {
  const mainRoute = match.url === '/' ? '' : match.url;

  return (
    <Switch>
      <Route path={`${mainRoute}/predefined-group/:groupId`} component={PredefinedGroupDetails} />
      <Route path={`${mainRoute}/`} component={PredefinedGroupList} />
      <Redirect from={``} to={`/`} />
    </Switch>
  );
};

export default DataManagement;

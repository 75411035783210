import React from 'react';
import { useSelector } from 'react-redux';

import RegisteredProfilePage from './RegisteredProfilePage';
import AnonymusProfilePage from './AnonymusProfilePage';

function ProfilePage(props) {
  const isLimited = useSelector((state) => (state.auth.userData ? state.auth.userData.isLimited : false));

  if (isLimited) {
    return <AnonymusProfilePage {...props} />;
  } else {
    return <RegisteredProfilePage {...props} />;
  }
}

export default ProfilePage;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import axios from 'axios';

import InputWithError from '../InputWithError';
import Popup from '../Popup';
import Header from '../Header';

import * as authActions from '../../actions/auth';
import * as errorActions from '../../actions/error';
import { formValidator, isNewsletterEnabled } from '../../helper/functions';

import './ProfilePage.scss';

class RegisteredProfilePage extends React.Component {
  constructor() {
    super();
    this.state = {
      prevUserData: {
        name: '',
        email: '',
      },
      formData: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        currentPassword: '',
        newsletter: false,
        newsLetterOriginal: false,
      },
      popupShown: false,
      popupText: '',
      popupImg: null,
      errors: {},
    };
  }

  componentDidMount() {
    if (this.props.authenticated) {
      axios
        .get('/user/profile', {
          headers: {
            Authorization: 'Bearer ' + this.props.token,
          },
        })
        .then((response) => {
          this.setState((state) => {
            return {
              ...state,
              formData: {
                ...state.formData,
                name: response.data.name,
                email: response.data.email,
                newsletter: !!response.data.newsletter,
                newsLetterOriginal: !!response.data.newsletter,
              },
              prevUserData: {
                name: response.data.name,
                email: response.data.email,
              },
            };
          });
        })
        .catch((e) => this.props.throwError(e));
    } else {
      this.props.history.push('/');
    }
  }

  handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState((state) => {
      return {
        formData: {
          ...state.formData,
          [name]: value,
        },
        errors: {
          ...state.errors,
          ...formValidator(
            target.name,
            target.value,
            target.name === 'confirmPassword' && this.state.formData.password,
            true
          ),
        },
      };
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { password, confirmPassword, ...formDataToSend } = { ...this.state.formData };
    if (password.length > 0) {
      formDataToSend = { ...formDataToSend, password };
    }

    let payload;
    if (password === '' && confirmPassword === '' && formDataToSend.newsLetterOriginal !== formDataToSend.newsletter) {
      payload = { newsletter: formDataToSend.newsletter };
    } else {
      payload = { ...formDataToSend, type: 'email' };
    }

    axios
      .post('/user/profile', payload, {
        headers: {
          Authorization: 'Bearer ' + this.props.token,
        },
      })
      .then((response) => {
        this.props.setUserData(response.data);
        this.setState((state) => {
          return {
            ...state,
            popupShown: true,
            popupText: 'profile/edit-success',
            popupImg: 'success',
            formData: {
              ...state.formData,
              password: '',
              confirmPassword: '',
              currentPassword: '',
              newsLetterOriginal: formDataToSend.newsletter,
            },
          };
        });
      })
      .catch((e) => {
        this.setState((state) => {
          const errorMessage =
            e.response.data.error === 'EMAIL_ALREADY_EXISTS'
              ? 'registration/email-already-exists'
              : 'registration/general-error';
          return {
            ...state,
            popupShown: true,
            popupText: errorMessage,
            popupImg: 'error',
          };
        });
      });
  };

  popupOkHandler = () => {
    this.setState((state) => {
      return {
        ...state,
        prevUserData: {
          name: state.popupImg === 'success' ? state.formData.name : state.prevUserData.name,
          email: state.popupImg === 'success' ? state.formData.email : state.prevUserData.email,
        },
        popupShown: false,
        popupText: '',
        popupImg: null,
        formData: { ...state.formData, currentPassword: '' },
      };
    });
  };

  render() {
    const { prevUserData, formData, errors, popupShown, popupText, popupImg } = this.state;
    const formValid =
      (Object.keys(errors)
        .map((k) => errors[k])
        .filter((e) => e).length === 0 &&
        formData.currentPassword.length > 0 &&
        (prevUserData.name !== formData.name ||
          prevUserData.email !== formData.email ||
          (formData.password && formData.confirmPassword))) ||
      formData.newsletter !== formData.newsLetterOriginal;

    return (
      <div className="profile-page-content">
        <Popup popupShown={popupShown} popupImg={popupImg} okHandler={this.popupOkHandler}>
          <span>{I18n.t(popupText)}</span>
        </Popup>
        <Header />
        <div bp="full-width-until@sm 6--max" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <div className="form-wrapper" style={{ minHeight: 380 }}>
            <h1 className="profile-title">
              <Translate value="profile/title" />
            </h1>
            <div className="form-container">
              <form onSubmit={this.handleSubmit}>
                <div className="form-content">
                  <InputWithError
                    placeholder={I18n.t('form/name')}
                    name="name"
                    type="name"
                    value={formData.name}
                    onChange={this.handleInputChange}
                    errorMsg={errors.name}
                    autoComplete="new-password"
                  />
                  <InputWithError
                    placeholder={I18n.t('form/email')}
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={this.handleInputChange}
                    errorMsg={errors.email}
                    autoComplete="new-password"
                  />
                  <InputWithError
                    placeholder={I18n.t('form/new-password')}
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={this.handleInputChange}
                    errorMsg={errors.password}
                    autoComplete="new-password"
                  />
                  <InputWithError
                    placeholder={I18n.t('form/new-password-again')}
                    name="confirmPassword"
                    type="password"
                    value={formData.confirmPassword}
                    onChange={this.handleInputChange}
                    errorMsg={errors.confirmPassword}
                    autoComplete="new-password"
                  />
                  {isNewsletterEnabled() && (
                    <div className="checkbox-container">
                      <label className="checkbox-line">
                        <input
                          name={'newsletter'}
                          type={'checkbox'}
                          checked={formData.newsletter}
                          onChange={this.handleInputChange}
                        />
                        <span className="checkmark"></span>
                        {I18n.t('registration/newsletter-subscribe')}
                      </label>
                    </div>
                  )}
                  <div className="grey-line"></div>
                  <InputWithError
                    placeholder={I18n.t('form/current-password')}
                    name="currentPassword"
                    type="password"
                    value={formData.currentPassword}
                    onChange={this.handleInputChange}
                    errorMsg={errors.currentPassword}
                  />
                </div>
              </form>
              <div className="button-container">
                <button className="cancel" onClick={() => this.props.history.goBack()}>
                  <Translate value="form/cancel" />
                </button>
                <button onClick={(e) => this.handleSubmit(e)} className="medium" disabled={!formValid}>
                  <Translate value="form/save" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RegisteredProfilePage.propTypes = {
  token: PropTypes.string,
  login: PropTypes.func,
  setUserData: PropTypes.func,
  userData: PropTypes.object,
  authenticated: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    userData: state.auth.userData,
    authenticated: state.auth.authenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login: authActions.login,
      setUserData: authActions.setUserData,
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredProfilePage);

import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';

import SubHeader from '../SubHeader';
import BackButton from '../BackButton';
import TextWithTooltip from '../TextWithTooltip';
import Filterbar from './Filterbar';
import ViewOptionsMobile from '../ViewOptionsMobile';
import FilterPopup from '../FilterPopup';

function MobileFilter({ filterbarProps, backButton, backBtnTitle, pathname, subPageTitle }) {
  const [filterbarIsOpen, setFilterbarIsOpen] = useState(false);
  const isNewProcessesPage = pathname && !pathname.includes('new-processes');

  return (
    <>
      <div className="mobile-filter">
        <FilterPopup filterbarIsOpen={filterbarIsOpen} setFilterbarIsOpen={setFilterbarIsOpen} {...filterbarProps} />
      </div>
      <SubHeader>
        <div bp="flex full-width vertical-center" className="title-bar">
          <div bp="fit">
            {isNewProcessesPage ? (
              <BackButton title={I18n.t(backBtnTitle)} backUrl="/client-management/entities" />
            ) : (
              <BackButton title={backButton.title} backUrl={backButton.url} />
            )}
          </div>
          <div className="title">
            <TextWithTooltip textWithOverflow={subPageTitle} />
          </div>
        </div>
        <div bp="flex full-width vertical-center" className="filter-bar">
          <div bp="fill">
            <Filterbar {...filterbarProps} />
          </div>
          {!isNewProcessesPage && (filterbarProps.switchers || filterbarProps.selects) && (
            <div bp="fit">
              <ViewOptionsMobile setFilterPopupOpen={() => setFilterbarIsOpen(true)} />
            </div>
          )}
        </div>
      </SubHeader>
    </>
  );
}

export default MobileFilter;

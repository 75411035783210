import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Svg from '../Svg';

import * as layoutActions from '../../actions/layout';
import { useGetViewMode } from '../../helper/functions';

import './ViewOptions.scss';

import filtersClosed from '../../assets/filter_icon.svg';
import filtersOpened from '../../assets/close_filter_icon.svg';
import listview from '../../assets/listview.svg';
import cardview from '../../assets/cardview.svg';

const ViewOptions = (props) => {
  const {
    isFilterBarOpen,
    toggleFilterBar,
    viewMode,
    setViewMode,
    options = ['toggleFilterBar', 'setViewMode'],
    componentName,
  } = props;
  const vm = useGetViewMode(viewMode, componentName);
  const optionImages = [
    {
      type: 'toggleFilterBar',
      src: isFilterBarOpen ? filtersOpened : filtersClosed,
      hasHover: true,
      onClick: () => {
        toggleFilterBar(!isFilterBarOpen);
      },
    },
    {
      type: 'setViewMode',
      src: listview,
      hasHover: true,
      onClick: () => setViewMode({ key: componentName, mode: 'list' }),
      otherColor: viewMode && viewMode[componentName] !== 'tile' ? clientConfig.colors.svgHoverColor : null,
    },
    {
      type: 'setViewMode',
      src: cardview,
      hasHover: true,
      onClick: () => setViewMode({ key: componentName, mode: 'tile' }),
      otherColor: viewMode && vm === 'tile' ? clientConfig.colors.svgHoverColor : null,
    },
  ];

  return (
    <div className="view-options">
      {optionImages.map(
        (img, index) =>
          options.includes(img.type) && (
            <div onClick={img.onClick} key={index}>
              <Svg src={img.src} hasHover={img.hasHover} otherColor={img.otherColor} />
            </div>
          )
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isFilterBarOpen: state.layout.isFilterBarOpen,
    viewMode: state.layout.viewMode,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleFilterBar: layoutActions.toggleFilterBar,
      setViewMode: layoutActions.setViewMode,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewOptions);

import React from 'react';

import { isP4y, getBrowserLang } from '../../helper/functions';

import standardHun from '../../assets/default_badge_HUN.svg';
import goldHun from '../../assets/gold_sponsor_badge_HUN.svg';
import platinumHun from '../../assets/platinum_sponsor_badge_HUN.svg';
import standardEng from '../../assets/default_badge_ENG.svg';
import goldEng from '../../assets/gold_sponsor_badge_ENG.svg';
import platinumEng from '../../assets/platinum_sponsor_badge_ENG.svg';

import './Badges.scss';

const Badges = () => {
  if (!isP4y()) {
    return null;
  }

  return (
    <div bp="grid 4 margin" className="badges">
      {renderBadges()}
    </div>
  );
};

function renderBadges() {
  switch (getBrowserLang()) {
    case 'hu_HU':
      return (
        <>
          <img bp="margin" src={standardHun} />
          <img bp="margin" className="platinum" src={platinumHun} />
          <img bp="margin" src={goldHun} />
        </>
      );

    default:
      return (
        <>
          <img bp="margin" src={standardEng} />
          <img bp="margin" className="platinum" src={platinumEng} />
          <img bp="margin" src={goldEng} />
        </>
      );
  }
}

export default Badges;

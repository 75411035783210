import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Svg from '../../../components/Svg';
import { translate } from '../../../helper/functions';

import arrow from '../../../assets/accordion_arrow.svg';

function Body({ data, renderRow }) {
  return data.map((d) => <RowGroup key={d.stageId} label={d.label} items={d.items} renderRow={renderRow} />);
}

export const dataShape = PropTypes.arrayOf(PropTypes.object);

Body.propTypes = {
  data: dataShape.isRequired,
};

export default Body;

function RowGroup({ label, items, renderRow }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="table-group">
      <div className="table-group-title">
        <div className="column group-title" onClick={() => setIsOpen(!isOpen)}>
          <span style={{ position: 'sticky', left: '20px' }}>{translate(label)}</span>
          <div className="group-actions">
            <div className={`arrow-container ${isOpen ? 'opened' : 'closed'}`}>
              <Svg src={arrow} hasHover={true} />
            </div>
          </div>
        </div>
      </div>
      <div className={`table-content hide-scrollbar table-group-content ${isOpen ? 'opened' : 'closed'}`}>
        {items.map((item) => renderRow(item))}
      </div>
    </div>
  );
}

RowGroup.propTypes = {
  label: PropTypes.shape({ label: PropTypes.object }).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderRow: PropTypes.func.isRequired,
};

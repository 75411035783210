import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';

import InputWithError from '../InputWithError';
import Footer from '../Footer';

import { afterLogin, isTermsEnabled, isPrivacyEnabled } from '../../helper/functions';
import * as authActions from '../../actions/auth';

const RequestLoginCode = ({ history }) => {
  const [code, setCode] = useState('');
  const [codeStatus, setCodeStatus] = useState({ status: 'notSent' });
  const [token, setToken] = useState('');
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      axios
        .post('/auth/login', { token, code, type: 'code', terms, privacy })
        .then((response) => {
          afterLogin(
            () => dispatch(authActions.login(response.data)),
            response,
            undefined,
            response.data.userData,
            history
          );
        })
        .catch((e) => setCodeStatus({ status: 'error', error: e }));
    },
    [code, setCodeStatus, dispatch, terms, privacy]
  );
  const handleChange = useCallback((e) => setCode(e.target.value), [setCode]);
  const [requestCodeStatus, setRequestCodeStatus] = useState({ status: 'sending' });

  useEffect(() => {
    if (authenticated) {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    const token = query.code;
    setToken(token);

    axios
      .post('/auth/request-login-code', { token })
      .then(() => setRequestCodeStatus({ status: 'sent' }))
      .catch((e) => setRequestCodeStatus({ status: 'error', error: e }));
  }, [setRequestCodeStatus]);

  return (
    <div className="authentication-page-content">
      <div bp="full-width-until@sm 6--max" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="form-wrapper no-header">
          <h1>
            <Translate value="request-login-code/title" />
          </h1>
          <RequestCodeMessage requestCodeStatus={requestCodeStatus} />
          <h4>
            <Translate value="request-login-code/code" />
          </h4>
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <InputWithError placeholder="" name="login-code" type="text" value={code} onChange={handleChange} />
              <CodeStatus codeStatus={codeStatus} />
              <div className="checkbox-container">
                {isTermsEnabled() ? (
                  <label className="checkbox-line">
                    <input name={'terms'} type={'checkbox'} checked={terms} onChange={() => setTerms(!terms)} />
                    <span className="checkmark"></span>
                    <Translate value="registration/agree" />
                    <a target="_blank" href={I18n.t('registration/terms-and-conditions-link')} rel="noreferrer">
                      <Translate value="registration/terms-and-conditions" />
                    </a>
                  </label>
                ) : null}
                {isPrivacyEnabled() ? (
                  <label className="checkbox-line">
                    <input name={'privacy'} type={'checkbox'} checked={privacy} onChange={() => setPrivacy(!privacy)} />
                    <span className="checkmark"></span>
                    <Translate value="registration/agree" />
                    <a target="_blank" href={I18n.t('registration/privacy-link')} rel="noreferrer">
                      <Translate value="registration/privacy" />
                    </a>
                  </label>
                ) : null}
              </div>
              <h4>
                <Translate value="request-login-code/description" />
              </h4>
              <div className="button-container">
                <button className="medium" type="submit" disabled={!privacy || !terms || code.length < 6}>
                  <Translate value="request-login-code/send" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RequestLoginCode;

function RequestCodeMessage({ requestCodeStatus }) {
  switch (requestCodeStatus.status) {
    case 'sending':
      return (
        <div>
          <Translate value="request-login-code/code-sending" />
        </div>
      );
    case 'sent':
      return (
        <div>
          <Translate value="request-login-code/code-sent" />
        </div>
      );
    case 'error':
      return (
        <div>
          <Translate value="request-login-code/request-code-error" />
        </div>
      );
  }
}

function CodeStatus({ codeStatus }) {
  switch (codeStatus.status) {
    case 'notSent':
      return null;
    case 'success':
      return <Redirect to={`/`} />;
    case 'error':
      return (
        <div>
          <Translate value="request-login-code/code-error" />
        </div>
      );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import TextWithTooltip from '../../../components/TextWithTooltip';
import Svg from '../../../components/Svg';

import sort from '../../../assets/sort.svg';
import ascending from '../../../assets/sort_ascending_icon.svg';
import descending from '../../../assets/sort_descending_icon.svg';

function Header({ headerData, propertyAndDirection, setPropertyAndDirection }) {
  return (
    <div className="table-header">
      {headerData.map((header, index) => (
        <div
          key={index}
          className={`column ${header.class} ${!index ? 'sticky' : ''}`}
          onClick={() => {
            header.sortName &&
              setPropertyAndDirection({
                property: header.sortName,
                isAscending: !propertyAndDirection.isAscending,
              });
          }}
        >
          <TextWithTooltip textWithOverflow={I18n.t(header.name)} />
          {header.sortName && header.sortName !== propertyAndDirection.property && (
            <Svg className="sort-svg" src={sort} otherColor="#000" />
          )}
          {header.sortName === propertyAndDirection.property && (
            <Svg
              className="sorted-svg"
              src={propertyAndDirection.isAscending ? ascending : descending}
              otherColor="#000"
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default Header;

export const headerDataShape = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    className: PropTypes.string,
    sortName: PropTypes.string,
  })
);

Header.propTypes = {
  headerData: headerDataShape.isRequired,
  propertyAndDirection: PropTypes.shape({
    property: PropTypes.string,
    isAscending: PropTypes.bool,
  }).isRequired,
  setPropertyAndDirection: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import queryString from 'query-string';
import axios from 'axios';

import InputWithError from '../InputWithError';
import Footer from '../Footer';
import Popup from '../Popup';

import * as authActions from '../../actions/auth';
import { formValidator } from '../../helper/functions';

import sent_icon from '../../assets/sent_icon.svg';

class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      formData: {
        password: '',
        confirmPassword: '',
      },
      token: '',
      errors: {},
      popup: {
        shown: false,
        title: '',
        message: '',
      },
    };
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push('/');
    }
    const query = queryString.parse(this.props.location.search);
    this.setState({
      token: query.code,
    });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState((state) => {
      return {
        formData: {
          ...state.formData,
          [target.name]: target.value,
        },
        errors: {
          ...state.errors,
          ...formValidator(
            target.name,
            target.value,
            target.name === 'confirmPassword' && this.state.formData.password
          ),
        },
      };
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post('/auth/reset-password', { ...this.state.formData, type: 'password', token: this.state.token })
      .then((response) => {
        this.setState((state) => {
        return {
          ...state,
          popup: {
            ...this.state.popup,
            shown: true,
            title: I18n.t('successful-new-password/title'),
            message: I18n.t('successful-new-password/text'),
          },
        };
      });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  okHandler = () => {
    this.setState((state) => {
      return {
        ...state,
        popup: {
          shown: false,
          title: '',
          message: '',
        },
      };
    });
    this.props.history.push('/');
  };

  render() {
    const { formData, errors, popup } = this.state;
    const formValid =
      Object.keys(errors)
        .map((k) => errors[k])
        .filter((e) => e).length === 0;
    return (
      <div className="authentication-page-content">
        <Popup popupShown={popup.shown} popupTitle={popup.title} okHandler={this.okHandler}>
          <span>{popup.message}</span>
          {window.theme === 'axial' && <img alt="icon_sent" src={sent_icon} />}
        </Popup>
        <div bp="full-width-until@sm 6--max" style={{ margin: '0 auto' }}>
          <div className="form-wrapper no-header">
            <h1 className="login-headline">
              <Translate value="new-password/title" />
            </h1>
            <h4>
              <Translate value="new-password/new-password" />
            </h4>
            <div className="form-container">
              <form onSubmit={this.handleSubmit}>
                <InputWithError
                  placeholder={I18n.t('form/password')}
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={this.handleInputChange}
                  errorMsg={errors.password}
                />
                <InputWithError
                  placeholder={I18n.t('form/password-again')}
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={this.handleInputChange}
                  errorMsg={errors.confirmPassword}
                />
                <h4>
                  <Translate value="new-password/description" />
                </h4>
                <div className="button-container">
                  <button className="medium" type="submit" disabled={formData.password.length === 0 || !formValid}>
                    <Translate value="form/save" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

ResetPassword.propTypes = {
  token: PropTypes.string,
  login: PropTypes.func,
  authenticated: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    token: state.auth.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login: authActions.login,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

import React, { useState, useCallback } from 'react';
import { I18n } from 'react-redux-i18n';

import ReactSelectWrapper from '../ReactSelectWrapper';
import Popup from '../Popup';

function FilterPopup({ filterbarIsOpen, setFilterbarIsOpen, switchers, selects, clearSearchAndFilter }) {
  const handleCancel = useCallback(() => {
    setFilterbarIsOpen(false);
  }, []);

  return (
    <Popup
      popupShown={filterbarIsOpen}
      popupTitle={I18n.t('filter-popup/filters')}
      okHandler={handleCancel}
      cancelHandler={handleCancel}
    >
      {selects &&
        selects.map((select, index) => {
          const extendedOptions = [
            {
              value: 'all',
              label: I18n.t('general/all').replace('{}', I18n.t(`selector/${select.name}`).toLowerCase()),
            },
            ...select.options,
          ];
          const placeholder = I18n.t('selector/select', { name: I18n.t(`selector/${select.name}`) });
          return (
            <div key={index}>
              <ReactSelectWrapper
                options={extendedOptions}
                className={select.className}
                onChange={(event) => select.onSelectChange(event.value)}
                placeholder={placeholder}
                hasHover={true}
                value={{
                  value: select.selected,
                  label: select.selected ? extendedOptions.find((o) => o.value === select.selected).label : placeholder,
                }}
              />
            </div>
          );
        })}
      {switchers &&
        switchers.map((switcher, index) => (
          <div bp="grid" className="switcher-container" key={index}>
            <div bp="5" className="switcher-title">
              {I18n.t(switcher.text)}
            </div>
            <div bp="2" className="switcher">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={switcher.checked}
                  onChange={(event) => switcher.onSwitcherChange(event.target.value)}
                />
                <span className={`slider round ${switcher.checked ? 'on' : 'off'}`}></span>
              </label>
            </div>
          </div>
        ))}
    </Popup>
  );
}

export default FilterPopup;

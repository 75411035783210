import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { pageStates } from '../../helper/payment';
import Svg from '../../components/Svg';

import './PaymentStatusBar.scss';
import loader from '../../assets/loader_circle_icon.svg';

function PaymentStatusBar() {
  const paymentState = useSelector((state) => state.currentStage.paymentState);

  let currentStep = 1;
  switch (paymentState) {
    case pageStates.processingPayment:
      currentStep = 2;
      break;
    case pageStates.payed:
      currentStep = 4;
      break;
    case pageStates.errorPayment:
      currentStep = 3;
      break;
    case pageStates.waitingForPaymentInfo:
    case pageStates.notPayed:
    case pageStates.errorPaymentInfo:
    default:
      currentStep = 1;
  }

  return <div className="payment-statusbar">{renderContent(paymentState, currentStep)}</div>;
}

export default PaymentStatusBar;

function renderContent(paymentState, currentStep) {
  switch (paymentState) {
    case pageStates.waitingForPaymentInfo:
    case pageStates.errorPaymentInfo:
      return <Svg className={'download rotating'} src={loader} hasHover={true} />;
    case pageStates.notPayed:
      return (
        <ol className="steps">
          <li className="step is-complete">
            <span className="step-text">{I18n.t('payment/step-1')}</span>
          </li>
          <li className="step is-active">
            <span className="step-text" />
          </li>
          <li className="step is-active">
            <span className="step-text" />
          </li>
        </ol>
      );
    case pageStates.processingPayment:
      return (
        <ol className="steps">
          <li className="step is-complete">
            <span className="step-text">{I18n.t('payment/step-1')}</span>
          </li>
          <li className="step is-complete">
            <span className="step-text">{I18n.t('payment/step-2')}</span>
          </li>
          <li className="step is-active">
            <span className="step-text" />
          </li>
        </ol>
      );
    case pageStates.errorPayment:
    case pageStates.payed:
      return (
        <ol className="steps">
          <li className="step is-complete">
            <span className="step-text">{I18n.t('payment/step-1')}</span>
          </li>
          <li className={currentStep >= 2 ? 'step is-complete' : 'step is-active'}>
            <span className="step-text">{I18n.t('payment/step-2')}</span>
          </li>
          <StepThree currentStep={currentStep} />
        </ol>
      );
  }
}

function StepThree({ currentStep }) {
  let stepClass;
  let text;
  if (currentStep === 4) {
    stepClass = 'step is-complete';
    text = I18n.t('payment/step-3-success');
  } else {
    stepClass = 'step is-active';
    text = I18n.t('payment/step-3-error');
  }

  return (
    <li className={stepClass}>
      <span className="step-text">{text}</span>
    </li>
  );
}

StepThree.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

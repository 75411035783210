import React from 'react';
import PropTypes from 'prop-types';

import './FormTooltip.scss';

const FormTooltip = (props) => {
  const { price, currency, text } = props;
  if (text.length === 0) return null
  return (
    <div className="tooltip">
      <div className="arrow-box">
        <span dangerouslySetInnerHTML={{ __html: text }} />
        {price && false &&
          <div className="price-box"><span>{price} {currency}</span></div>
        }
      </div>
    </div>
  );
}

FormTooltip.propTypes = {
  text: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.string
};

export default FormTooltip;

import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import FormTooltip from '../../FormTooltip';

import ReactSelectWrapper from '../../ReactSelectWrapper';
import Esign from '../../Esign';

import { translate, getDateTimeFormat, setSelectorDirectionClass } from '../../../helper/functions';
import {
  useGetPlaceholder,
  useESignatureHandleChange,
  useHandleChange,
  useHandleChangeForDate,
  useHandleChangeForCheckbox,
  useHandleChangeForRadio,
} from './predefined-field-logic';

import './Field.scss';
import 'react-datepicker/dist/react-datepicker.css';

import error_icon from '../../../assets/error_icon.svg';

const Field = ({ description, children, error, valueChanged }) => {
  const errorMsg = valueChanged ? error : '';

  return (
    <div className="field group-wrapper-with-separator">
      <div bp="grid" className="form-row">
        <div bp="8" className="left-container">
          <div className="field-input-with-error">
            <div className={`field-input-with-error-wrapper ${errorMsg ? 'error' : ''}`}>
              {children}
              {errorMsg && <img className="error-icon" src={error_icon} />}
            </div>
            <div className="error-message">{errorMsg}</div>
          </div>
        </div>
        <div bp="4" className="right-container">
          <FormTooltip text={translate(description)} />
        </div>
      </div>
    </div>
  );
};

Field.propTypes = {};

export default Field;

export function Input(props) {
  const placeholder = useGetPlaceholder(props.placeholder, props.validationRules);

  switch (props.type) {
    case 'esignature':
      return <ESignature onChange={props.onChange} />;
    case 'text':
    case 'number':
      return (
        <TextInput
          id={props.id}
          groupId={props.groupId}
          value={props.value}
          placeholder={placeholder}
          onChange={props.onChange}
          validationRules={props.validationRules}
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          id={props.id}
          groupId={props.groupId}
          values={props.values}
          validationRules={props.validationRules}
          label={props.label}
          value={props.value}
          placeholder={placeholder}
          onChange={props.onChange}
        />
      );
    case 'textarea':
      return (
        <Textarea
          id={props.id}
          groupId={props.groupId}
          value={props.value}
          placeholder={placeholder}
          onChange={props.onChange}
        />
      );
    case 'date':
      return (
        <DateField
          id={props.id}
          groupId={props.groupId}
          value={props.value}
          placeholder={placeholder}
          onchange={props.onChange}
        />
      );
    case 'select':
      return <Select {...props} placeholder={placeholder} onChange={props.onChange} />;
    case 'radio':
      return <Radio {...props} placeholder={placeholder} />;
    case 'separator':
    default:
      return null;
  }
}

Input.propTypes = {};

function ESignature({ onChange }) {
  const handleChange = useESignatureHandleChange(onChange);

  return <Esign value={value} onChange={handleChange}></Esign>;
}

function TextInput({ id, groupId, value, placeholder, onChange }) {
  const handleChange = useHandleChange(groupId, id, onChange);

  return (
    <label>
      <input
        className={'input ' + (value ? 'has-value' : '')}
        type="text"
        value={value || ''}
        onChange={handleChange}
      />
      <div className="active-placeholder">
        <span>{placeholder}</span>
      </div>
    </label>
  );
}

function Checkbox({ id, groupId, validationRules, values, label, value, placeholder, onChange }) {
  const handleChange = useHandleChangeForCheckbox(groupId, id, onChange, value);

  return (
    <div className="checkbox-container">
      {label && translate(label) && (
        <div className="checkbox-main-label">
          {translate(label) + (validationRules && validationRules.required ? '*' : '')}
        </div>
      )}
      {values &&
        values.map((val, i) => {
          return (
            <label key={i} className="checkbox-line">
              <input
                id={val.id}
                type="checkbox"
                checked={value ? value.map((v) => v.id).includes(val.id) : false}
                placeholder={placeholder}
                onChange={() => handleChange(val)}
              />
              <span className="checkmark"></span>
              {translate(val.label)}
            </label>
          );
        })}
    </div>
  );
}

function Textarea({ id, groupId, value, placeholder, onChange }) {
  const handleChange = useHandleChange(groupId, id, onChange);

  return (
    <label>
      <textarea className={value ? 'has-value' : ''} value={value ? value : ''} onChange={handleChange} />
      <div className="active-placeholder">
        <span>{placeholder}</span>
      </div>
    </label>
  );
}

function DateField({ id, groupId, value, placeholder, onchange }) {
  const handleChange = useHandleChangeForDate(groupId, id, onchange);

  return (
    <div className={'datepicker-with-placeholder ' + (value || true ? 'has-value' : '')}>
      <DatePicker
        dateFormat={getDateTimeFormat(clientConfig.locale)}
        selected={value ? new Date(value) : null}
        onChange={handleChange}
        showYearDropdown
        showMonthDropdown
        onCalendarClose={() => () => {}}
        onCalendarOpen={() => () => {}}
        dropdownMode="select"
      />
      <span className="datepicker-placeholder">{placeholder}</span>
    </div>
  );
}

function Select(props) {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenCallback = useCallback(() => {
    setSelectorDirectionClass();
    setIsOpen(true);
  }, [isOpen, setIsOpen]);
  const handleCloseCallback = useCallback(() => {
    setSelectorDirectionClass();
    setIsOpen(false);
  }, [isOpen, setIsOpen]);
  let filterOption = (item, search) => item.label.toLowerCase().startsWith(search.toLowerCase());
  const searchable = props.options && props.options.searchable;
  const options = props.referenceCollectionName
    ? props.referenceCollections[props.referenceCollectionName].map((ref) => {
        return { value: ref._id, label: translate(ref.label) };
      })
    : props.values.map((v) => {
        return { value: v.id, label: translate(v.label) };
      });
  if (props.referenceCollectionName && props.referenceCollectionName.includes('phone-codes')) {
    const r = /\d+/;
    filterOption = (item, search) => {
      const countryName = item.label
        .split(')')
        .pop()
        .trim();
      return countryName.toLowerCase().startsWith(search.toLowerCase()) || item.label.match(r).includes(search);
    };
    options.sort((a, b) => a.label.match(r) - b.label.match(r));
  } else if (props.referenceCollectionName) {
    options.sort((a, b) => a.label.localeCompare(b.label));
  }
  return (
    <div className="select-with-placeholder">
      <ReactSelectWrapper
        className={`hide-placeholder ${props.value || isOpen ? 'has-value' : ''}`}
        options={options}
        placeholder={props.placeholder}
        isMulti={!!props.multiple}
        onChange={(e) => props.onChange(props.groupId, props.id, e)}
        onMenuOpen={handleOpenCallback}
        onMenuClose={handleCloseCallback}
        filterOption={filterOption}
        value={props.value}
        isSearchable={searchable}
      >
        <span className="select-placeholder">{props.placeholder}</span>
      </ReactSelectWrapper>
    </div>
  );
}

function Radio(props) {
  const handleChange = useHandleChangeForRadio(props.groupId, props.id, props.onChange);
  return (
    <div className="radiobutton-container">
      {props.label && translate(props.label) && (
        <div className="radiobutton-main-label">
          {translate(props.label) + (props.validationRules && props.validationRules.required ? '*' : '')}
        </div>
      )}
      {props.values &&
        props.values.map((val, i) => {
          return (
            <label key={i} className={'radiobutton-line '}>
              <input
                id={val.id}
                type="radio"
                checked={props.value && props.value.id === val.id ? true : false}
                placeholder={props.placeholder}
                onChange={() => handleChange(val)}
              />
              <span className="checkmark"></span>
              {translate(val.label)}
            </label>
          );
        })}
    </div>
  );
}

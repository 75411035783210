import { I18n } from 'react-redux-i18n';
import moment from 'moment';

export function mapToRawGrouppedTransactions(rawTransactions) {
  const result = {};

  Object.values(rawTransactions).forEach((t) => {
    if (!(t.stageId in result)) {
      result[t.stageId] = [];
    }

    result[t.stageId].push(t._id);
  });

  return result;
}

export function mapToRawTransactions(transactionsFromServer) {
  return transactionsFromServer.map((transaction) => ({
    _id: transaction._id,
    userName: transaction.user.profile.name,
    transactionTime: moment(transaction.updated).format('MM-DD-YYYY'),
    transactionId: transaction.paymentId,
    provider: transaction.provider,
    state: mapTransactionStatus(transaction.status),
    stageId: transaction.stageId,
  }));
}

function mapTransactionStatus(status = '') {
  switch (status.toLocaleLowerCase()) {
    case 'infraud':
    case 'authorized':
    case 'inpayment':
      return 'pending';
    case 'finished':
      return 'approved';
    default:
      return 'rejected';
  }
}

export function mapToGrouppedTransactions(rawGrouppedTransactions, rawTransactions, stages) {
  if (!rawGrouppedTransactions || !rawTransactions) {
    return [];
  }

  const result = [];

  for (const [key, value] of Object.entries(rawGrouppedTransactions)) {
    let label;
    if (stages && stages[key] && stages[key].label) {
      label = stages[key].label;
    } else {
      label = {};
    }

    result.push({ label: label, stageId: key, items: value.map((v) => rawTransactions[v]) });
  }

  return result;
}

export function filterByUsers(data, query) {
  return data
    .map((g) => ({
      ...g,
      items: g.items.filter((i) => i.userName.toLocaleLowerCase().includes(query.toLocaleLowerCase())),
    }))
    .filter((g) => g.items.length > 0);
}

export const headerData = [
  {
    name: 'transaction-history/table-header-user-name',
    class: 'column transaction-user-name sticky ',
    sortName: 'userName',
  },
  {
    name: 'transaction-history/table-header-transaction-time',
    class: 'column transaction-time ',
    sortName: 'transactionTime',
  },
  {
    name: 'transaction-history/table-header-transaction-id',
    class: 'column transaction-id ',
  },
  {
    name: 'transaction-history/table-header-provider',
    class: 'column transaction-provider ',
  },
  {
    name: 'transaction-history/table-header-transaction-status',
    class: 'column transaction-status ',
    sortName: 'transactionStatus',
  },
];

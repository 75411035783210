export const types = {
  SET_ENTITIES: 'SET_ENTITIES',
  SET_ENTITY: 'SET_ENTITY',
  REQUEST_ENTITIES: 'REQUEST_ENTITIES',
};

export function setEntities(data) {
  return {
    type: types.SET_ENTITIES,
    data,
  };
}

export function setEntity(data, fields) {
  return {
    type: types.SET_ENTITY,
    data,
    fields,
  };
}

export function requestEntities() {
  return {
    type: types.REQUEST_ENTITIES,
  };
}

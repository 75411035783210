import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { masks, removeMask } from '../fields';
import { getPennyMask } from './number-field';

function NumberField({ number, placeholder, disabled, mask, handleGlobalChange }) {
  const [value, setValue] = useState(number || '');

  useEffect(() => {
    if (!number) {
      setValue('');
      return;
    }

    switch (mask) {
      case masks.penny:
        setValue(getPennyMask(number));
        break;
      default:
        setValue(number);
    }
  }, [number]);

  const handleChange = (e) => {
    handleGlobalChange(e, e.target.value);
  };

  switch (mask) {
    case masks.penny: {
      const handleChange = (e) => {
        handleGlobalChange(e, removeMask(e.target.value));
      };
      return (
        <NumberInput
          value={value}
          maxLength={100}
          placeholder={placeholder}
          disabled={disabled}
          handleChange={handleChange}
        />
      );
    }
    default: {
      return (
        <NumberInput
          value={value}
          maxLength={100}
          placeholder={placeholder}
          disabled={disabled}
          handleChange={handleChange}
        />
      );
    }
  }
}

export default NumberField;

NumberField.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  mask: PropTypes.string,
  handleGlobalChange: PropTypes.func.isRequired,
};

NumberField.defaultProps = {
  number: '',
  placeholder: '',
  disabled: false,
  mask: null,
};

function NumberInput({ value, placeholder, disabled, handleChange }) {
  return (
    <label>
      <input
        className={'input ' + (value ? 'has-value' : '')}
        type="text"
        disabled={disabled}
        value={value || ''}
        onChange={handleChange}
      />
      <div className="active-placeholder">
        <span>{placeholder}</span>
      </div>
    </label>
  );
}

NumberInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  maxLength: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
};

NumberInput.defaultProps = {
  value: '',
  placeholder: '',
  disabled: false,
};

import React from 'react';

import Fitness from './Fitness';
import ResponsiveTable from '../../../components/ResponsiveTable';
import TextWithTooltip from '../../../components/TextWithTooltip';
import ResponsiveTileView2 from '../../../components/ResponsiveTileView/ResponsiveTileView2';
import { getDateInLocalFormat, useIsBigScreen } from '../../../helper/functions';

import resume_icon from '../../../assets/resume_icon.svg';

export default function ApplicantList() {
  const isBigScreen = useIsBigScreen();

  const entities = [
    {
      applicantName: 'Minta János Ábrahám',
      announcementDate: new Date(2021, 3, 30, 17, 50, 0, 0),
      phoneNumber: '36301234567',
      fitness: 0,
      wageDemand: '200.000',
    },
    {
      applicantName: 'Minta János Ábrahám',
      announcementDate: new Date(2021, 3, 30, 17, 50, 0, 0),
      phoneNumber: '36301234567',
      fitness: 2,
      wageDemand: '200.000',
    },
    {
      applicantName: 'Minta János Ábrahám',
      announcementDate: new Date(2021, 3, 30, 17, 50, 0, 0),
      phoneNumber: '36301234567',
      fitness: 1,
      wageDemand: '200.000',
    },
    {
      applicantName: 'Minta János Ábrahám',
      announcementDate: new Date(2021, 3, 30, 17, 50, 0, 0),
      phoneNumber: '36301234567',
      fitness: 2,
      wageDemand: '200.000',
    },
    {
      applicantName: 'Minta János Ábrahám',
      announcementDate: new Date(2021, 3, 30, 17, 50, 0, 0),
      phoneNumber: '36301234567',
      fitness: 0,
      wageDemand: '200.000',
    },
  ];

  const headerColumns = [
    { name: 'Jelentkező neve', class: 'col-onehalf', sortName: 'applicantName' },
    { name: 'Meghirdetés dátuma', class: '', sortName: 'announcementDate' },
    { name: 'Telefonszám', class: '', sortName: 'phoneNumber' },
    { name: 'Önéletrajz', class: '', sortName: 'resume' },
    { name: 'Alkalmasság', class: '', sortName: 'fitness' },
    { name: 'Bérigény', class: '', sortName: 'wageDemand' },
  ];

  const onColumnClick = () => {};

  const renderCell = (headerColumn, entity) => {
    switch (headerColumn.sortName) {
      case 'applicantName':
        return <TextWithTooltip textWithOverflow={entity[headerColumn.sortName]} />;
      case 'announcementDate':
        return <div>{getDateInLocalFormat(entity[headerColumn.sortName])}</div>;
      case 'phoneNumber':
        return <div>+{entity[headerColumn.sortName]}</div>;
      case 'resume':
        return (
          <div>
            <img alt="resume" src={resume_icon} />
          </div>
        );
      case 'fitness':
        return <Fitness value={entity[headerColumn.sortName]} />;
      case 'wageDemand':
        return (
          <div style={{ width: '80%', fontWeight: 600 }} className="net-sallary">
            {entity[headerColumn.sortName]}
          </div>
        );
    }
  };

  const renderRow = (headerColumn, entity, index) => {
    if (headerColumn.sortName === 'applicantName') {
      return;
    }

    let value = entity[headerColumn.sortName];

    switch (headerColumn.sortName) {
      case 'applicantName':
        value = entity['applicantName'];
        break;
      case 'announcementDate':
        value = getDateInLocalFormat(entity[headerColumn.sortName]);
        break;
      case 'phoneNumber':
        value = entity['phoneNumber'];
        break;
      case 'resume':
        value = <img style={{ width: 80, height: 80 }} alt="resume" src={resume_icon} />;
        break;
      case 'fitness':
        value = <Fitness value={entity['fitness']} />;
        break;
      case 'wageDemand':
        value = (
          <div style={{ fontWeight: 600 }} className="net-sallary">
            {entity['wageDemand']}
          </div>
        );
    }

    return (
      <div key={index} className="tile-content-row">
        <span className="content-key">{headerColumn.name}</span>
        <span className="content-value">{value}</span>
      </div>
    );
  };

  if (isBigScreen) {
    return (
      <ResponsiveTable
        className="entity-table"
        headerColumns={headerColumns}
        data={entities}
        defaultSort={'created'}
        isAscending={false}
        renderRow={(entity, options) => {
          return (
            <div key={options.index} className={'table-row ' + (entity.state ? entity.state : '')}>
              {headerColumns.map((hc, columnIndex) => {
                return (
                  <div
                    key={columnIndex}
                    className={`column ${hc ? hc.class : ''} applicants`}
                    onClick={() => onColumnClick(hc, entity)}
                  >
                    {renderCell(hc, entity)}
                  </div>
                );
              })}
            </div>
          );
        }}
      ></ResponsiveTable>
    );
  } else {
    return (
      <ResponsiveTileView2
        titleName={'applicantName'}
        tileContent={entities}
        tableHeaderColumns={headerColumns}
        renderRow={renderRow}
        onTileClick={() => {}}
      />
    );
  }
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import Authorization from '../Authorization';
import Filterbar from '../Filterbar';
import SubHeader from '../SubHeader';
import ViewOptions from '../ViewOptions';
import ResponsiveTileView from '../ResponsiveTileView';
import ResponsiveTable from '../ResponsiveTable';

import { translate, filterBy, useGetViewMode } from '../../helper/functions';
import { calculateRoutePath } from '../../helper/navigation';
import { useGetPredefinedGroups } from './predefined-group-list-logic';

import './PredefinedGroupList.scss';

const PredefinedGroupList = ({ userData, history, viewMode }) => {
  const componentName = 'data-management';
  const [searchAndFilter, setSearchAndFilter] = useState({ search: '' });
  const [filteredGroups, setFilteredGroups] = useState([]);
  const groups = useGetPredefinedGroups();
  const vm = useGetViewMode(viewMode, componentName);

  useEffect(() => {
    if (groups.status !== 'success') return;

    setFilteredGroups(
      groups.data.filter((g) =>
        filterBy(g, [{ type: 'translateObject', itemProperties: ['label'], searchProperty: 'search' }], searchAndFilter)
      )
    );
  }, [groups, searchAndFilter]);

  const usersMainRoute = userData && userData.mainRoute;

  const tableHeaderColumns = [
    { name: I18n.t('group-list-table-header/label'), class: 'col-4 sticky', sortName: 'label' },
    { name: I18n.t('group-list-table-header/numberOfElements'), class: '', sortName: 'numberOfElements' },
  ];

  const filterbarProps = {
    searchbar: {
      shown: true,
      type: 'text',
      placeholder: 'predefined-group-list/search',
      onSearchChange: () => setSearchAndFilter({ ...setSearchAndFilter, search: event.target.value }),
    },
    clearSearchAndFilter: () => setSearchAndFilter({ ...setSearchAndFilter, search: '' }),
  };

  const navigateToPredefinedGroup = (gId) => {
    history.push(calculateRoutePath(usersMainRoute, `/data-management/predefined-group/${gId}`));
  };

  return (
    <div className="data-management">
      <div className="data-management-header">
        <SubHeader>
          <div className="column">
            <ViewOptions componentName={componentName} />
          </div>
        </SubHeader>
        <Filterbar {...filterbarProps} />
      </div>
      {vm === 'tile' ? (
        <ResponsiveTileView
          type="group"
          tileContent={filteredGroups}
          tableHeaderColumns={tableHeaderColumns.map((th) => th.sortName)}
          defaultSort="label"
          onTileClick={(tile) => navigateToPredefinedGroup(tile._id)}
        />
      ) : (
        <ResponsiveTable
          headerColumns={tableHeaderColumns}
          data={filteredGroups}
          className="predefined-groups-table-container"
          defaultSort="label"
          renderRow={(g) => (
            <div key={g._id} className="table-row" onClick={() => navigateToPredefinedGroup(g._id)}>
              <div className="column col-4 sticky name-container">{translate(g.label)}</div>
              <div className="column">{g.groupCount}</div>
            </div>
          )}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userData: state.auth.userData,
    viewMode: state.layout.viewMode,
  };
}

export default connect(mapStateToProps, null)(Authorization(PredefinedGroupList, ['list-predefined-groups']));

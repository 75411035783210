import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import SimpleBar from 'simplebar-react';

import { translate, getNiceValueFormat } from '../../../helper/functions';
import { useLoadSubmissionFromLocalStorage } from './messenger-logic';
import SummaryDataCheck from '../SummaryDataCheck/SummaryDataCheck';
import { Footer } from './Footer/Footer';
import Button from '../Button/Button';
import Svg from '../../../components/Svg';
import { postResults } from '../../../thunks/postresults';
import { selectGroupResults } from '../../../selectors/submission-selectors';
import { getPhoneMask } from '../../Field/TextField/text-field';

import edit from '../../../assets/edit.svg';
import './MessengerDataCheck.scss';

export default function MessengerDataCheck({ history, submissionId, stageId, prevStage }) {
  const dispatch = useDispatch();
  useLoadSubmissionFromLocalStorage();

  const fieldsMeta = useSelector((state) => state.modules.fields);
  const fieldValues = useSelector((state) => state.fieldValues[submissionId]);
  const token = useSelector((state) => state.auth.token);
  const groupResults = useSelector(selectGroupResults(submissionId));
  const handleSave = () => {
    dispatch(postResults(submissionId, stageId, token, groupResults));
  };

  const fieldValuesArray = fieldValues ? Object.values(fieldValues) : [];
  const fields = fieldValuesArray.map((field) => {
    const fieldMeta = fieldsMeta[field.fieldId];
    return { ...field, ...fieldMeta };
  });

  const handleEditClick = (fieldId) => {
    history.push(`/${submissionId}/${prevStage._id}/edit/${fieldId}`);
  };

  return (
    <>
      <SimpleBar style={{ maxHeight: '100%' }}>
        <div className="card submission-summary">
          <SummaryDataCheck header={<Header />}>
            {fields.map((field) => (
              <tr key={field.fieldId}>
                <th>
                  <span>{field.label && translate(field.label)}</span>
                  <Svg
                    src={edit}
                    hasHover={false}
                    title={'Szerkeszt'}
                    style={{ cursor: 'pointer', paddingLeft: '15px' }}
                    onClick={() => handleEditClick(field._id)}
                  />
                </th>
                <td>
                  <FieldValue field={field} />
                </td>
              </tr>
            ))}
          </SummaryDataCheck>
        </div>
        <Footer>
          <Button extraClass="messenger" onClick={handleSave}>
            {I18n.t('messenger/send-application')}
          </Button>
        </Footer>
      </SimpleBar>
    </>
  );
}

MessengerDataCheck.propTypes = {
  history: PropTypes.object.isRequired,
  submissionId: PropTypes.string.isRequired,
  stageId: PropTypes.string.isRequired,
  prevStage: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
};

function Header() {
  return (
    <thead>
      <tr className="main" style={{ display: 'grid' }}>
        <th>
          <div>Jelentkezés áttekintése</div>
        </th>
      </tr>
    </thead>
  );
}
function FieldValue({ field }) {
  switch (field.type) {
    case 'radio':
      return field.value.label ? <div>{translate(field.value.label)}</div> : null;
    case 'checkbox':
      return (
        <ul>
          {field.value.map((v) => (
            <li key={v.id}>{translate(v.label)}</li>
          ))}
        </ul>
      );
    default:
      if (field.textMask === 'penny') {
        return <div>{getNiceValueFormat(field, field.value)} Ft</div>;
      } else if (field.textMask === 'phone') {
        return <div>{getPhoneMask(field.value)}</div>;
      } else {
        return <div className="summary-text">{field.value}</div>;
      }
  }
}

FieldValue.propTypes = {
  field: PropTypes.any.isRequired,
};

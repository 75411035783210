import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';
import { withRouter, Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import AuthComponentWrapper from '../AuthComponentWrapper';
import WrappedLink from '../WrappedLink';

import * as layoutActions from '../../actions/layout';
import * as authActions from '../../actions/auth';
import { isP4y } from '../../helper/functions';
import { useComponentVisible } from '../../helper/hooks';

import './Header.scss';

import hamburger from '../../assets/hamburger_icon_mobile.svg';
import close from '../../assets/close_icon_white.svg';
import consolidity from '../../assets/consolidity_logo.svg';

function Header(props) {
  const toggleHeaderDropdown = () => props.toggleMenuDropdown(!props.isMenuDropdownShown);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const clientConfig = window.clientConfig;

  useEffect(() => {
    if (props.isMenuDropdownShown) {
      setIsComponentVisible(true);
    }
  }, [props.isMenuDropdownShown]);

  useEffect(() => {
    if (!isComponentVisible) {
      props.toggleMenuDropdown(false);
    }
  }, [isComponentVisible]);

  const { userData, links, authenticated, history, popupWrapperShown } = props;
  return (
    <section ref={ref} className="header" id="fixedmenu" style={{ zIndex: popupWrapperShown ? 0 : 11 }}>
      <div className="show-mobile align-items-center justify-content-space-between">
        <Link to={'/'}>
          <img
            className="logo-header-mobile"
            src={clientConfig && clientConfig.images['logo'] ? clientConfig.images['logo'] : consolidity}
          />
        </Link>
        {authenticated && <img className="openmenu" onClick={toggleHeaderDropdown} src={hamburger} />}
        <div className={`sidenav${props.isMenuDropdownShown ? ' active' : ' not-active'}`}>
          <div className="sidenav-title">
            <Translate value="menu/menu" />
            <img className="close-icon" src={close} onClick={toggleHeaderDropdown} />
          </div>
          {userData &&
            !userData.mainRoute &&
            !history.location.pathname.includes('error') &&
            links &&
            links.map((link, index) => (
              <AuthComponentWrapper allowedRules={link.allowedRules || []} key={index}>
                <div onClick={toggleHeaderDropdown}>
                  <WrappedLink navLink={true} to={link.to}>
                    <Translate value={link.translateKey} />
                  </WrappedLink>
                </div>
              </AuthComponentWrapper>
            ))}
          {!authenticated && isP4y() ? (
            <a>
              <span className="usage-help-link" dangerouslySetInnerHTML={{ __html: I18n.t('login/usage-help-link') }} />
            </a>
          ) : null}
          {authenticated && (
            <>
              <Link to={'/profile'} onClick={toggleHeaderDropdown}>
                <Translate value="menu/profile" />
              </Link>
              <Link
                to={'/login'}
                onClick={async () => {
                  await props.logout();
                  toggleHeaderDropdown();
                }}
              >
                <Translate value="menu/logout" />
              </Link>
            </>
          )}
        </div>
      </div>
      <div
        className={`hide-mobile align-items-center ${
          props.children ? 'custom-alignment' : 'justify-content-space-between'
        }`}
      >
        <Link to={'/'}>
          <img
            className="logo-header-desktop"
            src={clientConfig && clientConfig.images['logo'] ? clientConfig.images['logo'] : consolidity}
          />
        </Link>
        <div className="header-links flex align-items-center">
          {userData &&
            !userData.mainRoute &&
            !history.location.pathname.includes('error') &&
            links &&
            links
              .filter((l) => !l.hideOnDesktop)
              .map((link, index) => (
                <AuthComponentWrapper allowedRules={link.allowedRules || []} key={index}>
                  <WrappedLink navLink={true} to={link.to}>
                    <Translate value={link.translateKey} />
                  </WrappedLink>
                </AuthComponentWrapper>
              ))}
        </div>
        {!authenticated && isP4y() ? (
          <div
            className="usage-help-link"
            dangerouslySetInnerHTML={{
              __html: I18n.t('login/usage-help-link'),
            }}
          />
        ) : null}
        {props.children}
      </div>
    </section>
  );
}

Header.propTypes = {
  isMenuDropdownShown: PropTypes.bool,
  toggleMenuDropdown: PropTypes.func,
  logout: PropTypes.func,
  authenticated: PropTypes.bool,
  userData: PropTypes.object,
  token: PropTypes.string,
  history: PropTypes.object,
  links: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    userData: state.auth.userData,
    token: state.auth.token,
    isMenuDropdownShown: state.layout.isMenuDropdownShown,
    popupWrapperShown: state.currentStage.popupWrapperShown,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleMenuDropdown: layoutActions.toggleMenuDropdown,
      logout: authActions.logout,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

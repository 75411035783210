export function getPennyMask(value) {
  const splitByDecimal = value.toString().split('.');
  let firstPart;
  let secondPart;

  if (splitByDecimal.length > 0) {
    firstPart = splitByThrees(splitByDecimal[0]);
  }

  if (splitByDecimal.length > 1) {
    secondPart = splitByDecimal[1];

    if (secondPart.length === 0) {
      return `${firstPart}.`;
    }
  }

  if (firstPart && secondPart) {
    return `${firstPart}.${secondPart}`;
  }

  return firstPart;
}

function splitByThrees(value) {
  return value
    .split('')
    .reverse()
    .join('')
    .replace(/(\d{3})(?=\d)/g, '$1' + ' ')
    .split('')
    .reverse()
    .join('');
}

import React, { useEffect, useMemo } from 'react';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { GrouppedDataView } from 'components/DataView';
import StatusIndicator from '../../components/StatusIndicator';
import { mapToGrouppedTransactions, headerData, filterByUsers } from './transaction-history';
import { fetchTransactionHistory } from './transaction-history-slice';

import '../../components/ResponsiveTable/ResponsiveTable.scss';
import '../../components/AdminDocumentTable/AdminDocumentTable.scss';
import './TransactionHistory.scss';

function TransactionHistory({ submissionId, search }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const rawGrouppedTransactions = useSelector((state) => state.transactionHistory.rawGrouppedTransactions);
  const rawTransactions = useSelector((state) => state.transactionHistory.rawTransactions);
  const stages = useSelector((state) => state.modules.stages);

  useEffect(() => {
    dispatch(fetchTransactionHistory({ submissionId, token }));
  }, [token]);

  const grouppedTransactions = useMemo(
    () => mapToGrouppedTransactions(rawGrouppedTransactions, rawTransactions, stages),
    [rawGrouppedTransactions, rawTransactions, stages]
  );

  const filteredGrouppedTransactions = useMemo(() => filterByUsers(grouppedTransactions, search), [
    grouppedTransactions,
    search,
  ]);

  return (
    <GrouppedDataView
      headerData={headerData}
      data={filteredGrouppedTransactions}
      renderRow={renderRow}
      renderTile={renderTile}
    />
  );
}

TransactionHistory.propTypes = {
  submissionId: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
};

function renderRow(transaction) {
  return (
    <div key={transaction.transactionId} className="table-row">
      <div className={`column transaction-user-name sticky ${transaction.state}`}>
        <span>{transaction.userName}</span>
      </div>
      <div className="column transaction-time">
        <span>{transaction.transactionTime}</span>
      </div>
      <div className="column transaction-id">
        <span>{transaction.transactionId}</span>
      </div>
      <div className="column transaction-provider">
        <span>{transaction.provider}</span>
      </div>
      <div className="column transaction-status">
        <StatusIndicator state={transaction.state} />
      </div>
    </div>
  );
}

function renderTile(transaction) {
  return (
    <>
      <div className="tile-content-row">
        <span className="content-key">{I18n.t('transaction-history/table-header-user-name')}</span>
        <span className="content-value">{transaction.userName}</span>
      </div>
      <div className="tile-content-row">
        <span className="content-key">{I18n.t('transaction-history/table-header-transaction-time')}</span>
        <span className="content-value">{transaction.transactionTime}</span>
      </div>
      <div className="tile-content-row">
        <span className="content-key">{I18n.t('transaction-history/table-header-transaction-id')}</span>
        <span className="content-value">{transaction.transactionId}</span>
      </div>
      <div className="tile-content-row">
        <span className="content-key">{I18n.t('transaction-history/table-header-provider')}</span>
        <span className="content-value">{transaction.provider}</span>
      </div>
      <div className="tile-content-row">
        <span className="content-key">{I18n.t('transaction-history/table-header-transaction-status')}</span>
        <span className="content-value">{transaction.state}</span>
      </div>
    </>
  );
}

export default TransactionHistory;

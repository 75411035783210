import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';

import ModuleSelectorPopup from '../ModuleSelectorPopup';

import Authorization from '../Authorization';
import TextWithTooltip from '../TextWithTooltip';
import ResponsiveSubHeader from '../SubHeader/ResponsiveSubHeader';
import SideBarNavigationMobile from '../SideBarNavigationMobile';
import MobileHeaderLinks from '../MobileHeaderLinks';

import * as errorActions from '../../actions/error';
import * as entityThunks from '../../thunks/entity';
import { translate, getDateInLocalFormat, useIsBigScreen } from '../../helper/functions';
import { createSubmissionCallback } from '../ModuleSelectorPopup/module-selector-logic';

import './ModuleSelector.scss';

const ModuleSelector = (props) => {
  const {
    entityId,
    fetchEntities,
    modules,
    filterAutoCreate,
    history,
    token,
    moduleId,
    subPageTitle,
    throwError,
    numberOfEntities,
    isLoaded,
    navigation,
    backButton,
  } = props;
  const pathname = history && history.location ? history.location.pathname : '';
  const defaultSearchAndFilter = { search: '' };
  const [searches, setSearches] = useState(defaultSearchAndFilter);
  const [popup, popupSetter] = useState({
    isPopupOpen: false,
    moduleId: null,
    submissionName: '',
  });
  const [filteredModules, setFilteredModules] = useState([]);
  const [categories, setCategories] = useState([]);
  const isBigScreen = useIsBigScreen();

  useEffect(() => {
    if (!pathname.includes('new-processes') && !isLoaded) {
      fetchEntities();
    }
  }, []);

  useEffect(() => {
    if (Object.values(modules).length) {
      fetchCategories();
    }
  }, [modules]);
  useEffect(() => {
    if (Object.values(modules).some((m) => m._id === moduleId)) {
      popupSetter({
        isPopupOpen: true,
        moduleId,
        submissionName: translate(modules[moduleId].label) + ' ' + new Date().toLocaleDateString(),
      });
    }
    return () => {
      popupSetter({ isPopupOpen: false, moduleId: null, submissionName: '' });
    };
  }, [modules]);
  useEffect(() => {
    setFilteredModules(
      Object.values(modules).filter(
        (m) =>
          (filterAutoCreate ? !m.autoCreateEntity : m.autoCreateEntity) &&
          (translate(m.description).toLowerCase().includes(searches.search.toLowerCase()) ||
            translate(m.label).toLowerCase().includes(searches.search.toLowerCase()))
      )
    );
    createSubmission;
  }, [modules, searches]);
  const createSubmission = createSubmissionCallback(popup, modules, entityId, popupSetter);

  const headers = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  const fetchCategories = async () => {
    try {
      const response = await axios.get('/categories', headers);
      setCategories(
        response.data.filter((c) =>
          Object.values(modules)
            .filter((m) => (filterAutoCreate ? !m.autoCreateEntity : m.autoCreateEntity))
            .some((m) => m.categories.includes(c._id))
        )
      );
    } catch (e) {
      throwError(e);
    }
  };

  const renderButtons = (filteredModules) => {
    if (!filteredModules || !filteredModules.length) {
      return null;
    }

    return (
      <div className="cards-container">
        {filteredModules.map((item) => (
          <div className="card-wrapper" key={item._id}>
            <div
              className="card"
              onClick={() =>
                popupSetter({
                  isPopupOpen: true,
                  moduleId: item._id,
                  submissionName: translate(modules[item._id].label) + ' ' + getDateInLocalFormat(new Date()),
                })
              }
            >
              <div className="card-title">
                <TextWithTooltip textWithOverflow={translate(item.label)} textClassName="title" />
              </div>
              <span className="description" dangerouslySetInnerHTML={{ __html: translate(item.description) }}></span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const filterbarProps = {
    searchbar: {
      shown: true,
      resultsLength: filteredModules.length,
      type: 'text',
      placeholder: 'entity-details/search-for',
      onSearchChange: () => {
        setSearches({
          ...searches,
          search: event.target.value,
        });
      },
    },
    clearSearchAndFilter: () => setSearches(defaultSearchAndFilter),
  };

  const backBtnTitle =
    numberOfEntities > 0 ? 'entity-details/sidebar/back-button' : 'entity-details/sidebar/back-button-no-entity';

  return (
    <div className="module-selector-container">
      <ResponsiveSubHeader
        subPageTitle={subPageTitle}
        backButton={backButton}
        backBtnTitle={backBtnTitle}
        pathname={pathname}
        filterbarProps={filterbarProps}
      />
      {!isBigScreen && <SideBarNavigationMobile navigation={navigation} />}
      <div className="modules-container">
        {categories.length > 1
          ? categories.map((category) => (
              <div key={category._id} className="categories-container">
                <div className="category-title">{translate(category.label)}</div>
                {renderButtons(filteredModules.filter((m) => m.categories.includes(category._id)))}
              </div>
            ))
          : renderButtons(filteredModules)}
      </div>
      <ModuleSelectorPopup popup={popup} popupSetter={popupSetter} createSubmission={createSubmission} />
      {isBigScreen ? null : <MobileHeaderLinks />}
    </div>
  );
};

ModuleSelector.propTypes = {
  token: PropTypes.string,
  modules: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const entityId = ownProps && ownProps.params ? ownProps.params.entityId : null;
  const moduleId = ownProps.match && ownProps.match.params ? ownProps.match.params.moduleId : null;
  const numberOfEntities = state.entity && state.entity.entities && state.entity.entities.length;
  const isLoaded = state.entity && state.entity.isLoaded;

  return {
    token: state.auth.token,
    modules: state.modules.modules,
    userData: state.auth.userData,
    entityId,
    moduleId,
    numberOfEntities,
    isLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      throwError: errorActions.throwServerError,
      fetchEntities: entityThunks.fetchEntities,
    },
    dispatch
  );
}

export default Authorization(connect(mapStateToProps, mapDispatchToProps)(ModuleSelector), ['create-submission']);

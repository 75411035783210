/**
 * Selects the specified submssion with all groups and fields
 *
 * @export
 * @param {string} submissionId
 *
 * @returns {() => object} the submission selector
 */
export function selectSubmsission(submissionId) {
  return (state) => {
    return state.submissionsPlus;
  };
}

/**
 * Selects the group results for a submssion
 *
 * @export
 * @param {string} submissionId
 * @return {() => []} groupResults (groups with values)
 */
export function selectGroupResults(submissionId) {
  return (state) => {
    if (state.groups[submissionId]) {
      return Object.values(state.groups[submissionId]).map((g) => ({
        ...g,
        values: g.values.map((v) => state.fieldValues[submissionId][v]),
      }));
    } else {
      return [];
    }
  };
}

import { createSlice } from '@reduxjs/toolkit';

import { pageStates } from '../helper/payment';
import { types as authTypes } from '../actions/auth';

export const currentStageSlice = createSlice({
  name: 'currentStage',
  initialState: {
    processViewShowHiddenFields: false,
    paymentState: pageStates.waitingForPaymentInfo,
    popupWrapperShown: false,
    originalFieldValues: { isSet: false },
  },
  reducers: {
    removeCurrentStageData: () => undefined,
    updateCurrentStage: (state, { payload }) => {
      state.submissionId = payload.submissionId;
      state.stageId = payload.stageId;

      const currentStage = payload.stages[payload.stageId];
      if (currentStage) {
        const stageType = currentStage.type;
        state.type = stageType;

        if (stageType === 'payment') {
          state.isPaid = false;
          state.canNavigateToNext = false;
        } else {
          state.canNavigateToNext = true;
        }
      }
    },
    updatePaymentInfo: (state, { payload }) => {
      state.isPaid = payload.paid;
      if (state.type === 'payment') {
        state.canNavigateToNext = payload.state === pageStates.payed;
        state.paymentState = payload.state;
      } else {
        state.canNavigateToNext = true;
      }
    },
    toggleProcessViewShowHiddenFeilds: (state) => {
      state.processViewShowHiddenFields = !state.processViewShowHiddenFields;
    },
    setPopupWrapper: (state, action) => {
      state.popupWrapperShown = action.payload.isOpen;
    },
    setOriginalFieldValues: (state, { payload }) => {
      state.originalFieldValues.isSet = true;
      state.originalFieldValues.fieldValues = payload.fieldValues;
    },
  },
  extraReducers: {
    LOGOUT: () => {
      return {
        processViewShowHiddenFields: false,
        paymentState: pageStates.waitingForPaymentInfo,
        popupWrapperShown: false,
        originalFieldValues: { isSet: false },
      };
    },
  },
});

export const {
  removeCurrentStageData,
  updateCurrentStage,
  updatePaymentInfo,
  toggleProcessViewShowHiddenFeilds,
  setPopupWrapper,
  setOriginalFieldValues,
} = currentStageSlice.actions;
export default currentStageSlice.reducer;

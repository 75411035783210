import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import equal from 'fast-deep-equal';

import Svg from '../Svg';
import Popup from '../Popup';
import SelectButton from '../SelectButton';

import { completeSubmission, postResults } from '../../thunks/postresults';
import { toggleProcessViewShowHiddenFeilds } from '../../reducers/current-stage';
import { undoStageResults } from '../../reducers/submissions';
import { calculateRoutePath } from '../../helper/navigation';
import { isProcessViewEnabled, getStage } from '../../helper/functions';

import prev from '../../assets/blue_prev.svg';
import next from '../../assets/blue_next.svg';
import save from '../../assets/save.svg';
import adminUserAdmin from '../../assets/admin_user_admin_icon.svg';
import profile from '../../assets/user_icon_white.svg';

class NavigationButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavigateToNextPopupOpen: false,
      isNavigateToPrevPopupOpen: false,
      isSavePopupOpen: false,
    };
  }

  openNavigateToNextPopup = () => {
    this.setState({ isNavigateToNextPopupOpen: true });
  };

  closeNavigateToNextPopup = () => {
    this.setState({ isNavigateToNextPopupOpen: false });
  };

  openNavigateToPrevPopup = () => {
    this.setState({ isNavigateToPrevPopupOpen: true });
  };

  closeNavigateToPrevPopup = () => {
    this.setState({ isNavigateToPrevPopupOpen: false });
  };

  openSavePopup = () => {
    this.setState({ isSavePopupOpen: true });
  };

  closeSavePopup = () => {
    this.setState({ isSavePopupOpen: false });
  };

  handleComplete = (e) => {
    e.preventDefault();

    const submissionId = this.props.params.submissionId;
    const token = this.props.token;
    const userData = this.props.userData;
    const usersMainRoute = userData && userData.mainRoute;

    this.props.completeSubmission(submissionId, token, () => {
      this.props.history.push(
        calculateRoutePath(usersMainRoute, `/client-management/entity/${this.props.submission.entityId}`)
      );
    });
  };

  handleSubmit = (e, nextStageId, complete = false) => {
    e.preventDefault();
    const {
      stageId,
      submission,
      params,
      token,
      stageResults,
      userData,
      history,
      fields,
      originalFieldValues,
    } = this.props;
    const submissionId = params.submissionId;
    const usersMainRoute = userData && userData.mainRoute;

    const routeToPush = complete
      ? submission.entityId
        ? `/client-management/entity/${submission.entityId}`
        : '/client-management'
      : `/client-management/${params.submissionId}/${nextStageId}`;

    let nextStageIsDocumentLibrary = false;

    if (nextStageId && this.props.stages[nextStageId].type === 'document-library') {
      nextStageIsDocumentLibrary = true;
    }

    if (
      this.props.stage.type !== 'data-input' ||
      (stageResults.length > 0 && !equal(originalFieldValues.fieldValues, stageResults)) ||
      nextStageIsDocumentLibrary
    ) {
      this.props.postResults(submissionId, stageId, token, stageResults, fields, (status) => {
        if (status.state === 'success') {
          this.navigateToNextStage(routeToPush, usersMainRoute, history);
        }
      });
    } else {
      this.navigateToNextStage(routeToPush, usersMainRoute, history);
    }
  };

  navigateToNextStage = (routeToPush, usersMainRoute, history) => {
    history.push(calculateRoutePath(usersMainRoute, routeToPush));
  };

  handleSave = (e) => {
    e.preventDefault();
    const { stageId, params, token, stageResults, fields } = this.props;
    const submissionId = params.submissionId;

    this.props.postResults(submissionId, stageId, token, stageResults, fields, (status) => {
      this.props.setOriginalValuesToOutOfDate();
    });
  };

  handlePreviousClick = (prevStageId) => {
    if (!prevStageId) {
      return;
    }

    this.props.undoStageResults({
      submissionId: this.props.submission._id,
      fieldValues: this.props.originalFieldValues.fieldValues,
    });

    if (this.props.isDirty) {
      this.openNavigateToPrevPopup();
    } else {
      let prevLink;
      if (isProcessViewEnabled(this.props.userData.rules)) {
        this.props.setOriginalValuesToOutOfDate();
        prevLink = `/client-management/${this.props.params.submissionId}/${prevStageId}`;
      } else {
        prevLink = `/${this.props.params.submissionId}/${prevStageId}`;
      }

      this.props.history.push(prevLink);
    }
  };

  render() {
    const { filteredStageMeta, stage, submission, stageResults, params, module } = this.props;
    const { isBlocked, completed } = submission;

    if (!submission || !filteredStageMeta.length) {
      return null;
    }

    const currentPosition = filteredStageMeta.findIndex((sm) => sm.stageId === stage._id);
    const blockedPosition = filteredStageMeta.findIndex((sm) => sm.stageId === isBlocked);
    const prevStageId = currentPosition > 0 ? filteredStageMeta[currentPosition - 1].stageId : null;
    const nextStageId =
      currentPosition + 1 < filteredStageMeta.length ? filteredStageMeta[currentPosition + 1].stageId : null;
    const notBlocked = !isBlocked || currentPosition < blockedPosition;

    const formValid = !stageResults.find((stageResult) => {
      return stageResult.values.find((value) => {
        return value.error;
      });
    });

    return (
      <div className="prev-next-container form-style">
        <NavigateAwayPopup
          isOpen={this.state.isNavigateToNextPopupOpen}
          handleClose={this.closeNavigateToNextPopup}
          history={this.props.history}
          submissionId={params.submissionId}
          nextStageId={nextStageId}
          originalFieldValues={this.props.originalFieldValues.fieldValues}
          setOriginalValuesToOutOfDate={this.props.setOriginalValuesToOutOfDate}
          undoStageResults={this.props.undoStageResults}
        />
        <NavigateAwayPopup
          isOpen={this.state.isNavigateToPrevPopupOpen}
          handleClose={this.closeNavigateToPrevPopup}
          history={this.props.history}
          submissionId={params.submissionId}
          nextStageId={prevStageId}
          originalFieldValues={this.props.originalFieldValues.fieldValues}
          setOriginalValuesToOutOfDate={this.props.setOriginalValuesToOutOfDate}
          undoStageResults={this.props.undoStageResults}
        />
        <SavePopup
          isOpen={this.state.isSavePopupOpen}
          handleOpen={this.openSavePopup}
          handleClose={this.closeSavePopup}
          handleOk={this.handleSave}
        />
        <ButtonContainer
          prevStageId={prevStageId}
          nextStageId={nextStageId}
          completed={completed}
          module={module}
          userData={this.props.userData}
          formValid={formValid}
          stage={stage}
          notBlocked={notBlocked}
          isBlocked={isBlocked}
          submission={submission}
          submissionId={params.submissionId}
          isDirty={this.props.isDirty}
          history={this.props.history}
          handlePreviousClick={this.handlePreviousClick}
          handleComplete={this.handleComplete}
          handleSubmit={this.handleSubmit}
          handleSave={this.handleSave}
          openNavigateToNextPopup={this.openNavigateToNextPopup}
          openSavePopup={this.openSavePopup}
          setOriginalValuesToOutOfDate={this.props.setOriginalValuesToOutOfDate}
        />
      </div>
    );
  }
}

NavigationButtons.propTypes = {
  authenticated: PropTypes.bool,
  token: PropTypes.string,
  isDirty: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  const stageMeta =
    ownProps.submission &&
    state.modules.modules[ownProps.submission.moduleId] &&
    state.modules.modules[ownProps.submission.moduleId].stageMeta
      ? state.modules.modules[ownProps.submission.moduleId].stageMeta
      : [];
  const stage = getStage(state.modules.stages, stageMeta, ownProps.params.stageId, state.auth.userData.isLimited);
  const stageGroups = stage.groupMeta.map((gm) => gm.groupId);
  const stageResults =
    ownProps.submission && ownProps.submission.data
      ? ownProps.submission.data.filter((s) => stageGroups.includes(s.groupId))
      : [];
  const stageId = ownProps.params.stageId;

  return {
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    entities: state.entity.entities,
    stages: state.modules.stages,
    userData: state.auth.userData,
    module: ownProps.submission ? state.modules.modules[ownProps.submission.moduleId] : null,
    fields: state.modules.fields,
    stage,
    stageId,
    stageResults,
    stageMeta,
    stages: state.modules.stages,
    originalFieldValues: state.currentStage.originalFieldValues,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      completeSubmission,
      postResults,
      undoStageResults,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationButtons);

function NavigateAwayPopup({
  isOpen,
  handleClose,
  history,
  submissionId,
  nextStageId,
  originalFieldValues,
  setOriginalValuesToOutOfDate,
  undoStageResults,
}) {
  const handleOk = () => {
    handleClose();
    undoStageResults({
      submissionId,
      fieldValues: originalFieldValues,
    });
    setOriginalValuesToOutOfDate();
    navigateToNext(history, submissionId, nextStageId);
  };

  return (
    <Popup
      popupShown={isOpen}
      popupTitle={I18n.t('general/navigate-away-promt')}
      cancelHandler={() => {
        handleClose();
      }}
      okHandler={handleOk}
    >
      <div className="form-container">
        <span>{I18n.t('general/navigate-away-promt-text')}</span>
      </div>
    </Popup>
  );
}

function navigateToNext(history, submissionId, nextStageId) {
  history.push(`/client-management/${submissionId}/${nextStageId}`);
}

function SavePopup({ isOpen, handleClose, handleOk }) {
  const handleOkClick = (e) => {
    handleClose();
    handleOk(e);
  };

  return (
    <Popup
      popupShown={isOpen}
      popupTitle={I18n.t('general/save-promt')}
      cancelHandler={() => handleClose()}
      okHandler={handleOkClick}
    >
      <div className="form-container">
        <span>{I18n.t('general/save-promt-text')}</span>
      </div>
    </Popup>
  );
}

function ButtonContainer({
  prevStageId,
  nextStageId,
  completed,
  module,
  userData,
  formValid,
  stage,
  notBlocked,
  isBlocked,
  submission,
  submissionId,
  isDirty,
  history,
  handlePreviousClick,
  handleComplete,
  handleSubmit,
  handleSave,
  openNavigateToNextPopup,
  openSavePopup,
  setOriginalValuesToOutOfDate,
}) {
  const isProcessView = isProcessViewEnabled(userData.rules);

  return (
    <div className="button-container">
      {isProcessView && <HideButton />}
      {isProcessView && <UserViewButtonGroup submissionId={submissionId} />}
      <PrevButton
        isProcessView={isProcessView}
        submissionId={submissionId}
        prevStageId={prevStageId}
        completed={completed}
        handlePreviousClick={handlePreviousClick}
        isBlocked={isBlocked}
      />
      {nextStageId || module.autoCompleteSubmission ? (
        <NextButtons
          isProcessView={isProcessView}
          handleComplete={handleComplete}
          handleSubmit={handleSubmit}
          handleSave={handleSave}
          nextStageId={nextStageId}
          autoCompleteSubmission={module.autoCompleteSubmission}
          formValid={formValid}
          stageType={stage.type}
          notBlocked={notBlocked}
          completed={completed}
          submissionLoading={submission.loading}
          submissionId={submissionId}
          isDirty={isDirty}
          history={history}
          openNavigateToNextPopup={openNavigateToNextPopup}
          openSavePopup={openSavePopup}
          setOriginalValuesToOutOfDate={setOriginalValuesToOutOfDate}
        />
      ) : null}
    </div>
  );
}

function NextButtons({
  isProcessView,
  handleComplete,
  handleSubmit,
  handleSave,
  nextStageId,
  autoCompleteSubmission,
  formValid,
  stageType,
  notBlocked,
  completed,
  submissionLoading,
  submissionId,
  isDirty,
  history,
  openNavigateToNextPopup,
  openSavePopup,
  setOriginalValuesToOutOfDate,
}) {
  const canNavigateToNext = useSelector((state) => state.currentStage.canNavigateToNext);

  const handleNextClick = () => {
    if (isDirty) {
      openNavigateToNextPopup();
    } else {
      setOriginalValuesToOutOfDate();
      navigateToNext(history, submissionId, nextStageId);
    }
  };

  const handleSaveClick = (e) => {
    if (isDirty) {
      openSavePopup();
    } else {
      handleSave(e);
    }
  };

  if (isProcessView) {
    return (
      <>
        {nextStageId && (
          <button className="next small" onClick={handleNextClick}>
            <Svg src={next} otherColor="#FFF" />
          </button>
        )}
        <button onClick={handleSaveClick} className="next small" disabled={!isDirty || completed}>
          <Svg src={save} otherColor="#FFF" />
        </button>
      </>
    );
  } else {
    if (
      (formValid || stageType !== 'data-input') &&
      notBlocked &&
      !completed &&
      !submissionLoading &&
      (canNavigateToNext === undefined || canNavigateToNext)
    ) {
      if (!nextStageId && autoCompleteSubmission) {
        return (
          <button onClick={(e) => handleComplete(e)} className="next">
            <span>{I18n.t('general/complete-submission')}</span>
            <Svg src={next} otherColor="#FFF" />
          </button>
        );
      } else {
        return (
          <button onClick={(e) => handleSubmit(e, nextStageId, false)} className="next">
            <span>{I18n.t('general/save-and-next')}</span>
            <Svg src={next} otherColor="#FFF" />
          </button>
        );
      }
    } else {
      return (
        <button className="next inactive">
          <span>{I18n.t('general/save-and-next')}</span>
          <Svg src={next} otherColor="#FFF" />
        </button>
      );
    }
  }
}

function PrevButton({ isProcessView, submissionId, prevStageId, completed, handlePreviousClick, isBlocked }) {
  const stageHistory = useSelector((state) => state.submissions[submissionId].stageHistory);

  if (isProcessView) {
    return (
      <button className={`prev small ${!prevStageId && 'inactive'}`} onClick={() => handlePreviousClick(prevStageId)}>
        <Svg src={prev} otherColor="#FFF" />
      </button>
    );
  } else {
    return (
      <>
        {prevStageId && !completed && (isBlocked === null || stageHistory.includes(isBlocked)) ? (
          <button className="prev" onClick={() => handlePreviousClick(prevStageId)}>
            <Svg src={prev} otherColor="#FFF" />
            <span>{I18n.t('general/previous')}</span>
          </button>
        ) : (
          <button className="prev inactive">
            <Svg src={prev} otherColor="#FFF" />
            <span>{I18n.t('general/previous')}</span>
          </button>
        )}
      </>
    );
  }
}

function UserViewButtonGroup({ submissionId }) {
  const buttonsMeta = [
    {
      navlink: false,
      to: '#',
      text: I18n.t('general/process-view'),
      className: 'subheader-button',
    },
    {
      navlink: true,
      to: `/client-management/admin/${submissionId}/data`,
      text: I18n.t('submission/switch-to-admin-view'),
      className: 'subheader-button',
    },
    {
      navlink: true,
      to: `/client-management/admin/${submissionId}/documents`,
      text: I18n.t('admin-submission/switch-to-library'),
      className: 'subheader-button',
    },
    {
      navlink: true,
      to: `/client-management/admin/${submissionId}/transactions`,
      text: I18n.t('submission/switch-to-transaction-history'),
      className: 'subheader-button',
    },
  ];

  return <SelectButton buttonsMeta={buttonsMeta} />;
}

function HideButton() {
  const dispatch = useDispatch();
  const processViewShowHiddenFields = useSelector((state) => state.currentStage.processViewShowHiddenFields);

  return (
    <span className="next small" onClick={() => dispatch(toggleProcessViewShowHiddenFeilds())}>
      <Svg
        src={processViewShowHiddenFields ? adminUserAdmin : profile}
        hasHover={true}
        otherColor={clientConfig.colors.headerText}
        flipColors={true}
      />
    </span>
  );
}

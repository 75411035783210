import { types } from '../actions/auth';

const mainRoutes = [
  {
    route: '/client-management',
    rule: 'list-submissions',
  },
  {
    route: '/user-management',
    rule: 'list-users',
  },
  {
    route: '/data-management',
    rule: 'list-predefined-groups',
  },
];

export default function (
  state = {
    authenticated: false,
    token: null,
    userData: null,
  },
  action
) {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        authenticated: true,
        token: action.data.token,
        locale: action.data.locale,
        userData: {
          ...action.data.userData,
          mainRoute:
            mainRoutes.filter((r) => action.data.userData.rules.some((rule) => r.rule === rule)).length === 1
              ? mainRoutes.filter((r) => action.data.userData.rules.some((rule) => r.rule === rule))[0].route
              : null,
        },
      };
    case types.LOGOUT:
      return {
        ...state,
        authenticated: false,
        token: null,
        userData: null,
      };

    case types.SET_USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.data,
        },
      };

    default:
      return state;
  }
}

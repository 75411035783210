import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';

import { isUserAllowed } from '../../helper/navigation';


const Authorization = (WrappedComponent, allowedRules) =>
  class WrappedInAuthorization extends React.Component {

    render() {
      const { userData, authenticated } = this.props;
      if (!authenticated) return null
      return isUserAllowed(userData, allowedRules) ? 
        <WrappedComponent {...this.props} /> : <Redirect to="/" />;
    }
  }

function mapStateToProps(state, ownProps) {
  return {
    userData: state.auth.userData,
    authenticated: state.auth.authenticated
  };
}

export default compose(connect(mapStateToProps, null), Authorization);

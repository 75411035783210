import React, { useState } from 'react';

import FormTooltip from '../FormTooltip';
import { useIsBigScreen } from '../../helper/functions';

import info_icon from '../../assets/info_icon_blue.svg';

function InfoBox({ text = '' }) {
  const isBigScreen = useIsBigScreen();
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);
  const handleClick = () => setTooltipIsVisible(!tooltipIsVisible);

  if (isBigScreen) {
    return <FormTooltip text={text} />;
  } else {
    if (!text) {
      return null;
    } else {
      return (
        <>
          <img alt="info" src={info_icon} onClick={handleClick} />
          {tooltipIsVisible ? <FormTooltip text={text} /> : null}
        </>
      );
    }
  }
}

export default InfoBox;

import React from 'react';

import Svg from '../Svg';
import AuthComponentWrapper from '../AuthComponentWrapper';

import trash from '../../assets/blue_trash.svg';

const DeleteButton = (props) => {
  const { onClick, text, disabled = false, tooltipText, rules } = props;

  return (
    <AuthComponentWrapper allowedRules={rules} >
      <div className="delete-container">
        <div className="button-container">
          <button onClick={onClick} disabled={disabled}>
            <Svg src={trash} otherColor="#FFFFFF" />
            <span>{text}</span>
          </button>
        </div>
        {disabled && (
          <div className="disabled-tooltip">
            <span>{tooltipText}</span>
          </div>
        )}
      </div>
    </AuthComponentWrapper>
  );
}

export default DeleteButton;
import React, { useRef, useEffect } from "react";

import './EditableName.scss';

import pencil from '../../assets/pencil_icon.svg';

const EditableName = (props) => {
  const { isEditing, name, value, editor, setter, objectToEdit, setActivity } = props
  const wrapperRef = useRef(null);
  const editRef = useRef(null);
  const handleClick = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setter(event, objectToEdit)
    }
    if (editRef.current && editRef.current.contains(event.target)) {
      setTimeout(() => {
        if (wrapperRef.current) {
          const valueLength = wrapperRef.current.value.length;
          wrapperRef.current.focus();
          wrapperRef.current.setSelectionRange(valueLength, valueLength);
        }
      }, 200);
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  return (<div className={"name-input-switcher " + (isEditing ? "active" : "")}
    onClick={(e) => {
      e.stopPropagation();
      setActivity(objectToEdit._id);
      editor(objectToEdit.name);
    }}>
    {isEditing ?
      <input
        ref={wrapperRef}
        value={value}
        onChange={(e) => { editor(e.target.value) }}
        onKeyPress={(e) => setter(e, objectToEdit)}>
      </input>
      :
      <p>{name}</p>}
    <div ref={editRef} className={"pencil " + (isEditing ? "active" : "")}>
      <img src={pencil} />
    </div>
  </div>)
}

export default EditableName;
import React, { useState } from 'react';

import WrappedLink from '../WrappedLink';
import Svg from '../Svg';
import OutsideAlerter from './OutsideAlerter';

import arrow_down from '../../assets/arrow_down.svg';
import arrow_up from '../../assets/arrow_up.svg';
import './SelectButton.scss';

const SelectButton = ({ buttonsMeta }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (buttonsMeta.length === 0) {
    return null;
  }

  const firstButtonMeta = buttonsMeta[0];
  const restButtonsMeta = buttonsMeta.filter((_, index) => index > 0);

  return (
    <OutsideAlerter callback={() => setIsOpen(false)}>
      <div className="select-button">
        <FirstButton buttonMeta={firstButtonMeta} isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className={'rest-container' + (isOpen ? ' open' : '')}>
          {restButtonsMeta.map((button, index) => {
            return (
              <WrappedLink
                key={index}
                className="rest"
                navLink={button.navlink}
                to={button.to}
                onClick={() => setIsOpen(false)}
              >
                <button className={button.className}>{button.text}</button>
              </WrappedLink>
            );
          })}
        </div>
      </div>
    </OutsideAlerter>
  );
};

export default SelectButton;

function FirstButton({ buttonMeta, isOpen, setIsOpen }) {
  return (
    <button className={buttonMeta.className + (isOpen ? ' open' : '')} onClick={() => setIsOpen(!isOpen)}>
      {buttonMeta.text}
      <div className="icon">
        <Svg src={isOpen ? arrow_up : arrow_down} otherColor="#FFF" />
      </div>
    </button>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';
import axios from 'axios';

import Svg from '../Svg/Svg';
import Comment from '../Comment';
import Group from '../Group';
import StatusSelector from '../StatusSelector';
import Separator from '../Separator';

import { postResults } from '../../thunks/postresults';
import * as errorActions from '../../actions/error';
import { storeOriginalValues, restoreOriginalValues, setSubmissionSettings } from '../../reducers/submissions';

import { translate, getNiceValueFormat, transformValue, useIsBigScreen } from '../../helper/functions';

import './AdminGroupResult.scss';

import edit from '../../assets/edit.svg';
import eye from '../../assets/eye.svg';
import eye_no from '../../assets/eye_no.svg';
import cancel from '../../assets/cancel.svg';
import save from '../../assets/save.svg';

const settingsOptionValues = {
  state: ['approved', 'pending', 'rejected'],
};

const specialCharacters = /[$-/:-?{-~!"^_`\[\]]/;

class AdminGroupResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
    };

    this.editClick = this.editClick.bind(this);
    this.setState = this.setState.bind(this);
  }

  searchMatch = (fieldResult) => {
    const field = this.props.fields[fieldResult.fieldId];
    const lowerCaseSearch = this.props.search.toLowerCase();
    const actualLabel =
      !field.label || translate(field.label).length === 0 ? translate(field.placeholder) : translate(field.label);
    return (
      (fieldResult.value &&
        getNiceValueFormat(field, fieldResult.value).toString().toLowerCase().includes(lowerCaseSearch)) ||
      actualLabel.toLowerCase().includes(lowerCaseSearch)
    );
  };

  handleSelectChange = (event, result, resultFieldId) => {
    this.setFieldSettings('state', event.target.dataset.state, result, resultFieldId);
  };

  setFieldSettings = (key, value, result, resultFieldId) => {
    const { submissionId, token } = this.props;
    const groupSettings = {
      resultGroupId: result.resultId,
      fields: [],
    };
    if (!resultFieldId) {
      groupSettings[key] = value;
      groupSettings.fields = result.values.map((fieldValue) => {
        return {
          resultFieldId: fieldValue.resultFieldId,
          [key]: value,
        };
      });
    } else {
      if (key === 'state') {
        let lowestSettingValueIndex = 0;
        result.values.forEach((fieldValue) => {
          const fieldSettingValue = resultFieldId === fieldValue.resultFieldId ? value : fieldValue[key];
          const fieldSettingValueIndex = settingsOptionValues[key].findIndex(
            (optionValue) => optionValue === fieldSettingValue
          );
          if (fieldSettingValueIndex > lowestSettingValueIndex) {
            lowestSettingValueIndex = fieldSettingValueIndex;
          }
        });
        groupSettings[key] = settingsOptionValues[key][lowestSettingValueIndex];
        groupSettings.fields = [
          {
            resultFieldId,
            [key]: value,
          },
        ];
      } else {
        groupSettings.fields = [
          {
            resultFieldId,
            [key]: value,
          },
        ];
        const fieldSettings = result.values.map((fieldValue) =>
          resultFieldId === fieldValue.resultFieldId ? value : fieldValue[key]
        );

        if (
          fieldSettings.every((fieldSetting) => fieldSetting) ||
          fieldSettings.every((fieldSetting) => !fieldSetting)
        ) {
          groupSettings[key] = value;
        } else {
          groupSettings[key] = result[key];
        }
      }
    }
    axios
      .put('/admin/drf/submissions/' + submissionId + '/set-states', [groupSettings], {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        this.props.setSubmissionSettings({ submissionId, resultId: result.resultId, key, groupSettings });
      })
      .catch((e) => {
        this.props.throwError(e);
      });
  };

  handleEditSave = (result) => {
    const { stageId, submissionId, postResults, token, fields } = this.props;
    postResults(submissionId, stageId, token, [result], fields, (status) => {
      if (status.state === 'success') {
        this.setState({ editMode: false });
      }
    });
  };

  editClick() {
    this.setState((state) => ({ editMode: false }));
    this.props.restoreOriginalValues({ submissionId: this.props.submissionId });
  }

  render() {
    const { fields, groups, groupMetaOptions, result, search, referenceResult } = this.props;
    const { editMode } = this.state;
    const group = groups[result.groupId];

    const filteredFieldMeta = search
      ? group.fieldMeta.filter(({ fieldId }) => {
          const field = fields[fieldId];
          const fieldResult = result.values.find((resultValue) => resultValue.fieldId === fieldId) || {};
          const lowerCaseSearch = search.toLowerCase();
          const actualLabel =
            !field.label || translate(field.label).length === 0 ? translate(field.placeholder) : translate(field.label);
          return (
            (fieldResult.value &&
              getNiceValueFormat(field, fieldResult.value).toString().toLowerCase().includes(lowerCaseSearch)) ||
            actualLabel.toLowerCase().includes(lowerCaseSearch)
          );
        })
      : group.fieldMeta;
    if (filteredFieldMeta.length === 0) {
      return null;
    }

    const groupHidden = 'hidden' in result ? result.hidden : groupMetaOptions.hidden;
    const groupReadonly = 'readonly' in result ? result.readonly : groupMetaOptions.readonly;
    return (
      <div className="admin-data-section-container">
        <GroupHeader
          result={result}
          groupHidden={groupHidden}
          groupLabel={translate(group.label)}
          referenceName={transformValue(group.referenceNameTemplate, result.values, fields)}
          groupReadonly={groupReadonly}
          submissionId={this.props.submissionId}
          isSubmissionCompleted={this.props.isSubmissionCompleted}
          editMode={editMode}
          editClick={this.editClick}
          restoreOriginalValues={this.props.storeOriginalValues}
          storeOriginalValues={this.props.storeOriginalValues}
          setState={this.setState}
          handleSelectChange={this.handleSelectChange}
          setFieldSettings={this.setFieldSettings}
          handleEditSave={this.handleEditSave}
        />

        {editMode ? (
          <div className="form-container edit-group-container">
            <Group
              groupMeta={{ options: null }}
              groupResults={[result]}
              group={group}
              submissionId={this.props.submissionId}
              key={group._id}
              adminEditMode={true}
            />
          </div>
        ) : (
          <div className="content-rows" key={result._id}>
            <ContentRows
              filteredFieldMeta={filteredFieldMeta}
              fields={fields}
              result={result}
              group={group}
              submissionId={this.props.submissionId}
              groupHidden={groupHidden}
              groupReadonly={groupReadonly}
              setFieldSettings={this.setFieldSettings}
              referenceResult={referenceResult}
              handleSelectChange={this.handleSelectChange}
            />
          </div>
        )}
      </div>
    );
  }
}

AdminGroupResult.propTypes = {
  token: PropTypes.string,
  groups: PropTypes.object,
  fields: PropTypes.object,
  setSubmissionSettings: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const submission = state.submissionsPlus[ownProps.submissionId] || {};
  let referenceResult;

  if (submission.data && ownProps.result.referenceResultId) {
    referenceResult = submission.data.find(
      (r) => state.groups[ownProps.submissionId][r].resultId === ownProps.result.referenceResultId
    );
  }

  let isSubmissionLoading;
  let isSubmissionCompleted;

  if (state.submissionsPlus[ownProps.submissionId]) {
    isSubmissionLoading = state.submissionsPlus[ownProps.submissionId].loading;
    isSubmissionCompleted = state.submissionsPlus[ownProps.submissionId].completed;
  }

  return {
    token: state.auth.token,
    groups: state.modules.groups,
    fields: state.modules.fields,
    referenceResult,
    isSubmissionLoading,
    isSubmissionCompleted,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSubmissionSettings,
      storeOriginalValues,
      restoreOriginalValues,
      postResults,
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminGroupResult);

function ContentRows({
  filteredFieldMeta,
  fields,
  result,
  group,
  submissionId,
  groupHidden,
  groupReadonly,
  referenceResult,
  setFieldSettings,
  handleSelectChange,
}) {
  const isBigScreen = useIsBigScreen();
  const isSubmissionCompleted = useSelector((state) => state.submissionsPlus[submissionId].completed);

  const statusSelector = (
    <StatusSelector state={'pending'} isDisabled={isSubmissionCompleted} handleSelectChange={() => {}} />
  );

  return filteredFieldMeta.map(({ fieldId }, i) => {
    const field = fields[fieldId];
    const value = result.values.find((v) => v.fieldId === fieldId) || {};
    const fieldState = value.state || result.state;
    const fieldMeta = group.fieldMeta.find((fm) => fm.fieldId === fieldId);
    const defaultHidden = 'hidden' in fieldMeta.options;
    const fieldHidden = 'hidden' in value ? value.hidden : groupHidden || defaultHidden;
    let isReferencedField = false;

    if (
      result.referenceResultId &&
      referenceResult &&
      referenceResult.values &&
      referenceResult.values.find((v) => v.fieldId === fieldId)
    ) {
      isReferencedField = true;
    }

    const fieldReadonly = ('readonly' in value ? value.readonly : groupReadonly) || isReferencedField;
    if (field.type === 'separator' && field.multiline) {
      return null;
    }
    if (field.type === 'separator')
      return <Separator key={fieldId} {...field} readonly={!!fieldMeta.options.readonly} />;

    const fieldLabel =
      !field.label || translate(field.label).length === 0 ? translate(field.placeholder) : translate(field.label);

    return (
      <GroupRow
        key={i}
        value={value}
        fieldHidden={fieldHidden}
        setFieldSettings={setFieldSettings}
        result={result}
        fieldLabel={fieldLabel}
        fieldValue={getNiceValueFormat(field, value.value)}
        isReferencedField={isReferencedField}
        fieldReadonly={fieldReadonly}
        fieldState={fieldState}
        isBigScreen={isBigScreen}
        isSubmissionCompleted={isSubmissionCompleted}
        handleSelectChange={handleSelectChange}
        statusSelector={statusSelector}
      />
    );
  });
}

function GroupHeader({
  result,
  groupHidden,
  groupLabel,
  referenceName,
  groupReadonly,
  submissionId,
  isSubmissionCompleted,
  editMode,
  editClick,
  restoreOriginalValues,
  storeOriginalValues,
  setState,
  handleSelectChange,
  setFieldSettings,
  handleEditSave,
}) {
  const isBigScreen = useIsBigScreen();
  const groupHasError = result.values.some((r) => r.error != null);

  if (isBigScreen) {
    return (
      <div className={'group-header ' + (editMode ? 'edit' : '')}>
        <div className={'group-header-title ' + (result.state ? result.state : '')}>
          <div className={`img-container eye ${result.resultId ? '' : 'inactive'}`}>
            <Svg
              src={groupHidden ? eye_no : eye}
              hasHover={true}
              onClick={() => setFieldSettings('hidden', !groupHidden, result)}
            />
          </div>
          <span>{groupLabel}</span>
          <span className="group-header-reference-name">{referenceName}</span>
        </div>
        <div className={`switcher ${result.resultId ? '' : 'inactive'}`}>
          <label className="switch">
            <input
              type="checkbox"
              checked={groupReadonly ? true : false}
              onChange={() => setFieldSettings('readonly', !groupReadonly, result)}
            />
            <span className={`slider round ${groupReadonly ? 'off' : 'on'}`}></span>
          </label>
          <Translate
            className={`switcher-status ${groupReadonly ? 'off' : 'on'}`}
            value={groupReadonly ? 'datacheck/inactive' : 'datacheck/active'}
          />
        </div>
        <div className={`dropdown state-selector ${result.resultId ? '' : 'invisible'}`}>
          <StatusSelector
            state={result.state}
            isDisabled={isSubmissionCompleted}
            handleSelectChange={(event) => handleSelectChange(event, result)}
          />
        </div>
        {editMode && (
          <div className={`img-container edit ${isSubmissionCompleted ? 'inactive' : ''}`}>
            <Svg src={cancel} hasHover={true} onClick={editClick} />
          </div>
        )}
        <div className={`img-container edit ${isSubmissionCompleted ? 'inactive' : ''}`}>
          {editMode ? (
            <Svg
              src={save}
              hasHover={!groupHasError}
              otherColor={groupHasError ? 'grey' : undefined}
              onClick={() => !groupHasError && handleEditSave(result)}
            />
          ) : (
            <Svg
              src={edit}
              hasHover={true}
              onClick={() => {
                setState((state) => ({ editMode: true }));
                storeOriginalValues({ submissionId });
              }}
            />
          )}
        </div>
        <div className="img-container message">
          {!groupHidden && (
            <Comment
              comment={result.comment}
              submissionId={submissionId}
              target={{ resultId: result.resultId }}
              targetName={groupLabel}
              targetUrl={'/comment'}
            />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={'group-header-mobile ' + (result.state ? result.state : '')}>
        <div bp="full-width vertical-center flex" className={'group-header-title'}>
          <div className={`img-container eye ${result.resultId ? '' : 'inactive'}`}>
            <Svg
              src={groupHidden ? eye_no : eye}
              hasHover={true}
              onClick={() => setFieldSettings('hidden', !groupHidden, result)}
            />
          </div>
          <span>{groupLabel}</span>
        </div>
        <div bp="full-width vertical-center flex" className="icons">
          <div className={`dropdown state-selector ${result.resultId ? '' : 'invisible'}`}>
            <StatusSelector
              state={result.state}
              isDisabled={isSubmissionCompleted}
              handleSelectChange={(event) => handleSelectChange(event, result)}
            />
          </div>
          <div className="icons-left">
            <div className={`switcher ${result.resultId ? '' : 'inactive'}`}>
              <label className="switch">
                <span className={`slider round ${groupReadonly ? 'off' : 'on'}`}></span>
                <input
                  type="checkbox"
                  checked={groupReadonly ? true : false}
                  onChange={() => setFieldSettings('readonly', !groupReadonly, result)}
                />
              </label>
              <Translate
                className={`switcher-status ${groupReadonly ? 'off' : 'on'}`}
                value={groupReadonly ? 'datacheck/inactive' : 'datacheck/active'}
              />
            </div>

            {editMode && (
              <div className={`img-container edit ${isSubmissionCompleted ? 'inactive' : ''}`}>
                <Svg
                  src={cancel}
                  hasHover={true}
                  onClick={() => {
                    setState((state) => ({ editMode: false }));
                    restoreOriginalValues({ submissionId });
                  }}
                />
              </div>
            )}
            <div className={`img-container edit ${isSubmissionCompleted ? 'inactive' : ''}`}>
              {editMode ? (
                <Svg
                  src={save}
                  hasHover={!groupHasError}
                  otherColor={groupHasError ? 'grey' : undefined}
                  onClick={() => !groupHasError && handleEditSave(result)}
                />
              ) : (
                <Svg
                  src={edit}
                  hasHover={true}
                  onClick={() => {
                    setState((state) => ({ editMode: true }));
                    storeOriginalValues({ submissionId });
                  }}
                />
              )}
            </div>
            <div className="img-container message">
              {!groupHidden && (
                <Comment
                  comment={result.comment}
                  submissionId={submissionId}
                  target={{ resultId: result.resultId }}
                  targetName={groupLabel}
                  targetUrl={'/comment'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function GroupRow({
  value,
  fieldHidden,
  setFieldSettings,
  result,
  fieldLabel,
  fieldValue,
  isReferencedField,
  fieldReadonly,
  fieldState,
  isBigScreen,
  isSubmissionCompleted,
  handleSelectChange,
}) {
  if (isBigScreen) {
    return (
      <>
        <div className="row">
          <div className={`cell ${value.resultFieldId ? '' : 'disabled'}`}>
            <Svg
              hasHover={true}
              src={fieldHidden ? eye_no : eye}
              onClick={() => setFieldSettings('hidden', !fieldHidden, result, value.resultFieldId)}
            />
          </div>
          <div className="cell label">
            {fieldLabel.trim().slice(-1).match(specialCharacters) ? fieldLabel.trim().slice(0, -1) : fieldLabel.trim()}:
          </div>
          <div className="cell">
            <span>{fieldValue}</span>
          </div>
          <div className={`cell ${value.resultFieldId && !isReferencedField ? '' : 'disabled'}`}>
            <div className="table-cell switcher">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={fieldReadonly ? true : false}
                  onChange={() => setFieldSettings('readonly', !fieldReadonly, result, value.resultFieldId)}
                />
                <span className={`slider round ${fieldReadonly ? 'off' : 'on'}`}></span>
              </label>
              <Translate
                className={`switcher-status ${fieldReadonly ? 'off' : 'on'}`}
                value={fieldReadonly ? 'datacheck/inactive' : 'datacheck/active'}
              />
            </div>
          </div>
          <div className="cell">
            <div className={`dropdown state-selector ${value.resultFieldId ? '' : 'invisible'}`}>
              <StatusSelector
                state={fieldState}
                isDisabled={isSubmissionCompleted}
                handleSelectChange={(event) => handleSelectChange(event, result, value.resultFieldId)}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="row">
          <Svg
            className="eye"
            hasHover={true}
            src={fieldHidden ? eye_no : eye}
            onClick={() => setFieldSettings('hidden', !fieldHidden, result, value.resultFieldId)}
          />
          <div className="text-container">
            <span className="title">{fieldLabel}:</span>
            <span className="content">{fieldValue}</span>
          </div>
        </div>
        <div className="row">
          <div className={`dropdown state-selector ${value.resultFieldId ? '' : 'invisible'}`}>
            <StatusSelector
              state={fieldState}
              isDisabled={isSubmissionCompleted}
              handleSelectChange={(event) => handleSelectChange(event, result, value.resultFieldId)}
            />
          </div>
          <div className={`icons-left ${value.resultFieldId && !isReferencedField ? '' : 'disabled'}`}>
            <div className="table-cell switcher">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={fieldReadonly ? true : false}
                  onChange={() => setFieldSettings('readonly', !fieldReadonly, result, value.resultFieldId)}
                />
                <span className={`slider round ${fieldReadonly ? 'off' : 'on'}`}></span>
              </label>
              <Translate
                className={`switcher-status ${fieldReadonly ? 'off' : 'on'}`}
                value={fieldReadonly ? 'datacheck/inactive' : 'datacheck/active'}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import Select from 'react-select';

import Svg from '../Svg';

import * as errorActions from '../../actions/error';
import {
  deleteGroupResult as deleteGroupResultAction,
  addNewGroupResult,
  setStageResults as setStageResultsAction,
} from '../../reducers/submissions';
import { translate } from '../../helper/functions';

import './ServerTable.scss';

import sort from '../../assets/sort.svg';
import prev from '../../assets/blue_prev.svg';
import next from '../../assets/blue_next.svg';

const Checkbox = ({ name, checked = false, onChange }) => (
  <label className="checkbox-line">
    <input type="checkbox" name={name} checked={checked} onChange={onChange} />
    <span className="checkmark"></span>
  </label>
);

Checkbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

class ServerTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
      loading: true,
      serverData: [],
      totalCount: 0,
      filter: '',
      pagination: {
        page: 1,
        rowsPerPage: 25,
        sortBy: null,
        descending: false,
      },
      paginationOptions: [
        { value: 5, label: '5' },
        { value: 10, label: '10' },
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
      ],
      debounceTimer: null,
    };
  }

  componentDidMount() {
    this.refreshTable();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submissionId !== this.props.submissionId || prevProps.group._id !== this.props.group._id) {
      this.refreshTable();
    }
  }

  refreshTable() {
    this.setState({ loading: true });
    this.requestServerData();
  }

  handlePaginationChange(selectedOption) {
    const pagination = { ...this.state.pagination };
    pagination.rowsPerPage = selectedOption.value;

    this.setState({ pagination }, () => this.refreshTable());
  }

  handleSort(fieldId, index) {
    const sortKey = `values.${index}.value`;
    const pagination = { ...this.state.pagination };

    pagination.descending = !pagination.descending;

    pagination.sortBy = sortKey;

    this.setState({ pagination }, () => this.refreshTable());
  }

  addPredefinedGroup(id) {
    axios
      .get(`/drf/submissions/${this.props.submissionId}/predefined-groups/${id}`, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((response) => {
        const position = this.props.groupResults.length;
        this.props.addNewGroupResult({
          submissionId: this.props.submissionId,
          groupId: this.props.groupMeta.groupId,
          position,
        });
        this.props.setStageResults({
          submissionId: this.props.submissionId,
          groupResults: [{ ...response.data, predefinedId: response.data._id, position: position }],
        });
      })
      .catch((e) => this.props.throwError(e));
  }

  removeGroupResult(groupResult) {
    if (groupResult.resultId) {
      axios
        .delete(`/drf/submissions/${this.props.submissionId}/results/${groupResult.resultId}`, {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        })
        .then(() => {})
        .catch((e) => this.props.throwError(e));
    }

    this.props.deleteGroupResult({
      submissionId: this.props.submissionId,
      groupId: groupResult.groupId,
      position: groupResult.position,
    });
  }

  handleCheckboxChange(e) {
    const groupResults = this.props.groupResults;

    const id = e.target.name;
    const isChecked = e.target.checked;

    if (isChecked) {
      this.addPredefinedGroup(id);
      return;
    }

    const groupResult = groupResults.find((item) => item.predefinedId === id);

    this.removeGroupResult(groupResult);
  }

  handleSearchChange(e) {
    if (this.state.debounceTimer) {
      clearTimeout(this.state.debounceTimer);
    }

    const debounceTimer = setTimeout(() => {
      this.refreshTable();
    }, 500);

    this.setState({ filter: e.target.value, debounceTimer });
  }

  buildRequestData({ submissionId, descriptorGroupId }) {
    const pagination = this.state.pagination;
    const params = {};

    if (pagination) {
      if (pagination.sortBy) {
        params._sort = pagination.sortBy;
        params._order = pagination.descending ? 'DESC' : 'ASC';
      }

      if (pagination.page) {
        params._start = (pagination.page - 1) * pagination.rowsPerPage;
      }

      params._end = pagination.rowsPerPage * pagination.page;
    }

    if (this.state.filter) {
      params.q = this.state.filter;
    }

    // this.processQueryFilters(params, this.state.filterValues);

    return {
      url: `/drf/submissions/${submissionId}/predefined-groups/table/${descriptorGroupId}`,
      params,
    };
  }

  // processQueryFilters(params, filters) {
  //   if (Object.keys(filters).length) {
  //     const dotNotated = dotObject.dot(filters);

  //     for (const key of Object.keys(dotNotated)) {
  //       const filterValue = _.get(filters, key);
  //       if (typeof filterValue === 'string' && filterValue === '') {
  //         // eslint-disable-next-line no-continue
  //         continue;
  //       }

  //       if (
  //         (_.isArray(filterValue) && filterValue.length) ||
  //         (_.isObject(filterValue) && Object.keys(filterValue).length < 1)
  //       ) {
  //         // eslint-disable-next-line no-continue
  //         continue;
  //       }

  //       _.set(params, key, filterValue);
  //     }
  //   }
  // }

  requestServerData() {
    const { submissionId, group } = this.props;

    const requestData = this.buildRequestData({ submissionId, descriptorGroupId: group._id });

    axios
      .get(requestData.url, {
        params: requestData.params,
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((response) => {
        this.setState({
          initialized: true,
          loading: false,
          serverData: response.data,
          totalCount: response.headers['x-total-count'],
        });
      })
      .catch((e) => {
        this.props.throwError(e);
      });

    return [];
  }

  getPaginationNumbers() {
    const pagination = { ...this.state.pagination };

    let start = 0;
    let end = pagination.rowsPerPage;

    if (pagination.page === 1) {
      return { start, end };
    }

    return {
      start: (pagination.page - 1) * pagination.rowsPerPage,
      end: pagination.page * pagination.rowsPerPage,
    };
  }

  render() {
    if (!this.state.initialized) {
      return null;
    }

    const { fields, group, groupResults } = this.props;
    const fieldIds = group.fieldMeta.map((item) => item.fieldId);
    const tableFields = [];
    for (const fieldId of fieldIds) {
      const field = fields[fieldId];
      if (field) {
        tableFields.push(field);
      }
    }

    const paginationNumbers = this.getPaginationNumbers();

    return (
      <div className={'table-container is-server'}>
        <div>{translate(group.description)}</div>
        <br />
        <input
          onChange={this.handleSearchChange.bind(this)}
          value={this.state.filter}
          type="text"
          placeholder="Search ..."
        ></input>
        <table>
          <thead>
            <tr>
              <th></th>
              {tableFields.map((field, index) => {
                return (
                  <th
                    className={'sortable'}
                    key={field._id}
                    onClick={() => {
                      this.handleSort(field._id, index);
                    }}
                  >
                    <div>
                      <div>{translate(field.label)}</div>
                      <div className={'sort-icon'}>
                        <img src={sort} />
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.serverData.map((data) => {
              return (
                <tr key={data._id}>
                  <td class="checkbox-container">
                    <Checkbox
                      name={data._id}
                      checked={groupResults.find((groupResult) => groupResult.predefinedId === data._id) ? true : false}
                      onChange={this.handleCheckboxChange.bind(this)}
                    />
                  </td>
                  {tableFields.map((tableField) => {
                    const field = data.values.find((item) => item.fieldId === tableField._id);
                    if (field) {
                      return <td key={tableField._id}>{field.value}</td>;
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={'table-control'}>
          <div className={'table-control-col pagination-select'}>
            <Translate value="server-table/pagination/rows-per-page" />
            <Select
              className="select-container"
              classNamePrefix="subject-select"
              menuPlacement="top"
              options={this.state.paginationOptions}
              onChange={this.handlePaginationChange.bind(this)}
              value={this.state.paginationOptions.find((item) => item.value === this.state.pagination.rowsPerPage)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: 'transparent',
                },
              })}
            />
          </div>
          <div className={'table-control-col'}>
            {paginationNumbers.start + 1} - {paginationNumbers.end} of {this.state.totalCount}
          </div>
          <div className={'table-control-col'}>
            <div className="button-container">
              {this.state.pagination.page !== 1 && !this.state.loading ? (
                <button
                  onClick={() => {
                    const pagination = { ...this.state.pagination };
                    pagination.page -= 1;

                    this.setState({ pagination }, () => this.refreshTable());
                  }}
                  className="prev"
                >
                  <Svg src={prev} />
                </button>
              ) : (
                <button className="prev inactive">
                  <Svg src={prev} />
                </button>
              )}
              {this.state.pagination.page * this.state.pagination.rowsPerPage < this.state.totalCount &&
              !this.state.loading ? (
                <button
                  onClick={() => {
                    const pagination = { ...this.state.pagination };
                    pagination.page += 1;

                    this.setState({ pagination }, () => this.refreshTable());
                  }}
                  className="next"
                >
                  <Svg src={next} />
                </button>
              ) : (
                <button className="next inactive">
                  <Svg src={next} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const submission = state.submissions[ownProps.submissionId];
  const results = submission ? submission.data : [];
  const groups = state.modules.groups;
  const group = groups[ownProps.groupMeta.groupId];

  return {
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    submission,
    groups,
    group,
    fields: state.modules.fields,
    results,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addNewGroupResult,
      deleteGroupResult: deleteGroupResultAction,
      setStageResults: setStageResultsAction,
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerTable);

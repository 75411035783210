import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { translate, fieldValidator, getNiceValueFormat, allConditionsMet } from '../../../helper/functions';
import { useDebouncedCallback } from '../../../helper/hooks';
import { useLoadSubmissionFromLocalStorage, setCheckboxValue } from './messenger-logic';
import { addNewGroupResult, changeValue } from '../../../reducers/submissions';
import {
  selectFieldMetasForGroups,
  selectFieldValuesForGroups,
  selectGroups,
  selectEditedField,
} from '../../../selectors/group-selectors';
import { Footer, InputArea } from './Footer/Footer';
import { getPhoneMask } from '../../Field/TextField/text-field';

import axialHead from '../../../assets/axial_head.svg';
import userHead from '../../../assets/user_head.svg';
import info_icon from '../../../assets/info_icon_blue.svg';
import error from '../../../assets/error.svg';
import './MessengerDataInput.scss';

function MessengerDataInput({
  theirName,
  myName,
  fieldsMeta,
  fieldValues,
  updateAnswer,
  nextStage,
  submissionId,
  history,
  editedField,
}) {
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [originalValue, setOriginalValue] = useState('');
  const [currentError, setCurrentError] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const resetTypingWithBounce = useDebouncedCallback(() => setIsTyping(false), 2000);

  useEffect(() => {
    if (editedField && Object.values(fieldValues).length > 0) {
      setCurrentAnswer(fieldValues[editedField.fieldId].value);
      setOriginalValue(fieldValues[editedField.fieldId].value);
      scrollToEditedQuestion(editedField.fieldId);
    }

    return function cleanup() {
      // window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    if (!editedField) {
      scrollToLastQuestion();
    }
  }, [fieldValues]);

  const handleFocus = useCallback(() => {
    if (!editedField) {
      scrollToLastQuestion();
    }
  }, [editedField]);

  const handleAnswerChange = (e) => {
    setIsTyping(true);
    resetTypingWithBounce();
    setCurrentAnswer(e.target.value);
  };

  const editMode = !!editedField;
  let isTextInputActive;
  const questionAnswerPairs = [];
  let isFormDone = true;

  let lastQuestion;

  const handleUpdateClick = (e) => {
    e.preventDefault();
    const error = fieldValidator(currentAnswer, editedField.validationRules, editedField.type);
    if (error) {
      setCurrentError({ fieldId: editedField.fieldId, error });
    } else {
      setCurrentError(null);
      setCurrentAnswer('');
      updateAnswer(editedField.groupId, editedField.fieldId, currentAnswer);
      history.push(`/${submissionId}/${nextStage._id}`);
    }
  };

  for (let i = 0; i < fieldsMeta.length; i++) {
    const currentFieldMeta = fieldsMeta[i];
    const currentFieldValue = fieldValues[currentFieldMeta.fieldId] && fieldValues[currentFieldMeta.fieldId].value;
    const isEditedField = !!(editedField && currentFieldMeta.fieldId === editedField._id);
    const isActive = (currentFieldMeta.type !== 'separator' && currentFieldValue == null) || isEditedField;

    const fieldsMetaObject = fieldsMeta.reduce((acc, field) => {
      acc[field.fieldId] = field;
      return acc;
    }, {});

    if (
      !allConditionsMet(currentFieldMeta, fieldsMetaObject, currentFieldMeta.groupId, {
        values: Object.entries(fieldValues).map((fv) => fv[1]),
      })
    ) {
      continue;
    }

    const questionAnswerPair = {
      meta: currentFieldMeta,
      question: translate(currentFieldMeta.label),
      answer: getAnswer(currentFieldMeta, currentFieldValue),
      isActive,
    };

    questionAnswerPairs.push(questionAnswerPair);

    if (isActive && !isEditedField) {
      isFormDone = false;
      lastQuestion = questionAnswerPair;

      break;
    }
  }

  if (!isFormDone) {
    const lastQuestionType = lastQuestion.meta.type;
    isTextInputActive = getTextInputActive(lastQuestionType);
  } else if (editedField) {
    isTextInputActive = getTextInputActive(editedField.type);
  }

  const sendAnswer = (e) => {
    e.preventDefault();
    const error = fieldValidator(currentAnswer, lastQuestion.meta.validationRules, lastQuestion.meta.type);
    if (error) {
      setCurrentError({ fieldId: lastQuestion.meta.fieldId, error });
    } else {
      let answer = currentAnswer;
      if (currentAnswer === '') {
        switch (lastQuestion.meta.type) {
          case 'radio':
          case 'checkbox':
            answer = [];
            break;
        }
      }
      updateAnswer(lastQuestion.meta.groupId, lastQuestion.meta.fieldId, answer);
      setIsTyping(false);
      setCurrentError(null);
      setCurrentAnswer('');
    }
  };

  const handleSendClick = (e) => {
    sendAnswer(e);
  };

  const handleFormKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      if (editMode) {
        handleUpdateClick(e);
      } else {
        sendAnswer(e);
      }
    }
  };

  return (
    <>
      <form className="msger" onKeyDown={handleFormKeyDown}>
        <main className="msger-chat">
          {questionAnswerPairs.map((qa) => (
            <div className="msger-chat-block" id={qa.meta.fieldId} key={qa.meta.fieldId}>
              <Question
                question={qa.question}
                name={theirName}
                description={qa.meta.description ? translate(qa.meta.description) : undefined}
              />
              <Answer
                fieldInfo={qa}
                name={myName}
                currentAnswer={currentAnswer}
                currentError={currentError}
                setCurrentAnswer={setCurrentAnswer}
                isTyping={isTyping}
              />
            </div>
          ))}
        </main>

        <Footer>
          <InputArea
            submissionId={submissionId}
            isFormDone={isFormDone}
            nextStage={nextStage}
            lastQuestion={lastQuestion}
            editMode={editMode}
            editedField={editedField}
            isTextInputActive={isTextInputActive}
            currentAnswer={currentAnswer}
            originalValue={originalValue}
            history={history}
            handleAnswerChange={handleAnswerChange}
            handleUpdateClick={handleUpdateClick}
            handleSendClick={handleSendClick}
            handleFocus={handleFocus}
          />
        </Footer>
      </form>
    </>
  );

  function getTextInputActive(type) {
    return type === 'text' || type === 'number' || type === 'date' || type === 'textarea';
  }
}

const fieldMetaPropType = PropTypes.shape({
  fieldId: PropTypes.string,
  _id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.object,
  values: PropTypes.array,
  validationRules: PropTypes.object,
  groupId: PropTypes.string,
});

const fieldsMetaPropType = PropTypes.arrayOf(fieldMetaPropType);

const valuePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]);

MessengerDataInput.propTypes = {
  theirName: PropTypes.string.isRequired,
  myName: PropTypes.string.isRequired,
  fieldsMeta: fieldsMetaPropType.isRequired,
  fieldValues: PropTypes.shape({
    fieldId: PropTypes.string,
    value: valuePropType,
  }).isRequired,
  nextStage: PropTypes.object.isRequired,
  submissionId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  updateAnswer: PropTypes.func.isRequired,
  editedField: PropTypes.object,
};

MessengerDataInput.defaultProps = {
  editedField: undefined,
};

function getAnswer(currentFieldMeta, currentFieldValue) {
  switch (currentFieldMeta.type) {
    case 'radio':
    case 'checkbox':
      return {
        options: currentFieldMeta.values,
        value: currentFieldValue,
      };
    default:
      return currentFieldValue;
  }
}

function Question({ question, name, description }) {
  const [isOpen, setIsOpen] = useState(false);
  const handleBubbleClick = () => {
    if (description === '') {
      return;
    }

    setIsOpen(!isOpen);
  };

  return (
    <div className="msg left-msg">
      <div className="msg-img">
        <img alt="bot_head" src={axialHead} />
      </div>

      <div className="msg-container">
        <div className="msg-info">
          <div className="msg-info-name">{name}</div>
        </div>

        <div className="msg-bubble" onClick={handleBubbleClick}>
          <div className="msg-text">
            {question}
            {description && <img className="info" src={info_icon} />}
            {isOpen && <div className="description">{description}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

Question.propTypes = {
  question: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
};

Question.defaultProps = {
  description: undefined,
};

function Answer({ fieldInfo, name, currentAnswer, currentError, setCurrentAnswer, isTyping }) {
  if (fieldInfo.meta.type === 'separator') {
    return null;
  }

  const isActive = fieldInfo.isActive || fieldInfo.meta.edited;
  const hasError = currentError && currentError.fieldId === fieldInfo.meta.fieldId;

  return (
    <div className="msg right-msg">
      <div className="msg-img">
        <img alt="bot_head" src={userHead} />
      </div>

      <div className="msg-container">
        <div className="msg-info">
          <div className="msg-info-name">{name}</div>
        </div>

        <div className="msg-container-bubble">
          {hasError && (
            <div className="msg-error-icon">
              <img style={{ width: 17 }} src={error} />
            </div>
          )}
          <div className={`msg-bubble ${fieldInfo.meta.edited ? 'edited' : ''}`}>
            <div className={`msg-text ${isActive ? 'active-answer' : ''}`}>
              {!fieldInfo.answer && isTyping && <div className="dot-elastic"></div>}
              <Field fieldInfo={fieldInfo} currentAnswer={currentAnswer} setCurrentAnswer={setCurrentAnswer} />
            </div>
          </div>
        </div>
        {hasError && <div className="msg-error-message">{currentError.error}</div>}
      </div>
    </div>
  );
}

Answer.propTypes = {
  fieldInfo: PropTypes.shape({
    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isActive: PropTypes.bool.isRequired,
    meta: PropTypes.shape({
      type: PropTypes.string,
      edited: PropTypes.bool,
    }),
  }).isRequired,
  name: PropTypes.string.isRequired,
  currentAnswer: PropTypes.any.isRequired,
  currentError: PropTypes.object,
  setCurrentAnswer: PropTypes.func.isRequired,
  isTyping: PropTypes.bool.isRequired,
};

Answer.defaultProps = {
  currentError: undefined,
};

function Field({ fieldInfo, currentAnswer, setCurrentAnswer }) {
  if (!fieldInfo.answer) {
    return null;
  }

  const isActive = fieldInfo.isActive || fieldInfo.meta.edited;

  switch (fieldInfo.meta.type) {
    case 'number':
    case 'textarea':
      return <div>{getNiceValueFormat(fieldInfo.meta, fieldInfo.answer).trim()}</div>;
    case 'date': {
      const date = new Date(fieldInfo.answer);
      return `${date.getFullYear()}.${('0' + (date.getMonth() + 1)).slice(-2)}.${('0' + date.getDate()).slice(-2)}.`;
    }
    case 'text':
      if (fieldInfo.meta.textMask === 'penny') {
        return <div>{getNiceValueFormat(fieldInfo.meta, fieldInfo.answer)} Ft</div>;
      } else if (fieldInfo.meta.textMask === 'phone') {
        return <div>{getPhoneMask(fieldInfo.answer)}</div>;
      } else {
        return <div>{getNiceValueFormat(fieldInfo.meta, fieldInfo.answer).trim()}</div>;
      }
    case 'radio':
      return fieldInfo.answer.options.map((value) => {
        const isChecked = fieldInfo.isActive ? value.id === currentAnswer.id : value.id === fieldInfo.answer.value.id;

        return (
          <div className="option" key={value.id}>
            <label>
              <input
                type="radio"
                name={fieldInfo.meta.fieldId}
                disabled={!isActive}
                checked={isChecked}
                onChange={() => setCurrentAnswer(value)}
              />
              {translate(value.label)}
            </label>
          </div>
        );
      });
    case 'checkbox':
      return fieldInfo.answer.options.map((value) => {
        const handleChange = () => {
          setCheckboxValue(currentAnswer, setCurrentAnswer, value);
        };

        return (
          <div className="option" key={value.id}>
            <input
              type="checkbox"
              name={fieldInfo.meta.fieldId}
              id={value.id}
              disabled={!isActive}
              checked={isChecked()}
              onChange={handleChange}
            />
            <label htmlFor={value.id}>{translate(value.label)}</label>
          </div>
        );

        function isChecked() {
          if (fieldInfo.isActive) {
            return Array.isArray(currentAnswer) ? currentAnswer.map((ca) => ca.id).includes(value.id) : false;
          }

          return fieldInfo.answer.value.map((ca) => ca.id).includes(value.id);
        }
      });

    default:
      return null;
  }
}

Field.propTypes = {
  fieldInfo: PropTypes.shape({
    meta: PropTypes.shape({
      type: PropTypes.string,
      fieldId: PropTypes.string,
      edited: PropTypes.bool,
      textMask: PropTypes.string,
    }),
    isActive: PropTypes.bool.isRequired,
    answer: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.any,
        values: PropTypes.array,
      }),
    ]),
  }).isRequired,
  currentAnswer: PropTypes.any.isRequired,
  setCurrentAnswer: PropTypes.func.isRequired,
};

export default function MessengerDataInputContainer({ submissionId, groupIds, nextStage, history, params }) {
  const dispatch = useDispatch();
  useLoadSubmissionFromLocalStorage();

  const groups = useSelector(selectGroups(groupIds));
  const editedFieldId = params.fieldId;
  const editedField = useSelector(selectEditedField(groups, editedFieldId));
  const fieldsMeta = useSelector(selectFieldMetasForGroups(groups, submissionId, editedField));
  const fieldValues = useSelector(selectFieldValuesForGroups(fieldsMeta, submissionId));

  useEffect(() => {
    groupIds.forEach((groupId) => dispatch(addNewGroupResult({ submissionId, groupId, position: 0 })));
  }, []);

  const updateAnswer = (groupId, fieldId, value) =>
    dispatch(changeValue({ submissionId, groupId, fieldId, value, meta: { saveToLocalStorage: true } }));

  return (
    <MessengerDataInput
      theirName="Axiál állásportál"
      myName="Ön, kedves pályázó"
      fieldsMeta={fieldsMeta}
      fieldValues={fieldValues}
      updateAnswer={updateAnswer}
      nextStage={nextStage}
      submissionId={submissionId}
      history={history}
      editedField={editedField}
    />
  );
}

MessengerDataInputContainer.propTypes = {
  submissionId: PropTypes.string.isRequired,
  groupIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  nextStage: PropTypes.object,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

MessengerDataInputContainer.defaultProps = {
  nextStage: undefined,
};

function scrollToLastQuestion() {
  setTimeout(() => {
    document.querySelector('.msger-chat').lastElementChild.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, 200);
}

function scrollToEditedQuestion(fieldId) {
  setTimeout(() => {
    document.getElementById(fieldId).scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, 200);
}

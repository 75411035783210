import React, { useState } from 'react';
import SubHeader from '../SubHeader';
import BackButton from '../BackButton';
import Filterbar from '../Filterbar';
import ViewOptions from '../ViewOptions';
import TextWithTooltip from '../TextWithTooltip';
import { I18n } from 'react-redux-i18n';
import DataSummary from './PredefinedGroupDataSummary/PredefinedGroupDataSummary';

import { useGetData } from './PredefinedGroupDataSummary/data-summary-logic';
import { translate } from '../../helper/functions';

import addIcon from '../../assets/add_white.svg';

import './PredefinedGroupDetails.scss';

const PredefinedGroupDetails = () => {
  const [search, setSearch] = useState('');
  const predefinedGroupData = useGetData();

  const filterbarProps = {
    searchbar: {
      shown: true,
      type: 'text',
      placeholder: 'predefined-group-details/search',
      onSearchChange: () => setSearch(event.target.value),
    },
    clearSearchAndFilter: () => setSearch(''),
  };

  return (
    <div className="data-management">
      <SubHeader>
        <div className="column">
          <BackButton
            className="negative-margin"
            title={I18n.t('predefined-group-details/back')}
            backUrl={`/data-management`}
          />
          <div className="title add-margin-left">
            <TextWithTooltip
              textWithOverflow={
                predefinedGroupData.status === 'success' ? translate(predefinedGroupData.model.groupLabel) : ''
              }
            />
          </div>
        </div>
        <div className="column">
          <ViewOptions options={['toggleFilterBar']} />
          <button className="subheader-button" onClick={predefinedGroupData.startAdd}>
            <img src={addIcon}></img> {I18n.t('predefined-group-details/add-group')}
          </button>
        </div>
      </SubHeader>
      <Filterbar {...filterbarProps} />
      <DataSummary predefinedGroupData={predefinedGroupData} search={search} />
    </div>
  );
};

export default PredefinedGroupDetails;

import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { calculateRoutePath } from '../../helper/navigation';

const WrappedLink = (props) => {
  const { to, navLink = false, children, className = null, userData, onClick = null } = props;

  const usersMainRoute = userData && userData.mainRoute;

  return navLink ? (
    <NavLink className={className} to={calculateRoutePath(usersMainRoute, to)} onClick={onClick}>
      {children}
    </NavLink>
  ) : (
    <Link className={className} to={calculateRoutePath(usersMainRoute, to)} onClick={onClick}>
      {children}
    </Link>
  );
};

function mapStateToProps(state) {
  return {
    userData: state.auth.userData,
  };
}

export default connect(mapStateToProps, null)(WrappedLink);

import React from 'react';
import { I18n } from 'react-redux-i18n';

import { isP4y } from '../../helper/functions';

import p4yLogoGrey from '../../assets/p4y_logo_grey.svg';
import facebookLogo from '../../assets/facebook_logo.svg';
import linkedinLogo from '../../assets/linkedin_logo.svg';
import youtubeLogo from '../../assets/youtube_logo.svg';

import './Footer.scss';

const Footer = () => {
  if (!isP4y()) {
    return null;
  }

  return (
    <div className="footer">
      <div bp="grid 4 vertical-center" className="upper">
        <img bp="padding-left" className="logo" src={p4yLogoGrey} />
        <div className="middle-text">
          <a target="_blank" href={I18n.t('registration/privacy-link')}>
            {I18n.t('registration/privacy-footer')}
          </a>
          <span className="footer-separator" />
          <a target="_blank" href={I18n.t('registration/terms-and-conditions-link')}>
            {I18n.t('registration/terms-and-conditions-footer')}
          </a>
        </div>
        <div className="social">
          <a target="_blank" href="https://www.facebook.com/pact4youth/">
            <img src={facebookLogo} />
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/pact-for-youth-association/">
            <img src={linkedinLogo} />
          </a>
          <a target="_blank" href="https://www.youtube.com/channel/UC3HI_wpVJ62fCIXbQ-Cm1Xw">
            <img src={youtubeLogo} />
          </a>
        </div>
      </div>
      <div className="lower">
        <div className="middle-text">
          {I18n.t('registration/p4y-hungary')} | {I18n.t('registration/p4y-all-right-reserved')}
        </div>
      </div>
    </div>
  );
};

export default Footer;
